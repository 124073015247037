import { Button, CardMedia } from '@material-ui/core';
import React from 'react';
import Img from '../Img';

export default function CTAImage({ card, onCardClick, onLoad }) {
  const { params } = card;
  if (!params?.imgURL) return null;

  if (['basic', 'jsx'].includes(card.type) && params?.actionEmitter === 'image') {
    return (
      <CardMedia style={{ marginBottom: '-6px' }}>
        <Button
          ariaLabel={params?.ariaLabel}
          style={{ width: '100%', padding: 0, margin: 0 }}
          onClick={() => onCardClick(card)}
        >
          <Img onLoad={onLoad} src={params?.imgURL} alt={params?.altText} style={{ width: '100%', padding: 0, margin: 0 }} />
        </Button>
      </CardMedia>
    );
  }

  return (
    <CardMedia style={{ marginBottom: '-6px' }}>
      <Img onLoad={onLoad} src={params?.imgURL} alt={params?.altText} style={{ width: '100%', padding: 0, margin: 0 }} />
    </CardMedia>
  );
}
