import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo, useCallback } from 'react';
import * as yup from 'yup';
import Typography from '@material-ui/core/Typography';

import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import JsxParser from 'react-jsx-parser';
import PublicLayout from '../components/layout/PublicLayout';
import { t } from '../locales';
import DynamicForm from '../components/DynamicForm';
import config from '../config';
import { FunctionalRedirect } from '../components/Navigation/FunctionalRedirect';
import { useImperativeQuery } from '../util/hooks';
import { useAuthDispatch } from '../context/auth';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: 32,
  },
  footer: {
    marginBottom: 0,
    marginTop: 16,
  },
}));

const defaultFields = [
  {
    code: 'email',
    label: `${t('register.email')} *`,
    type: 'text',
    default: '',
    validation: [
      {
        type: 'required',
        message: t('register.validation.required', { field: 'email' }),
      },
      { type: 'email', message: t('register.validation.email') },
    ],
  },
  {
    code: 'password',
    label: `${t('register.password')} *`,
    type: 'password',
    default: '',
    validation: [
      {
        type: 'required',
        message: t('register.validation.required', { field: 'password' }),
      },
      {
        type: 'min',
        message: t('register.validation.passwordReq'),
        params: [8],
      },
    ],
  },
  {
    code: 'passwordConfirm',
    label: `${t('register.passwordRepeat')} *`,
    type: 'password',
    default: '',
    validation: [
      {
        type: 'required',
        message: t('register.validation.required', { field: 'password' }),
      },
      {
        type: 'min',
        message: t('register.validation.passwordReq'),
        params: [8],
      },
      {
        type: 'oneOf',
        message: t('register.validation.passwordRepeat'),
        params: [[yup.ref('password')]],
      },
    ],
  },
  {
    code: 'firstname',
    label: `${t('register.firstname')} *`,
    type: 'text',
    default: '',
    validation: [
      {
        type: 'required',
        message: t('register.validation.required', { field: 'firstname' }),
      },
    ],
  },
  {
    code: 'lastname',
    label: `${t('register.lastname')} *`,
    type: 'text',
    default: '',
    validation: [
      {
        type: 'required',
        message: t('register.validation.required', { field: 'lastname' }),
      },
    ],
  },
];

const REGISTER_CUSTOMER = gql`
  mutation ($customer: RegisterCustomer!) {
    registerCustomer(customer: $customer) {
      success
      token
    }
  }
`;

const RESEND_CUSTOMER_ACTIVATION = gql`
  mutation ($email: String!) {
    resendCustomerActivation(email: $email)
  }
`;

const CHECK_INVITE_CODE = gql`
  query ($inviteCode: String!) {
    checkInvite(inviteCode: $inviteCode)
  }
`;

export default function Register() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const { inviteCode } = useParams();

  const activeFields = useMemo(() => {
    const fields = [
      ...defaultFields,
      ...config.register.customFields,
    ];

    if (config.register.invitations) {
      const inviteField = {
        code: 'inviteCode',
        label: `${t('register.inviteCode')} *`,
        type: 'text',
        default: inviteCode || '',
        validation: [
          {
            type: 'required',
            message: t('register.validation.required', {
              field: 'inviteCode',
            }),
          },
        ],
      };
      let index = fields.findIndex(({ type }) => type === 'checkbox');
      if (index === -1) index = fields.length;
      fields.splice(index, 0, inviteField);
    }
    return fields;
  }, [inviteCode]);

  const [registerCustomer] = useMutation(REGISTER_CUSTOMER);
  const [resendCustomerActivation] = useMutation(RESEND_CUSTOMER_ACTIVATION, {
    onCompleted: () => {
      history.push('/landing', {
        notification: { type: 'success', text: t('register.activationResend') },
      });
    },
  });
  const checkInviteCode = useImperativeQuery(CHECK_INVITE_CODE);

  const onSubmit = useCallback(
    async (data) => {
      const onResend = (customer) => {
        resendCustomerActivation({
          variables: { email: customer.email },
        });
      };

      const customAttributes = Object.entries(data)
        .filter(([key]) => !defaultFields.find((f) => f.code === key))
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
      const regularFields = Object.entries(data)
        .filter(([key]) => key !== 'passwordConfirm')
        .filter(([key]) => defaultFields.find((f) => f.code === key))
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

      const customer = {
        ...regularFields,
        customAttributes,
      };

      if (config.register.invitations) {
        customer.inviteCode = data.inviteCode;
      }

      try {
        // Validate Invitation
        if (config.register.invitations) {
          const { data } = await checkInviteCode({
            inviteCode: customer.inviteCode,
          });
          if (data.checkInvite !== 'valid') {
            return {
              inviteCode: t(`register.validation.inviteCode.${data.checkInvite}`),
            };
          }
        }
        // Trigger registration.
        const regResult = await registerCustomer({ variables: { customer } });
        if (regResult.data.registerCustomer.token) {
          dispatch({ type: 'LOGIN', payload: regResult.data.registerCustomer });
          history.push('/');
          return;
        }
      } catch (e) {
        if (e.graphQLErrors?.find((e) => e.extensions?.code === 412)) {
          return {
            email: (
              <>
                {t('register.validation.notActivated.part1')}{' '}
                <button type="button" onClick={() => onResend(customer)}>
                  {t('register.validation.notActivated.part2')}
                </button>
              </>),
          };
        }
        return {
          email: t('register.validation.emailDuplicated'),
        };
      }

      history.push('/landing', {
        notification: { type: 'success', text: t('register.success') },
      });
      return true;
    },
    [registerCustomer, checkInviteCode, history, resendCustomerActivation, dispatch],
  );

  return (
    <PublicLayout title={t('register.title')}>
      <PublicLayout.Box>
        <Typography variant="h3" color="primary" style={{ marginBottom: 10 }}>
          {t('register.title')}
        </Typography>
        <JsxParser
          components={{ Typography, Link }}
          jsx={config.register.text}
        />
        <DynamicForm
          className={classes.form}
          activeFields={activeFields}
          onSubmit={onSubmit}
          submitLabel={t('register.submit')}
          footer={
            config.register.footer ? (
              <Typography
                className={classes.footer}
                component="div"
                dangerouslySetInnerHTML={{ __html: config.register.footer }}
              />
            ) : null
          }
        />
      </PublicLayout.Box>
    </PublicLayout>
  );
}

const ACTIVATE_ACCOUNT = gql`
  mutation ($token: String!) {
    activateCustomer(activationToken: $token)
  }
`;

export function ActivateAccount({ ...routeProps }) {
  const [activateAccount] = useMutation(ACTIVATE_ACCOUNT);

  const redirect = useCallback(
    async ({ token }) => {
      const notification = {};
      try {
        await activateAccount({ variables: { token } });
        notification.type = 'success';
        notification.text = t('register.activateSuccess');
      } catch (_) {
        notification.type = 'error';
        notification.text = t('register.activateError');
      }
      return {
        path: '/landing',
        state: { notification },
      };
    },
    [activateAccount],
  );

  return <FunctionalRedirect callback={redirect} {...routeProps} />;
}
