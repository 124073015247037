import { Typography } from '@material-ui/core';
import React from 'react';

export default function CTACaption({ params }) {
  return (
    <>
      {params?.title && (
        <Typography gutterBottom={!!params?.content} align="left" variant="h3" component="h3">
          {params?.title}
        </Typography>
      )}
      {params?.content?.split('\n').map((line) => {
        if (!line.trim()) return <br />;
        return (
          <Typography align="left" variant="body1" component="p">
            {line}
          </Typography>
        );
      })}
    </>
  );
}
