import { useMemo } from "react";
import { useLocation } from "react-router";
import config from "../config";

export function useRouteBasedConfig(configPropertyName) {
  const location = useLocation();
  const path = location.pathname;

  const activeConfig = useMemo(() => {
    return config[configPropertyName].find((option) => {
      return !!option.path.find((p) => {
        const regex = new RegExp(
          `^${p.replace(/\*/g, "(.*)").replace(/\//g, "\\/")}$`
        );
        return path.match(regex);
      });
    });
  }, [path]);

  return activeConfig;
}
