import React, { useEffect } from 'react';
import AutoPlay from './AutoPlay';

export default function ContentFlowPlayer({ params }) {
  useEffect(() => {
    window.Contentflow.setup({
      corporationId: params.corporationId,
      element: document.getElementById('contentflowVideoPlayer'),
      outputId: params.outputId,
      disableFallback: true,
      playback: {
        autoplay: true,
        muted: false,
      },
    });
  }, [params]);
  return (
    <>
      <div id="customVideoPlayerParent">
        <div id="contentflowVideoPlayer" />
      </div>
      <AutoPlay />
    </>
  );
}
