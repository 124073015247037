import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AutoPlay from './AutoPlay';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  player: {
    '&::-webkit-media-controls-timeline': {
      display: 'none',
    },
    '&::-webkit-media-controls-current-time-display': {
      display: 'none',
    },
    '&::-webkit-media-controls-time-remaining-display': {
      display: 'none',
    },
  },
  playerVolumeControl: {
    '&::-webkit-media-controls-volume-slider': {
      display: 'none',
    },
    '&::-webkit-media-controls-mute-button': {
      display: 'none',
    },
  },
});

export default function IframePlayer({ params }) {
  const classes = useStyles();

  return (
    <>
      <div id="customVideoPlayerParent" className={classes.root}>
        <iframe
          style={{ border: 'none', width: '100%', height: '100%' }}
          src={params.link}
          referrerpolicy="strict-origin"
          allowFullScreen="allowfullscreen"
          x-webkit-airplay="allow"
        />
      </div>
      <AutoPlay />
    </>
  );
}
