import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core';
import React from 'react';

export default function LoadingSpinner() {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      spacing={2}
      style={{ height: '100%' }}
    >
      <Grid
        container
        item
        xs={12}
        justify="center"
        alignItems="center"
        style={{ textAlign: 'center' }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    </Grid>
  );
}
