import { gql } from "@apollo/client";

export const GET_WELCOME = gql`
query getWelcome {
  getWelcome @rest(type: "Welcome", path: "config/welcome.json") {
    title
    subtitle
    lines
    modal {
      title
      lines
    }
  }
}
`;

export const GET_STREAM_ACCESS = gql`
query getStreamAccess {
  getStreamAccess @rest(type: "eventStatus", path: "config/eventStatus.json")
}
`;

export const GET_VISITOR_COUNT = gql`
query visitorCount {
  visitorCount @rest(type: "visitorCount", path: "config/visitorCount.json")
}
`;

export const GET_STREAM = gql`
  query getStream($code: String = "main") {
    getStream(code: $code)  @rest(type: "Stream", path: "config/stream{args.code}.json") {
      platform
      params
      actions {
        type
        label
        url
        openInNewTab
        code: formCode
      }
      guid
      content
    }
  }
`;

export const GET_CARDS = gql`
query getConversionCardsForFrontend{
  getConversionCardsForFrontend @rest(type: "ConversionCard", path: "config/conversionCards.json") {
    conversionCards {
      code, type, name, params
    }
  }
}`;

export const getAllEmojisForFrontendGql = gql`
  query getAllEmojisForFrontend {
    getAllEmojisForFrontend @rest(type: "allEmojis", path: "config/allEmojis.json")  {
      code
      name
      image
      sequenceId
      status
    }
  }
`;

export const GET_POLLS = gql`
query getPollsForFrontend{
  getPollsForFrontend @rest(type: "polls", path: "config/polls.json")   {
    polls {
      pollId, code, title, description, image, type, canChangeVote, showTotal, isAbsolute, status, sequenceId, totalVotes, voted, placeableVotesCount, showCustomersVotedCount, customersVoted, hideVotes
      pollItems {
        pollItemId, label, image, status, sequenceId, votes, voted
      }
    }
  }
}`


export const PING_STREAM = gql`
  mutation setActiveZoomSession($input: PublishablePostInput!) {
    setActiveZoomSession(input: $input)  @rest(type: "ping", path: "event/v1/events/ping", method: "POST") {
      status
    }
  }
`;

export const UNLOCK_STREAM = gql`
  mutation deleteActiveZoomSession($input: PublishablePostInput!) {
    deleteActiveZoomSession(input: $input) @rest(type: "ping", path: "event/v1/events/leave", method: "POST") {
      status
    }
  }
`;

export const TRACK_CLICK = gql`
  mutation trackClick($input: PublishablePostInput!) {
    trackClick(input: $input) @rest(type: "ping", path: "event/v1/events/click", method: "POST") {
      status
    }
  }
`;

export const LEAVE_STREAM = gql`
  mutation trackLeaveStream($input: PublishablePostInput!) {
    trackLeaveStream(input: $input) @rest(type: "ping", path: "event/v1/events/leave", method: "POST") {
      status
    }
  }
`;

export const GET_CURRENT_CUSTOMER = gql`
  query trackClick {
    getCurrentCustomer @rest(type: "customer", path: "event/v1/customer", method: "GET") {
      id
    }
  }
`;
