import { CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router";
import DynamicRoute from "../../util/DynamicRoute";

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "calc(100vh - 100px)",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

function FunctionalRedirectComponent({ callback }) {
  const classes = useStyles();

  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    const handler = async () => {
      const target = await callback(params);
      if (typeof target === "object") {
        history.replace(target.path, target.state);
      } else {
        history.replace(target);
      }
    };
    handler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <CircularProgress size={60} />
    </div>
  );
}

export function FunctionalRedirect({ path, callback, ...routeProps }) {
  const RouteComponent = useMemo(
    () => () => <FunctionalRedirectComponent callback={callback} />,
    [callback]
  );
  return (
    <DynamicRoute path={path} component={RouteComponent} {...routeProps} />
  );
}
