import { useQuery, useMutation } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useAuthState } from '../context/auth';
import GqlAPI from '../gql';
import config from '../config';

export function useBeforeUnload(fn) {
  const cb = useRef(fn);

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args) => cb.current?.(...args);

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);
}

export function useInterval(fn, interval) {
  const cb = useRef(fn);

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    let mounted = true;
    let timeoutRef;
    const handler = () => {
      cb.current();
      if (mounted) {
        timeoutRef = setTimeout(handler, interval);
      }
    };
    timeoutRef = setTimeout(handler, interval);

    return () => {
      mounted = false;
      if (timeoutRef) clearTimeout(timeoutRef);
    };
  }, []);
}

export function useWindowMessage(fn) {
  const cb = useRef(fn);

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onMessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        cb.current?.(data);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    };

    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, []);
}

export function useImperativeQuery(query) {
  const { refetch } = useQuery(query, { skip: true });

  const imperativelyCallQuery = (variables) => refetch(variables);

  return imperativelyCallQuery;
}
const EVENTS = { VISIT: 'visit', CLICK: 'click' };
const TARGETTYPES = { CTA: 'CTA', PAGE: 'page' };

export const useTracking = (config.apiType === 'rest')
  ? () => ({ track: () => {}, EVENTS, TARGETTYPES })
  : () => {
    if (config.apiType === 'rest') return { track: () => {}, EVENTS, TARGETTYPES };
    const [trackingEvent, setTrackingEvent] = useState();
    const { user, anonymousCustomerId } = useAuthState();
    const [trackEvent] = useMutation(GqlAPI.TRACK, {
      errorPolicy: 'ignore',
      onError: () => {},
    });

    const track = (event) => {
      setTrackingEvent(event);
    };

    useEffect(() => {
      if (trackingEvent && (user || anonymousCustomerId)) {
        trackEvent({ variables: {
          ...trackingEvent,
          anonymousCustomerId: anonymousCustomerId || undefined,
        } });
      }
    }, [trackingEvent]);

    return { track, EVENTS, TARGETTYPES };
  };
