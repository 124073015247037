import { Container, Paper, Grid, Typography, Button, Card, CardContent } from '@material-ui/core';
import { Link, Redirect, useLocation } from 'react-router-dom';
import React, { Fragment, useEffect } from 'react';
import JsxParser from 'react-jsx-parser';
import { Textfit } from 'react-textfit';
import config from '../../../config';
import { useAuthState } from '../../../context/auth';
import CTAAction from '../CTAActions';
import CTAImage from '../CTAImage';

export default function JsxCTA({ card, onCardClick, onLoad }) {
  const { user } = useAuthState();
  const { pathname } = useLocation();
  const { params } = card;
  const padding = false;

  useEffect(() => {
    onLoad && onLoad();
  }, [card]);

  return (
    <Card className={`jsx-cta-${card.code}`}>
      <CTAImage onLoad={onLoad} card={card} onCardClick={onCardClick} />
      <JsxParser
        components={{ Fragment, Container, Paper, Grid, Typography, Link, Button, Textfit, CardContent, Redirect }}
        jsx={params?.jsx}
        bindings={{ config, user, params, padding, pathname }}
      />
      <CTAAction card={card} onCardClick={onCardClick} />
    </Card>
  );
}
