import React, { useCallback, useState, useImperativeHandle, useRef, forwardRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import config from '../config';
import { useWindowMessage } from '../util/hooks';
import { t } from '../locales';

const useStyles = makeStyles({
  iframe: {
    border: 'none',
  },
  root: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  rootError: {
    background: '#000',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 32,
    textAlign: 'center',
    color: '#fff',
  },
});

const savedUsername = window.localStorage.getItem('wrk-zoom-username');

export default forwardRef(({
  apiKey,
  meetingId,
  signature,
  passcode,
  username,
}, ref) => {
  const isSmallDevice = window.innerWidth <= 768;
  const classes = useStyles();
  const iFrameRef = useRef(null);

  const [isEnd, setIsEnd] = useState(false);
  useWindowMessage((data) => {
    if (data.type === 'zoomEnd') {
      setIsEnd(true);
    }
  });

  useImperativeHandle(ref, () => ({
    switchToFullscreen() {
      if (!iFrameRef.current) return;
      iFrameRef.current.contentWindow.postMessage(
        JSON.stringify({ type: 'switchToFullscreen' }),
        '*',
      );
    },
  }));

  const [nameField, setNameField] = useState('');
  const [effectiveUsername, setEffectiveUsername] = useState(
    username || savedUsername,
    [],
  );
  const updateName = useCallback(() => {
    if (!nameField || !nameField.length) {
      return;
    }

    window.localStorage.setItem('wrk-zoom-username', nameField);
    setEffectiveUsername(nameField);
  }, [nameField]);

  if (isSmallDevice) {
    return (
      <div className={`${classes.root} ${classes.rootError}`}>
        <Typography variant="h3">
          {config.streamPage.locale.useDesktop}
        </Typography>
      </div>
    );
  }

  if (isEnd) {
    return (
      <div className={`${classes.root} ${classes.rootError}`}>
        <Typography variant="h3">
          {config.streamPage.locale.end || t('zoom.end')}
        </Typography>
      </div>
    );
  }

  if (!effectiveUsername) {
    return (
      <Dialog open aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('zoom.name')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('zoom.editName')}
          </DialogContentText>
          <TextField
            onChange={(e) => setNameField(e.target.value)}
            value={nameField}
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={updateName} color="primary">
            {t('zoom.participate')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <div className={classes.root}>
      <iframe
        ref={iFrameRef}
        className={classes.iframe}
        title="Zoom"
        width="100%"
        height="100%"
        allow="microphone; camera"
        allowFullScreen
        src={`/zoom.html?apiKey=${apiKey}&meetingId=${meetingId}&signature=${signature}&passcode=${passcode}&username=${effectiveUsername}`}
      >
        {t('loading')}
      </iframe>
    </div>
  );
});
