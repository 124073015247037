import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactHlsPlayer from 'react-hls-player';
import AutoPlay from './AutoPlay';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  player: {
    '&::-webkit-media-controls-timeline': {
      display: 'none',
    },
    '&::-webkit-media-controls-current-time-display': {
      display: 'none',
    },
    '&::-webkit-media-controls-time-remaining-display': {
      display: 'none',
    },
  },
  playerVolumeControl: {
    '&::-webkit-media-controls-volume-slider': {
      display: 'none',
    },
    '&::-webkit-media-controls-mute-button': {
      display: 'none',
    },
  },
});

export default function HLSPlayer({ params }) {
  const classes = useStyles();

  return (
    <>
      <div id="customVideoPlayerParent" className={classes.root}>
        <ReactHlsPlayer
          className={`${classes.player} ${params.hideVolumeControl ? classes.playerVolumeControl : ''}`}
          src={params.link}
          autoPlay={params.autoPlay}
          disablePictureInPicture
          controlsList="nodownload noplaybackrate"
          controls
          width="100%"
          height="100%"
        />
      </div>
      <AutoPlay />
    </>
  );
}
