import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GqlAPI from '../gql';

const useStyles = makeStyles(() => ({
  visitorLabel: {
    position: 'absolute',
    width: 'fit-content',
    color: 'white',
    padding: '5px 10px',
    marginTop: 10,
    marginLeft: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 999,
    borderRadius: 8,
  },
  icon: {
    marginRight: 5,
    marginBottom: 2,
    width: 15,
  },
}));

export default function VisitorCount({ streamCode }) {
  const classes = useStyles();

  const {
    data, startPolling, stopPolling,
  } = useQuery(GqlAPI.GET_STREAM_VISITOR_COUNT, {
    variables: { streamCode },
  });

  useEffect(() => {
    startPolling(10000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  return (
    <span className={`${classes.visitorLabel} visitor-count-label`}>
      <VisibilityIcon className={classes.icon} />
      {data?.getStreamVisitorCount || 1}
    </span>
  );
}
