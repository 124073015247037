import React from "react";
import { Link as RouterLink } from "react-router-dom";

export default function Link ({ to, link, children, openInNewTab }) {
  if(/^https?:\/\//.test(to || link) || /^http?:\/\//.test(to || link)){
    return <a
      href={to || link}
      target={(openInNewTab) ? "_blank":""}
      rel={(openInNewTab) ? "noopener noreferrer":""}
    >{children}</a>
  }
  return <RouterLink to={to || link}>{children}</RouterLink>
}
