import React from 'react';
import { Redirect, useParams } from 'react-router';
import config from '../config';

export default function PreStream() {
  const { code } = useParams();

  if (!config.streamPage?.useHrefForRedirect) return (<Redirect to={{ pathname: code ? `/stream/${code}` : '/stream' }} />);
  code ? window.location.href = `${window.location.origin}/stream/${code}` : window.location.href = `${window.location.origin}/stream`;
  return null;
}
