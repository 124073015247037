import React, { createContext, useReducer, useContext } from 'react';

const NavBarDispatchContext = createContext();
const NavBarStateContext = createContext();

const navbarReducer = (state, title) => {
    return {
      ...state,
      title,
    };
};

export const NavBarContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(navbarReducer, { title: null, type: null });

  return (
    <NavBarDispatchContext.Provider value={dispatch}>
      <NavBarStateContext.Provider value={state}>
        {children}
      </NavBarStateContext.Provider>
    </NavBarDispatchContext.Provider>
  );
};

export const useNavBarContextState = () => useContext(NavBarStateContext);
export const useNavBarContextDispatch = () => useContext(NavBarDispatchContext);
