import {
  Badge,
  Fab,
  makeStyles,
  Modal,
  Typography,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useEffect, useState } from 'react';
import { CometChat } from '@cometchat-pro/chat';
import gql from 'graphql-tag';
import ChatIcon from '@material-ui/icons/Chat';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import { useRouteBasedConfig } from '../../util/routeBasedConfig';
import config from '../../config';
import { useImperativeQuery } from '../../util/hooks';

import {
  CometChatConversationList,
  CometChatGroupList,
  CometChatMessages,
  CometChatUserList,
} from '../../third-party/cometchat-pro-ui-kit/CometChatWorkspace/src';
import { t } from '../../locales';
import { useCometChatContextState } from './CometChatContextProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 21,
    right: 20,
    zIndex: 1000,
  },
  modal: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    inset: 'unset !important',
    bottom: '0 !important',
    right: '0 !important',
    width: '50% !important',
    height: '50% !important',
    maxWidth: 800,
    minWidth: 400,
    '@media (max-height:900px)': {
      height: '70% !important',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: '100% !important',
      minWidth: '100% !important',
    },
  },
  paper: {
    marginRight: 32,
    background: '#fff',
    maxWidth: 800,
    minWidth: 400,
    height: '100%',
    width: '100%',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    overflow: 'hidden',
    boxShadow: '0px -1px 16px 5px rgba(0,0,0,0.26)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginRight: 0,
      height: '90%',
      minWidth: '100%',
    },
  },
  header: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 8,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 8,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  headerTitle: {
    flex: 1,
  },
  fab: {
    width: '60px',
    height: '60px',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
  buttonGroup: {
    width: '100%',
    background: theme.palette.primary.main,
    borderRadius: 0,
  },
  button: {
    color: theme.palette.primary.contrastText,
    flex: 1,
    borderRadius: 0,
  },
  buttonSelected: {
    '&&': {
      '&:hover': {
        backgroundColor: theme.palette.primary.contrastText,
      },
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
    },
  },
  wrapper: {
    height: '100%',
  },
}));

const INIT_STATE_NONE = 'none';
const INIT_STATE_LOADING = 'loading';
const INIT_STATE_DONE = 'done';

const QUERY_AUTH_TOKEN = gql`
  query {
    getCometChatToken {
      token
    }
  }
`;

let TIMER = null;

// eslint-disable-next-line import/prefer-default-export
export function CometChatProvider() {
  const activeConfig = useRouteBasedConfig('cometChat');
  const classes = useStyles();
  const cometChatSession = useCometChatContextState();

  const [initState, setInitState] = useState(INIT_STATE_NONE);
  const fetchAuthToken = useImperativeQuery(QUERY_AUTH_TOKEN);

  const [chatOpened, setChatOpened] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [activeConversation, setActiveConversation] = useState(false);
  const [mode, setMode] = useState('users');
  const [waitForModeUpdate, setWaitForModeUpdate] = useState(false);

  const currentTab = (type) => {
    switch (type) {
      case 'chats':
        return (
          <CometChatConversationList
            lang={config.i18n?.language || 'de'}
            onItemClick={(x) => {
              setActiveConversation(x);
            }}
          />
        );
      case 'users':
        return (
          <CometChatUserList
            lang={config.i18n?.language || 'de'}
            onItemClick={(x) => {
              setActiveConversation(x);
            }}
          />
        );
      case 'groups':
        return (
          <CometChatGroupList
            widgetsettings={{ main: { create_groups: false, join_or_leave_groups: false } }}
            lang={config.i18n?.language || 'de'}
            onItemClick={(x) => {
              setActiveConversation(x);
            }}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (initState !== INIT_STATE_NONE || !activeConfig?.isVisible) {
      return;
    }
    if (config.cometChatGlobal.isEnabled === false) return;
    setInitState(INIT_STATE_LOADING);

    const asyncHandler = async () => {
      // Handle initialization
      const { region, appId } = config.cometChatGlobal;
      const appSetting = new CometChat.AppSettingsBuilder()
        .subscribePresenceForAllUsers()
        .setRegion(region)
        .build();
      try {
        await CometChat.init(appId, appSetting);
        const {
          data: {
            getCometChatToken: { token },
          },
        } = await fetchAuthToken();
        await CometChat.login(token);
        setInitState(INIT_STATE_DONE);

        // Handle unread messages
        const handleUnreadMessages = async () => {
          const messagesRequest = new CometChat.MessagesRequestBuilder()
            .setUnread(true)
            .setLimit(10)
            .build();

          try {
            const messages = await messagesRequest.fetchPrevious();
            setUnreadMessages(messages.length);
          } catch (err) {
            console.error('Error while fetching unread messages', err);
          }
          TIMER = setTimeout(handleUnreadMessages, 5000);
        };
        handleUnreadMessages();
      } catch (err) {
        console.error('Failed loading and initializing CometChat Pro', err);
      }
    };
    asyncHandler();

    return () => {
      if (TIMER) {
        clearTimeout(TIMER);
      }
    };
  }, [initState, activeConfig, fetchAuthToken]);

  useEffect(() => {
    if (cometChatSession?.guid && (!config?.cometChatGlobal?.tabs?.length || config?.cometChatGlobal?.tabs?.find((t) => t === 'groups'))) {
      setActiveConversation(null);
      setMode('groups');
      setWaitForModeUpdate(true);
    }
  }, [cometChatSession?.guid]);

  useEffect(() => {
    if (waitForModeUpdate && cometChatSession?.guid
        && (!config?.cometChatGlobal?.tabs?.length || config?.cometChatGlobal?.tabs?.find((t) => t === 'groups'))) {
      setActiveConversation({ ...cometChatSession });
      setWaitForModeUpdate(false);
    }
  }, [waitForModeUpdate]);

  if (initState !== INIT_STATE_DONE) {
    return null;
  }

  if (config.cometChatGlobal.isEnabled === false) return null;
  const unreadMessagesText = unreadMessages >= 10 ? '10+' : unreadMessages;
  return (
    <div className={classes.root}>
      <Badge
        badgeContent={unreadMessagesText}
        color="primary"
        invisible={!unreadMessages}
      >
        <Fab
          className={classes.fab}
          color="primary"
          size="large"
          aria-label={t('cometChat.fabButtonAriaText', { unreadMessagesText })}
          onClick={() => setChatOpened(true)}
        >
          <ChatIcon className={classes.icon} />
        </Fab>
      </Badge>
      <Modal
        className={classes.modal}
        open={chatOpened}
        onClose={() => setChatOpened(false)}
        closeAfterTransition
        disableScrollLock
        BackdropComponent={() => null}
      >
        <Fade in={chatOpened}>
          <div className={classes.paper}>
            <div
              role="button"
              tabIndex={0}
              className={classes.header}
              onClick={() => setChatOpened(false)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') setChatOpened(false);
              }}
            >
              <Typography className={classes.headerTitle}>Chats</Typography>
              <CloseIcon />
            </div>
            {!activeConversation ? (
              <div className={classes.wrapper}>
                <ToggleButtonGroup
                  exclusive
                  value={mode}
                  className={classes.buttonGroup}
                  onChange={(event, newMode) => {
                    if (newMode) {
                      setMode(newMode);
                    }
                  }}
                >
                  {(!config?.cometChatGlobal?.tabs?.length || config?.cometChatGlobal?.tabs?.find((t) => t === 'chats'))
                    && (
                      <ToggleButton
                        color="primary"
                        value="chats"
                        classes={{ selected: classes.buttonSelected }}
                        className={classes.button}
                      >
                        {t('cometChat.chats')}
                      </ToggleButton>
                    )}
                  {(!config?.cometChatGlobal?.tabs?.length || config?.cometChatGlobal?.tabs?.find((t) => t === 'users'))
                    && (
                      <ToggleButton
                        color="primary"
                        classes={{ selected: classes.buttonSelected }}
                        value="users"
                        className={classes.button}
                      >
                        {t('cometChat.users')}
                      </ToggleButton>
                    )}
                  {(!config?.cometChatGlobal?.tabs?.length || config?.cometChatGlobal?.tabs?.find((t) => t === 'groups'))
                    && (
                      <ToggleButton
                        color="primary"
                        classes={{ selected: classes.buttonSelected }}
                        value="groups"
                        className={classes.button}
                      >
                        {t('cometChat.groups')}
                      </ToggleButton>
                    )}

                </ToggleButtonGroup>
                {currentTab(mode)}
              </div>
            ) : null}
            {activeConversation ? (
              <CometChatMessages
                lang={config.i18n?.language || 'de'}
                actionGenerated={(action) => {
                  if (action === 'toggleSidebar') {
                    setActiveConversation(null);
                  }
                }}
                videocall={false}
                audiocall={false}
                chatWithUser={activeConversation.uid}
                chatWithGroup={activeConversation.guid}
              />
            ) : null}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
