import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Button, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  button: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  cssOutlinedInput: {
    borderWidth: '0px',
    '&$cssFocused $notchedOutline': {
      borderWidth: '0px',
    },
  },
  cssFocused: {
    borderWidth: '0px',
  },
  notchedOutline: {
    borderWidth: '0px',
    borderColor: 'green !important',
  },
  register: {},
  form: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  errorText: {
    color: 'red',
    marginBottom: 16,
  },

}));

export default function PasswordForm({ loading, handleSubmit, error, labels }) {
  const classes = useStyles();
  const [password, setPassword] = useState('');

  return (
    <form className={classes.form} onSubmit={(e) => { e.preventDefault(); handleSubmit(password); }}>
      <Paper elevation={3} style={{ marginBottom: '20px' }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              autoFocus
              type="password"
              variant="outlined"
              value={password}
              onChange={({ target }) =>
                setPassword(target.value)}
              placeholder={labels?.password}
              InputLabelProps={{
                classes: {
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                labels?.login
              )}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <span className={classes.errorText}>{error}</span>
    </form>
  );
}
