import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { CssBaseline } from '@material-ui/core';
import { SnackbarProvider } from 'material-ui-snackbar-provider';
import ApolloProvider from './ApolloProvider';
import './App.css';
import Footer from './components/Footer';
import TawkTo from './components/TawkTo';
import { AuthProvider } from './context/auth';
import PageRouter from './components/Navigation/PageRouter';
import ThemeContainer from './components/global/MyTheme';
import { NotificationProvider } from './components/global/NotificationProvider';
import DynamicNavBar from './components/Navigation/DynamicNavBar';
import { CometChatProvider } from './components/global/CometChat';
import { CometChatContextProvider } from './components/global/CometChatContextProvider';
import { NavBarContextProvider } from './components/global/NavBarContextProvider';
import config from './config';
import CookieConsent from './components/CookieBanner';
import { FooterContextProvider } from './components/global/FooterContextProvider';

const instance = createInstance(config.matomo);
instance.pushInstruction('requireConsent');

function App() {
  return (
    <>
      <ApolloProvider>
        <AuthProvider>
          <BrowserRouter>
            <ThemeContainer>
              <NotificationProvider>
                <SnackbarProvider SnackbarProps={{ autoHideDuration: 4000 }}>
                  <NavBarContextProvider>
                    <CometChatContextProvider>
                      <FooterContextProvider>
                        <CssBaseline />
                        <MatomoProvider value={instance}>
                          <Helmet>
                            <link
                              rel="icon"
                              type="image/png"
                              href={config.favicon}
                              sizes="16x16"
                            />
                            {config.appleTouchIcon && (
                              <link rel="apple-touch-icon" href={config.appleTouchIcon} />
                            )}
                            {config.gtm?.id && (
                              <script>
                                {`
                          window.dataLayer = window.dataLayer || [];
                          function gtag(){dataLayer.push(arguments);}

                          // Default ad_storage to 'denied'.
                          gtag('consent', 'default', {
                            'ad_storage': 'denied',
                            'analytics_storage': 'denied',
                          });

                          dataLayer.push({
                            'event': 'default_consent'
                          });

                          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                          })(window,document,'script','dataLayer','${config.gtm?.id}');
                          `}
                              </script>
                            )}
                          </Helmet>
                          <DynamicNavBar />
                          <PageRouter />
                          <TawkTo />
                          <CometChatProvider />
                          <Footer />
                          {config.cookieBanner && config.cookieBanner.isEnabled && <CookieConsent />}
                        </MatomoProvider>
                      </FooterContextProvider>
                    </CometChatContextProvider>
                  </NavBarContextProvider>
                </SnackbarProvider>
              </NotificationProvider>
            </ThemeContainer>
          </BrowserRouter>
        </AuthProvider>
      </ApolloProvider>
    </>
  );
}

export default App;
