import { React, useState } from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  Container,
  Grid,
  Grow,
  Card,
  CardMedia,
  CardContent,
  Box,
  Typography,
  Button,
  Link,
} from '@material-ui/core';
import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined';
// import Vimeo from '@u-wave/react-vimeo';
import JsxParser from 'react-jsx-parser';
import VimeoPlayer from '../components/VimeoPlayer';
import { t } from '../locales';
import config from '../config';
import Img from '../components/Img';

const GET_CATEGORIES = gql`
  query getAllMediaLibraryCategoriesForFrontend {
    getAllMediaLibraryCategoriesForFrontend {
      categories {
        name
        code
        sequenceId
        content
        moreContent
      }
    }
  }
`;

const GET_MEDIA = gql`
  query getAllMediaLibraryCategoryMediaForFrontend($categoryCode: String!) {
    getAllMediaLibraryCategoryMediaForFrontend(categoryCode: $categoryCode) {
      media {
        code
        type
        params
        title
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  main: {
    paddingTop: theme.mixins.toolbar.minHeight + 32,
    background: 'none',
  },
  previewImage: {
    position: 'relative',
  },
  mediaCard: {
    height: '100%',
    marginLeft: 8,
    marginRight: 8,
  },

  thumbnail: {
    width: '100%',
  },
  mediaItem: {
    marginTop: 8,
    marginBottom: 8,
  },
  playButtonWrapper: {
    cursor: 'pointer',
    fontSize: '72px',
    display: 'inline-block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  playButtonIcon: {
    background: 'rgba(255, 255, 255, 1)',
    borderRadius: 64,
    boxShadow: '2px 2px 15px -6px #000000',

  },
  categories: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 25,
    marginLeft: -8,
    marginRight: -8,
    marginBottom: 50,
  },
  category: {
    margin: 8,
    padding: 32,
    borderRadius: 4,
    [theme.breakpoints.down('md')]: {
      padding: 16,
      width: '100%',
    },
  },
  tabContent: {
    margin: -16,
    padding: 16,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    '@media (max-width: 1350px)': {
      margin: 0,
    },
  },
  title: {
    marginBottom: 8,
    color: theme.palette.primary.contrastText,
  },
  titleVideo: {
    marginTop: 24,
    marginBottom: 8,
    color: theme.palette.primary.contrastText,
  },
}));

export default function MediaLibrary() {
  // selectedCategory is the index of the categories array of the respective category - TabBar works with indices
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [media, setMedia] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [activeMedia, setActiveMedia] = useState('');
  const { trackEvent } = useMatomo();

  const [getMedia, { loading: mediaLoading }] = useLazyQuery(GET_MEDIA, {
    onCompleted(data) {
      setMedia(data.getAllMediaLibraryCategoryMediaForFrontend.media);
    },
    onError(err) {
      console.log(err);
    },
  });

  const { loading: catLoading } = useQuery(GET_CATEGORIES, {
    onCompleted(data) {
      setCategories(data.getAllMediaLibraryCategoriesForFrontend.categories);
      if (!selectedCategory) setSelectedCategory(0); // we set the selectedCategory to the first element of the categories Array
      if (!data.getAllMediaLibraryCategoriesForFrontend?.categories?.length) return;
      getMedia({
        variables: {
          categoryCode:
            data.getAllMediaLibraryCategoriesForFrontend.categories[0].code,
        },
      });
    },
    onError(err) {
      console.log(err);
    },
  });

  const classes = useStyles();
  const theme = useTheme();

  const showMediaPlayerForMedia = (mediumCode) => {
    setActiveMedia(mediumCode);
    trackEvent({ category: 'mediaLibrary', action: 'playVideo', value: mediumCode });
  };

  let tabContent = [];

  if (catLoading) {
    return null;
  }

  tabContent = media?.map((current, index) => {
    const cardMedia = activeMedia === current.code ? (
      <CardMedia className={classes.previewImage}>
        {
          current.type === 'vimeo' ? (
            <VimeoPlayer
              videoId={current.params.videoId}
              width="100%"
              height="100%"
              color={theme.palette.primary.main}
            />
          ) : null // add players for other types (youtube, etc) here
        }
      </CardMedia>
    ) : (
      <CardMedia className={classes.previewImage}>
        <Img src={current.params.thumbnailLarge} className={classes.thumbnail} />
        <div
          role="button"
          tabIndex={index}
          className={classes.playButtonWrapper}
          onClick={() => showMediaPlayerForMedia(current.code)}
          onKeyPress={() => showMediaPlayerForMedia(current.code)}
        >
          <PlayCircleFilledOutlinedIcon
            className={classes.playButtonIcon}
            fontSize="inherit"
            color="primary"
          />
        </div>
      </CardMedia>
    );

    return (
      <Grow key={current.code} in>
        <Grid
          className={classes.mediaItem}
          item
          xs={12}
          sm={6}
          md={4}
          lg={4}
          xl={4}
        >
          <Card className={classes.mediaCard}>
            {/* <CardMedia className={classes.previewImage} image={media[i].params.thumbnailLarge}> */}
            {cardMedia}
            {/* </CardMedia> */}
            <CardContent>
              <Typography gutterBottom variant="h3" component="h2">
                {current.title}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grow>
    );
  });

  if (media?.length === 0) {
    tabContent = (
      <Box p={3}>
        <Typography>
          In dieser Kategorie gibt es derzeit keine Medien
        </Typography>
      </Box>
    );
  }

  if (mediaLoading) {
    tabContent = null;
  }

  const handleSelectedTab = (categoryIdx) => {
    setSelectedCategory(categoryIdx);
    setActiveMedia(''); // stopping playback
    getMedia({ variables: { categoryCode: categories[categoryIdx].code } });
    trackEvent({ category: 'mediaLibrary', action: 'selectCategory', value: categories[categoryIdx].code });
    setShowMore(false);
  };

  return (
    <>
      <Helmet>
        <title>{config.title}</title>
      </Helmet>

      <Container maxWidth="lg" className={classes.main}>
        <div className={classes.categories}>
          {categories.map((cat, i) => (
            <Button
              color={i === selectedCategory ? 'primary' : ''}
              variant="contained"
              className={classes.category}
              onClick={() => handleSelectedTab(i)}
            >
              {cat.name}
            </Button>
          ))}
        </div>
        <div className="show-more-box" style={categories[selectedCategory]?.content ? { marginTop: 15, marginBottom: 15 } : {}}>
          {categories[selectedCategory]?.content && (
            <JsxParser
              components={{ Typography, Link, Button }}
              jsx={categories[selectedCategory].content}
            />
          )}

          {showMore && categories[selectedCategory]?.moreContent && (
            <JsxParser
              components={{ Typography, Link, Button }}
              jsx={categories[selectedCategory].moreContent}
            />
          )}
          {categories[selectedCategory]?.moreContent
            && (
              <Link
                className="show-more"
                onClick={() => setShowMore(!showMore)}
                style={{ cursor: 'pointer', fontSize: '16px' }}
              >{showMore ? t('mediaLibrary.showLess') : t('mediaLibrary.showMore')}
              </Link>
            )}
        </div>
        <div className={classes.tabContent}>{tabContent}</div>
      </Container>
    </>
  );
}
