import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import config from '../config';
import { useAuthState } from '../context/auth';

const tawkToPropertyId = config.tawkTo.propertyId;
const tawkToKey = config.tawkTo.key;

function TawkTo() {
  const { user } = useAuthState();
  const [tawkLoaded, setTawkLoaded] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const routesWithoutNavbar = ['/preview/cta'];

  const setUser = () => {
    if (user && user.firstname) {
      const tawkLogin = {
        name: `${user.firstname} ${user.lastname}`,
        email: user.email,
        hash: user.tawkToHash,
      };

      window.Tawk_API.setAttributes(tawkLogin, (error) => {
        // eslint-disable-next-line no-console
        if (error) console.error(error);
      });
    }
  };

  const shouldShowTawkTo = (showInPaths, currentPath) => (
    showInPaths.find((p) => {
      const regex = new RegExp(`^${p.replace(/\*/g, '(.*)').replace(/\//g, '\\/')}$`);
      return currentPath.match(regex);
    })
  );

  const loadTawkTo = (propertyId, key) => {
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    const tawk = document.getElementById('tawkId');
    if (tawk) return window.Tawk_API;
    if (!key) throw new Error('Key not provided. Get key from tawk dashboard - Direct Chat Link');

    const script = document.createElement('script');
    script.id = 'tawkId';
    script.async = true;
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    script.src = `https://embed.tawk.to/${propertyId}/${key}`;

    const firstScriptTag = document.getElementsByTagName('script')[0];
    if (!firstScriptTag || !firstScriptTag.parentNode) throw new Error('DOM is unavailable');

    firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
  };

  useEffect(() => {
    if (!config.tawkTo.enabled || routesWithoutNavbar.includes(location.pathname)) {
      return null;
    }
    loadTawkTo(tawkToPropertyId, tawkToKey);

    if (config.tawkTo?.path && config.tawkTo?.path?.length) {
      // listen of page navigation changes
      history.listen((location) => {
        if (shouldShowTawkTo(config.tawkTo.path, location.pathname)) {
          return window.Tawk_API.showWidget && window.Tawk_API.showWidget();
        }
        window.Tawk_API.minimize && window.Tawk_API.minimize();
        window.Tawk_API.hideWidget && window.Tawk_API.hideWidget();
      });
    }

    window.Tawk_API.onLoad = () => {
      setUser();
      setTawkLoaded(true);

      // apply it to current route
      if (config.tawkTo?.path && config.tawkTo?.path?.length) {
        if (shouldShowTawkTo(config.tawkTo.path, location.pathname)) {
          return window.Tawk_API.showWidget && window.Tawk_API.showWidget();
        }
        window.Tawk_API.minimize && window.Tawk_API.minimize();
        window.Tawk_API.hideWidget && window.Tawk_API.hideWidget();
      }
    };
  }, []);

  useEffect(() => {
    if (user && tawkLoaded) {
      setUser();
    }
  }, [user]);

  return null;
}

export default TawkTo;
