import { Container } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import renderCard from '../../components/ConversionCards/renderCard';

export default function PreviewConversionCard() {
  const [card, setCard] = useState({});
  const cardWrapperRef = useRef(null);
  const onCardLoad = useCallback(() => {
    const height = cardWrapperRef.current.offsetHeight;
    window.parentIFrame.sendMessage({ type: 'cardSizeChanged', height });
    window.parentIFrame.size(height);
  }, [cardWrapperRef.current]);

  window.onmessage = (e) => {
    if (typeof e.data === 'string' && e.data?.indexOf(':{') > 0) {
      const message = JSON.parse(e.data.slice(e.data.indexOf(':') + 1));

      setCard(message);
      window.parentIFrame.sendMessage({ cardUpdate: true });
    }
  };

  const resizeObserver = new ResizeObserver(() => {
    onCardLoad();
  });

  useEffect(() => {
    window.iFrameResizer = {
      onReady: () => {
        window.parentIFrame.sendMessage({ type: 'pageLoaded' });
        resizeObserver.observe(document.body);
      } };
  }, [card]);

  return (
    <>
      <Helmet>
        <script src="/js/iframeResizer.contentWindow.min.js" />
      </Helmet>
      <div ref={cardWrapperRef} style={{ padding: '20px', height: 'full' }}>
        <Container style={{ marginBottom: '46px', marginTop: '46px' }}>
          {renderCard(card, () => {}, onCardLoad)}
        </Container>
      </div>
    </>
  );
}
