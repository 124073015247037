import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import config from "../../config";

const useStyles = makeStyles((theme) => ({
  container: {
    background: config.styles.generalBackground || 'white',

    padding: "0",
    maxWidth: "100%",
    minHeight: "100vh",
    position: "relative",
    paddingBottom: "100px",
    display: "block",

    [theme.breakpoints.down("sm")]: {
      paddingBottom: "250px",
    },
  },
}));

export default function Theme({ children }) {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.container}>{children}</Container>
    </>
  );
}
