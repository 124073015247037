import { React } from 'react';

export default function VimeoPlayer({ width, height, videoId, color, type = 'onDemand' }) {
  const splitVideoId = videoId.split('/');
  let url = `https://player.vimeo.com/video/${ splitVideoId[0] }?autoplay=1${(color) ? `&color=${ color.substr(1)}` : ''}`;
  if (splitVideoId[1]) url += `&h=${splitVideoId[1]}`;
  if (type === 'live') url = `https://vimeo.com/event/${videoId}/embed`;

  return (
    <>
      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
        <iframe
          src={url}
          title={videoId}
          style={{ position: 'absolute', top: 0, left: 0, width, height }}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          sandbox
        />
      </div>
      <script src="https://player.vimeo.com/api/player.js" />
    </>
  );
}
