import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import config from '../config';

const useStyles = makeStyles(() => ({
  dialogActions: {
    color: 'primary',
  },
}));

export default function AutoPlay() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [retryCount, setRetryCount] = useState(5);

  const handleClose = () => {
    document.querySelector('video')?.play();
    setOpen(false);
  };

  const interval = () => {
    setRetryCount(retryCount - 1);
    const promise = document.querySelector('#customVideoPlayerParent video')?.play();
    if (promise !== undefined) {
      promise.then((_) => {
        document.querySelector('#customVideoPlayerParent video').volume = 1;
        if (document.querySelector('#customVideoPlayerParent .volume-bar-active')) {
          document.querySelector('#customVideoPlayerParent .volume-bar-active').style.width = '80px';
          document.querySelector('#customVideoPlayerParent .volume-bar-active').nextElementSibling.style.left = '72px';
        }
        setRetryCount(0);
      }).catch(() => {
        setOpen(true);
      });
    }
  };

  useEffect(() => {
    if (retryCount <= 0) {
      return;
    }
    const id = setInterval(interval, 500);
    return () => clearInterval(id);
  }, [retryCount]);

  return (
    <>
      {open
       && (
         <Dialog
           classes={{ paper: classes.dialog }}
           open={open}
           onClose={handleClose}
           aria-labelledby="form-dialog-title"
           PaperProps={{ className: 'autoplayModal' }}
         >
           <DialogTitle id="form-dialog-title">{config.autoplayDialog?.title}</DialogTitle>
           <DialogContent>
             <DialogContentText>{config.autoplayDialog?.text}</DialogContentText>
           </DialogContent>
           <DialogActions className={classes.dialogActions}>
             <Button onClick={handleClose} color="secondary">
               {config.autoplayDialog?.button}
             </Button>
           </DialogActions>
         </Dialog>
       )}
    </>
  );
}
