import React from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import AfterEvent from '../../pages/AfterEvent';
import Landing from '../../pages/Landing';
import Login from '../../pages/Login';
import Stream from '../../pages/Stream';
import MediaLibrary from '../../pages/MediaLibrary';
import PreStream from '../../pages/PreStream';
import Register, { ActivateAccount } from '../../pages/Register';
import ForgotPassword from '../../pages/ForgotPassword';
import ResetPassword from '../../pages/ResetPassword';
import Welcome from '../../pages/Welcome';
import DynamicRoute from '../../util/DynamicRoute';
import Logout from './Logout.js';
import config from '../../config';
import Page from '../../pages/Page';
import PageDetail from '../../pages/PageDetail';
import Profile from '../../pages/Profile';
import CustomPage from '../../pages/CustomPage';
import PreviewConversionCard from '../../pages/Preview/PreviewConversionCard';

export default function Router() {
  const history = useHistory();

  const searchParams = new URLSearchParams(history.location.search);
  if (searchParams.has('preview')) {
    const cookies = new Cookies();
    cookies.set('preview', searchParams.get('preview'), { path: '/', maxAge: 1000000000 });
  }

  return (
    <Switch>
      <DynamicRoute exact path="/" component={Welcome} authenticated />
      <DynamicRoute exact path="/landing" component={Landing} guest />
      {config.register?.enabled ? (
        <DynamicRoute
          exact
          path="/register/:inviteCode?"
          component={Register}
          guest
        />
      ) : null}
      <DynamicRoute
        exact
        path="/forgotPassword"
        component={ForgotPassword}
        guest
      />
      <DynamicRoute
        exact
        path="/resetPassword/:token"
        component={ResetPassword}
        guest
      />
      {config.register?.enabled ? (
        <ActivateAccount path="/activateAccount/:token" exact guest />
      ) : null}
      <DynamicRoute
        exact
        path="/preStream"
        authenticated
      >
        <Redirect to="/stream" />
      </DynamicRoute>
      <DynamicRoute
        exact
        path="/preStream/:code"
        component={PreStream}
        authenticated
      />
      <DynamicRoute exact path="/profile" component={Profile} authenticated />
      <DynamicRoute exact path="/stream" component={Stream} authenticated />
      <DynamicRoute path="/stream/:code" component={Stream} authenticated />
      <DynamicRoute
        exact
        path="/pages"
        component={Page}
        authenticated
      />
      <DynamicRoute
        path="/pages/:code"
        component={PageDetail}
        authenticated
      />
      <DynamicRoute
        exact
        path="/mediaLibrary"
        component={MediaLibrary}
        authenticated
      />
      <DynamicRoute exact path="/logout" authenticated>
        <Logout />
      </DynamicRoute>
      <DynamicRoute exact path="/fin" authenticated>
        <AfterEvent />
      </DynamicRoute>
      <DynamicRoute path="/closed" authenticated>
        <Welcome closed />
      </DynamicRoute>
      <DynamicRoute exact path="/finAnonymous">
        <AfterEvent />
      </DynamicRoute>
      <DynamicRoute path="/login/:email" component={Login} guest />
      <DynamicRoute path="/preview/cta" component={PreviewConversionCard} />
      <DynamicRoute path="*">
        <CustomPage />
      </DynamicRoute>
    </Switch>
  );
}
