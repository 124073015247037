import { useLazyQuery, useMutation } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router';
import { Divider, Link, Typography } from '@material-ui/core';
import config from '../config';
import { useAuthDispatch } from '../context/auth';
import { t } from '../locales';
import EventRegistration from './EventRegistration';
import { LOGIN_USER, LOGIN_PASSWORD, RESEND_CUSTOMER_ACTIVATION } from '../gql/graphApi';
import PasswordForm from './PasswordForm';

const useStyles = makeStyles((theme) => ({
  button: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  cssOutlinedInput: {
    borderWidth: '0px',
    '&$cssFocused $notchedOutline': {
      borderWidth: '0px',
    },
  },
  cssFocused: {
    borderWidth: '0px',
  },
  notchedOutline: {
    borderWidth: '0px',
    borderColor: 'green !important',
  },
  register: {},
  form: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  errorText: {
    color: 'red',
    marginBottom: 16,
  },
  divider: {
    backgroundColor: '#eee !important',
    marginBottom: 16,
  },
  forgotLink: {
    marginBottom: 16,
    display: 'block',
  },
  bgText: {
    color: theme.palette.background.contrastText,
    marginBottom: 8,
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 32,
  },
}));

export default function Login() {
  const classes = useStyles();
  const [variables, setVariables] = useState({
    email: '',
    password: '',
  });

  const history = useHistory();
  const [errors, setErorrs] = useState({});

  const dispatch = useAuthDispatch();

  const [loginUser, { loading, data }] = useLazyQuery(LOGIN_USER, {
    onError(err) {
      if (err.graphQLErrors[0]) {
        setErorrs({ code: err.graphQLErrors[0].extensions.code, password: true });
      } else {
        setErorrs('API ERROR');
      }
    },
    onCompleted(data) {
      dispatch({ type: 'LOGIN', payload: data.customerLogin });
    },
  });

  const [loginWithPassword, { loading: loadingPassword, data: dataWithPassword }] = useLazyQuery(LOGIN_PASSWORD, {
    onError(err) {
      if (err.graphQLErrors[0]) {
        setErorrs({ code: err.graphQLErrors[0].extensions.code, password: true });
      } else {
        setErorrs('API ERROR');
      }
    },
    onCompleted(data) {
      dispatch({ type: 'LOGIN', payload: data.customerPasswordLogin });
    },
  });

  const [resendCustomerActivation] = useMutation(RESEND_CUSTOMER_ACTIVATION, {
    onCompleted: () => {
      history.replace('/landing', {
        notification: { type: 'success', text: t('register.activationResend') },
      });
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      setErorrs({});
    },
  });

  const handleLoginWithPassword = (password) => {
    if (config.loginType === 'loginWithPasswordFE') {
      if (config.loginPassword !== password) return setErorrs({ code: 'EWRONGPASSWORD', password: true });
      let currentCustomerId = localStorage.getItem('anonymousCustomerId');
      if (currentCustomerId) currentCustomerId = JSON.parse(currentCustomerId);
      return dispatch({ type: 'LOGIN', payload: { anonymousCustomerId: currentCustomerId } });
    }
    loginWithPassword({ variables: { password } });
  };

  const submitLoginForm = (e) => {
    e.preventDefault();
    loginUser({ variables });
  };

  const onResend = (customer) => {
    resendCustomerActivation({
      variables: { email: customer.email },
    });
  };

  const renderError = () => {
    if (errors === 'API ERROR') return (<span className={classes.errorText}>{t('login.errors.api')}&nbsp;</span>);
    if (errors === 'otherUserLoggedIn') return (<span className={classes.errorText}>{t('login.errors.otherUserLoggedIn')}&nbsp;</span>);

    if (errors.code === 412) {
      return (
        <span className={classes.errorText}>
          {t('register.validation.notActivated.part1')}{' '}
          <Link onClick={() => onResend(variables)} style={{ cursor: 'pointer' }}>{t('register.validation.notActivated.part2')}</Link>
        </span>
      );
    }

    if (errors.code === 403) {
      return (
        <span className={classes.errorText}>
          {t('login.errors.forbidden')}
        </span>
      );
    }
    if (errors.email || errors.password || errors.code === 'BAD_USER_INPUT') {
      return (<span className={classes.errorText}>{t('login.errors.invalid')}&nbsp;</span>);
    }

    return null;
  };

  useEffect(() => {
    if (localStorage.getItem('logoutReason')) {
      setErorrs(localStorage.getItem('logoutReason'));
      localStorage.removeItem('logoutReason');
    }
  }, []);

  if (config.loginType === 'eventRegistration') {
    return (<EventRegistration />);
  }

  if (config.loginType === 'loginWithPassword' || config.loginType === 'loginWithPasswordFE') {
    return (
      <PasswordForm
        loading={loadingPassword || dataWithPassword}
        handleSubmit={handleLoginWithPassword}
        error={(renderError()) && renderError()}
        labels={{ password: t('login.password'), login: t('login.login') }}
      />
    );
  }

  if (config.landingPage.loginForm) {
    return (
      <>
        {config.register.enabled ? (
          <div className={classes.section}>
            <Typography className={classes.bgText}>
              {t('login.registerSection')}
            </Typography>
            <Button
              onClick={() => history.push('/register')}
              className={classes.register}
              variant="contained"
              color="primary"
            >
              {t('login.register')}
            </Button>
          </div>
        ) : null}

        <form onSubmit={submitLoginForm} className={classes.form}>
          <Typography className={classes.bgText}>
            {t((config.register.enabled) ? 'login.loginSection' : 'login.loginWithoutRegisterSection')}
          </Typography>
          <Paper elevation={3} style={{ marginBottom: '20px' }}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  value={variables.email}
                  onChange={(e) =>
                    setVariables({ ...variables, email: e.target.value })}
                  placeholder={t('login.email')}
                  InputLabelProps={{
                    classes: {
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="password"
                  variant="outlined"
                  value={variables.password}
                  onChange={(e) =>
                    setVariables({ ...variables, password: e.target.value })}
                  placeholder={t('login.password')}
                  InputLabelProps={{
                    classes: {
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
                <Divider className={classes.divider} />
                <Link
                  className={classes.forgotLink}
                  component={RouterLink}
                  to="/forgotPassword"
                >
                  {t('login.forgot')}
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.button}
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={loading}
                >
                  {loading || data ? (
                    <CircularProgress size={20} style={{ color: 'white' }} />
                  ) : (
                    t('login.login')
                  )}
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {renderError()}
        </form>
      </>
    );
  }
  return <></>;
}
