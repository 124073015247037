import {
  ApolloClient,
  ApolloProvider as Provider,
  InMemoryCache,
  from,
} from "@apollo/client";
import { RestLink } from 'apollo-link-rest';
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { createUploadLink } from "apollo-upload-client";
import React from "react";
import config from "./config";

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      switch (err.extensions.code) {
        case "UNAUTHENTICATED":
          window.location.href = "/logout";
      }
      console.log(
        `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
      );
    });
  }

  if (networkError) console.log(`[Network error]: ${networkError}`, operation);
});

const retry = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      credentials: "include",
    },
  };
});

const httpLink = createUploadLink({ uri: config.apiUrl, credentials: 'include' });

const link = from([errorLink, authLink, httpLink, retry])
const restLink = from([
  errorLink,
  new RestLink({ uri: config.apiUrl }),
]);

const client = new ApolloClient({
  link: (config.apiType === 'rest') ? restLink : link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

export default function ApolloProvider(props) {
  return <Provider client={client} {...props} />;
}
