import { Card, CardContent } from '@material-ui/core';
import React, { useEffect } from 'react';
import CTAImage from '../CTAImage';
import CTACaption from '../CTACaption';
import CTAAction from '../CTAActions';

export default function BasicCTA({ card, onCardClick, onLoad }) {
  const hasCaptions = card.params?.title || card.params?.content;

  useEffect(() => {
    onLoad && onLoad();
  }, [card]);

  return (
    <Card>
      <CTAImage card={card} onCardClick={onCardClick} onLoad={onLoad} />
      {hasCaptions
        && (
          <CardContent>
            <CTACaption params={card.params} />
          </CardContent>
        )}
      <CTAAction card={card} onCardClick={onCardClick} />
    </Card>
  );
}
