import { useTheme } from '@material-ui/core';
import React from 'react';
import VimeoPlayer from '../VimeoPlayer';

export default function OnDemandPlayer({ params }) {
  const theme = useTheme();
  switch (params.plattform) {
    case 'vimeo':
      return (
        <VimeoPlayer
          type="onDemand"
          videoId={params.videoId}
          width="100%"
          height="100%"
          color={theme.palette.primary.main}
        />
      );
    default: return null;
  }
}
