import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import config from '../config';
import { useFooterContextState } from './global/FooterContextProvider';

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    // Avoid clipping with third party bottom content like arena chat.
    marginBottom: 32,
  },
  flex: {
    margin: 0,
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'center',
    padding: '10px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  flexItem: {
    flexBasis: 'auto',
    textAlign: 'center',
    padding: '10px',
    borderLeft: 'solid 0px white',
    [theme.breakpoints.up('sm')]: {
      borderLeft: 'solid 0px white',
    },
  },
  a: {
    color: theme.palette.background.contrastText,
    '&:hover': {
      color: theme.palette.background.hover || '#0056b3',
    },
    fontSize: '16px',
    fontWeight: 400,
  },
}));

export default function Footer() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const routesWithoutNavbar = ['/preview/cta'];
  const footerState = useFooterContextState();

  if (routesWithoutNavbar.includes(pathname)) {
    return null;
  }

  return (
    <section className={classes.footer} style={footerState?.layout ? { width: '80vw', position: 'fixed', marginBottom: 0 } : {}}>
      <div className={classes.flex}>
        {config.footerLinks.map(({ name, link }) => (
          <div className={classes.flexItem} key={name}>
            <a
              className={classes.a}
              href={link}
              target="_blank"
              rel="noreferrer"
            >
              <Typography variant="subtitle2"> {name} </Typography>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
}
