import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Container from '@material-ui/core/Container';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import Img from '../components/Img';

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    color: 'primary',
  },

  titleImage: {
    marginTop: '56px',
    position: 'relative',
    width: '100%',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginTop: '64px',
    },
  },
  box: {
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  },

  video: {
    position: 'relative',
    paddingBottom: '56.25%',
    paddingTop: '0px',
    height: '0',
    borderRadius: '10px',
    marginBottom: '-1px',
    marginTop: '50px',
  },
  videoFooter: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '10px',
    paddingBottom: '10px',
    minHeight: '70px',
    backgroundColor: theme.palette.primary.main,
    marginBottom: '30px',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  image: {
    width: '100%',
  },
}));

const getPagesGql = gql`
  query{
    getPagesForFrontend{
      pages {
        code
        name
        coverImg
        sequenceId
      }
    }
  }
`;

export default function Page() {
  const classes = useStyles();
  const { loading, data: { getPagesForFrontend } = { } } = useQuery(getPagesGql);

  return (
    <>
      <Container maxWidth="md" style={{ marginTop: '106px' }}>
        <Grid
          container
          style={{ marginTop: '25px' }}
          direction="row"
          justify="center"
          alignItems="center"
          spacing={5}
        >
          {loading && <CircularProgress size={20} className={classes.submitLoader} />}
          {getPagesForFrontend && getPagesForFrontend.pages.map((page) => (
            <Grid item xs={12} sm={12} md={6} key={page.code}>
              <Link to={`/pages/${page.code}`}>
                <Paper className={classes.paper} elevation={2}>
                  <Img src={page.coverImg} alt="" className={classes.image} />
                </Paper>
              </Link>
            </Grid>
          ))}

        </Grid>

      </Container>
    </ >
  );
}
