import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import config from '../config';

import de from './de';
import en from './en';
import deFormal from './de-formal';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        ...en,
        ...config.i18n?.overrides,
      },
    },
    de: {
      translation: {
        ...de,
        ...config.i18n?.overrides,
      },
    },
    'de-DE-SIE': {
      translation: {
        ...deFormal,
        ...config.i18n?.overrides,
      },
    },
  },
  lng: config.i18n?.language || 'de',
  fallbackLng: 'de',
});

// eslint-disable-next-line import/prefer-default-export
export const t = (...params) => i18n.t(...params);
