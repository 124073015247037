import React from "react";
import { CookieBanner } from '@palmabit/react-cookie-law';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { t } from "../locales";
import config from "../config";

export default function CookieConsent () {
  const { pushInstruction } = useMatomo();
  return (
    <CookieBanner
      //styles={{ dialog: { zIndex: 10000000000 } }}
      message={t('cookieBanner.text')}
      policyLink={config.cookieBanner.privacyPolicyLink}
      privacyPolicyLinkText={t('cookieBanner.privacyPolicy')}
      necessaryOptionText={t('cookieBanner.necessaryOptionText')}
      preferencesOptionText={t('cookieBanner.preferencesOptionText')}
      statisticsOptionText={t('cookieBanner.statisticsOptionText')}
      marketingOptionText={t('cookieBanner.marketingOptionText')}
      acceptButtonText={t('cookieBanner.acceptButtonText')}
      declineButtonText={t('cookieBanner.declineButtonText')}
      managePreferencesButtonText={t('cookieBanner.managePreferencesButtonText')}
      savePreferencesButtonText={t('cookieBanner.savePreferencesButtonText')}
      wholeDomain={true}
      showMarketingOption={false}
      preferencesDefaultChecked={true}
      statisticsDefaultChecked={true}
      onAccept = {() => {}}
      onAcceptPreferences = {() => {
        window.gtag && window.gtag('consent', 'update', { ad_storage: 'granted' });
      }}
      onAcceptStatistics = {() => {
        window.gtag && window.gtag('consent', 'update', { analytics_storage: 'granted' });
        pushInstruction('rememberConsentGiven');
      }}
    />
  )
}