import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { useQuery } from '@apollo/client';
import JsxParser from 'react-jsx-parser';
import Loading from '../components/global/Loading';
import GqlAPI from '../gql';
import config from '../config';
import { t } from '../locales';
import PublicLayout from '../components/layout/PublicLayout';
import { useAuthState, useAuthDispatch } from '../context/auth';

let dialogContent = null;
let dialogTitle = '';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogActions: {
    color: 'primary',
  },
}));

export default function Welcome({ closed = false }) {
  const authDispatch = useAuthDispatch();
  const { data } = useQuery(GqlAPI.GET_WELCOME);
  const { user } = useAuthState();
  const classes = useStyles();
  const [open, setOpen] = React.useState(closed);

  useQuery(GqlAPI.GET_CURRENT_CUSTOMER, {
    skip: !user || user?.alias === 'anonymous',
    onCompleted(data) {
      authDispatch({ type: 'UPDATE', payload: data.getCurrentCustomer });
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  if (config.redirectToFin) {
    window.location.href = '/fin';
  }
  let content = null;
  let buttons = config.welcomePage?.buttons?.length
    ? config.welcomePage.buttons : (config.welcomePage?.disableButtons ? null : [{ link: '/preStream', label: t('welcome.openLivestream') }]);

  buttons = buttons?.filter((button) => {
    if (!button.ticketCategories) return true;
    return button.ticketCategories.includes(user.ticketCategoryCode);
  });

  if (data && data.getWelcome) {
    dialogContent = data.getWelcome.modal.lines.map((line) => (
      <Typography variant="body1" paragraph>
        {line}
      </Typography>
    ));

    dialogTitle = data.getWelcome.modal.title;

    const lines = data.getWelcome.lines.map((line) => (
      <Typography variant="body1" paragraph>
        {line}
      </Typography>
    ));

    content = (
      <>
        <JsxParser
          components={{ Typography, Paper, Grid, Link, Button, Fragment }}
          jsx={config.welcomePage && config.welcomePage.additionalContentTop}
          bindings={{ data, config, user }}
        />
        {data.getWelcome.title
        && (
          <Typography color="primary" paragraph variant="h3">
            {data.getWelcome.title}{' '}
          </Typography>
        )}
        {data.getWelcome.subtitle
        && (
          <Typography color="primary" paragraph variant="h4">
            {data.getWelcome.subtitle}
          </Typography>
        )}
        {lines}
      </>
    );

    return (
      <>
        <PublicLayout
          title=""
          containerWidth="lg"
          titleImage={config.welcomePage?.titleImage}
          titleImageAltText={config.welcomePage?.titleImageAltText}
          buttons={buttons}
        >
          {content && (
            <>
              <PublicLayout.Box buttons={buttons}>
                {content}
              </PublicLayout.Box>
            </>
          )}
        </PublicLayout>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          PaperProps={{ className: 'welcomeModal' }}
        >
          <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogContent}</DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            {(config.loginType !== 'anonymousLogin' && config.loginType !== 'eventRegistration')
              && (
                <Link to="/logout">
                  <Button color="secondary">{t('logout')}</Button>
                </Link>
              )}
            <Button onClick={handleClose} color="secondary">
              {t('welcome.close')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return <Loading />;
}
