// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // Common ////////////////////////////////////////////////////////////////
  'common.submit': 'Submit',

  // Login ////////////////////////////////////////////////////////////////
  'login.email': 'E-Mail',
  'login.password': 'Password',
  'login.forgot': 'Forgot your password?',
  'login.loginSection': 'Already have an account?',
  'login.loginWithoutRegisterSection': 'Login',
  'login.login': 'Login',
  'login.registerSection': 'Need to sign up?',
  'login.register': 'Sign up',
  'login.errors.api': 'Service is currently unavailable',
  'login.errors.invalid': 'Invalid credentials',
  'login.errors.forbidden': 'The user iw not allowed to enter this event',

  // Registration ////////////////////////////////////////////////////////////////
  'register.title': 'Registration',
  'register.email': 'E-Mail',
  'register.password': 'Password',
  'register.passwordRepeat': 'Password (repeat)',
  'register.firstname': 'First name',
  'register.lastname': 'Last name',
  'register.inviteCode': 'Invite code',
  'register.submit': 'Submit',

  'register.success':
      'Your registration was successful. You will receive an e-mail shortly with further information to activate your account.',
  'register.activateSuccess':
      'Your account has been successfully activated, you may sign in.',
  'register.activateError': 'Your account activation failed.',
  'register.activationResend':
      'An activation e-mail was send to your e-mail address. Please check your inbox and your spam folder.',

  'register.validation.required': '$t(register.{{field}}) is required',
  'register.validation.email': 'Please enter a valid e-mail address.',
  'register.validation.inviteCode.alreadyUsed':
      'Invitation code has already been used.',
  'register.validation.inviteCode.invalid': 'Invitation code is invalid.',
  'register.validation.emailDuplicated': 'The entered e-mail address is already in use.',
  'register.validation.notActivated.part1':
      'Your Account is not activated yet. Please check your e-mail inbox for the activation link.',
  'register.validation.notActivated.part2':
      'Click here to send a new activation e-mail.',
  'register.validation.passwordReq':
      'The password has to be at least 8 characters long.',
  'register.validation.passwordRepeat': 'Passwords need to match.',

  // Forgot PW ////////////////////////////////////////////////////////////////
  'forgotPw.title': 'Forgot Password',
  'forgotPw.submit': 'Submit',
  'forgotPw.description':
      'If you forgot your password, you can request a new one.',
  'forgotPw.success':
      'An e-mail with further information will be sent, if an account with your e-mail exists.',

  'forgotPw.resetTitle': 'Reset Password',
  'forgotPw.resetSubmit': 'Submit',
  'forgotPw.resetPasswordSuccess':
      'Your password has been reset successfully. You may sign in with your new password.',
  'forgotPw.resetPasswordError': 'Resetting your password failed.',

  // Welcome ////////////////////////////////////////////////////////////////
  'welcome.openLivestream': 'Livestream',
  'welcome.close': 'Close',

  // Chat ////////////////////////////////////////////////////////////////
  'cometChat.chats': 'Chats',
  'cometChat.users': 'Users',
  'cometChat.groups': 'Groups',
  'cometChat.groupImgAltText': 'Avatar for group {{groupName}}',
  'cometChat.fabButtonAriaText': 'Open Chat. {{unreadMessagesText}} new messages',

  // Stream ////////////////////////////////////////////////////////////////
  'stream.cancel': 'Do you really want to leave the livestream page?',
  'stream.livestream.fail': 'Livestream could not be loaded.',
  'stream.button.reload': 'Reload',
  'stream.visitorCount.label': '{{visitorCount}} watching now',
  'stream.extern.loading': 'You will be redirected. Please wait.',

  // Thank You /////////////////////////////////////////////////////////////
  thanks: 'Thank you for participating.',

  // STREAM SP /////////////////////////////////////////////////////////////
  'streamSp.questionTitle': 'Ask a Question',
  'streamSp.questionInput': 'Submit your question here.',
  'streamSp.labelName': 'Your name',
  'streamSp.labelQuestion': 'Your question...',
  'stream.labelName': 'Your name',

  // *************** COMPONENTS ********* ////////////////////////////////////

  // Poll Elements ///////////////////////////////////////////////////////////
  'module.fail': 'Could not load module.',
  'poll.totalVotes': 'Total Votes: ',
  'poll.customersVoted': 'Participated: ',
  'poll.votes': 'Votes',
  'poll.wordCloudInputLabel': 'Enter a word',
  'poll.wordCloudSend': 'Send',
  'poll.wordCloudResend': 'Add response',

  // Player Zoom /////////////////////////////////////////////////////////////
  'zoom.name': 'Zoom Name',
  'zoom.editName': 'Please fill in your name to join the event',
  'zoom.participate': 'Join',
  'zoom.end': 'The Zoom Meeting has ended.',

  // loading
  loading: 'loading...',

  // Layout ///////////////////////////////////////////////////////////////////
  'layout.back': 'Back to the livestream',

  // After Event /////////////////////////////////////////////////////////////
  logout: 'Logout',

  // Profile  /////////////////////////////////////////////////////////////
  'profile.title': 'My Profile',
  'profile.email': 'E-Mail',
  'profile.firstname': 'First name',
  'profile.lastname': 'Last name',
  'profile.ticket': 'Ticket',
  'profile.actions': 'Change profile',
  'profile.delete': 'Delete profile',
  'profile.reset': 'Reset password',
  'profile.resetSuccess':
      'You will receive an e-mail with further information shortly.',
  'profile.deleteSuccess': 'Your account has been deleted successfully.',
  'profile.deleteError': 'Wrong password',
  'profile.deleteText':
      'If you want to delete your profile, enter your password. This action cannot be undone.',
  'profile.submitDelete': 'Delete profile',

  // Media Library  /////////////////////////////////////////////////////////////
  'mediaLibrary.showMore': 'Show more',
  'mediaLibrary.showLess': 'Show less',

  // Cookie Banner  /////////////////////////////////////////////////////////////
  // eslint-disable-next-line max-len
  'cookieBanner.text': 'We use cookies and similar tools to improve your event experience, to provide our services, to understand how customers use our services so that we can make improvements, and to display advertisements. Approved third-party vendors also use these tools in connection with our display of advertisements.',
  'cookieBanner.privacyPolicy': 'Privacy Policy',
  'cookieBanner.necessaryOptionText': 'Obligatory',
  'cookieBanner.preferencesOptionText': 'Preferences',
  'cookieBanner.statisticsOptionText': 'Statistics',
  'cookieBanner.marketingOptionText': 'Marketing',
  'cookieBanner.acceptButtonText': 'Accept all cookies',
  'cookieBanner.declineButtonText': 'Decline',
  'cookieBanner.managePreferencesButtonText': 'Manage Cookie Settings',
  'cookieBanner.savePreferencesButtonText': 'Save settings',

  // Forms  /////////////////////////////////////////////////////////////
  'form.submit.success': 'The form has been submitted.',
  'form.submit.error.default': 'An error occoured while submitting the form. Please try again',
  'form.submit.error.notFound': 'Form not found.',
  'form.submit.error.closed': 'The form is closed, new submissions can not be accepted.',
  'form.loading.error': 'Could not load the form',
  'form.button.cancel': 'Cancel',
  'form.button.submit': 'Submit',
  'form.button.reset': 'New Submission',
  'form.anonym': 'Anonymous',

  // CustomPage  /////////////////////////////////////////////////////////////
  'customPage.error.e404': 'Page not found.',
  'customPage.error.e403': 'You need to be logged in to view this page.',
  'customPage.error.e4031': 'Page is not available yet or not available any longer.',
  'customPage.error.e4032': 'This page is password protected, the given password is incorrect.',
  'customPage.error.e4033': 'You are not allowed to view this page. Wrong ticket category',
  'customPage.error.default': 'Error accessing the page',
  'customPage.error.password': 'Wrong password.',
  'customPage.password': 'Password',
  'customPage.unlock': 'Unlock',
  'customPage.tooEarlyDialog.title': 'The event has not started yet.',
  'customPage.tooEarlyDialog.content': 'This page is only available during the event. Please try again later',
  'customPage.tooEarlyDialog.retryAgain': 'Try again',

  // ArenaChat  /////////////////////////////////////////////////////////////
  'arenaChat.header': 'Chat',

  // ConversionCard  /////////////////////////////////////////////////////////////
  'cta.empty': 'There are currently no Cards available',

};
