import {
  Button,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContentText,
  CircularProgress,
} from '@material-ui/core';
import { React, useEffect, useMemo, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useLocation } from 'react-router-dom';
import { useAuthState } from '../context/auth';
import DynamicForm from './DynamicForm';
import { t } from '../locales';
import { getFormGql, addFormSubmissionGql } from '../gql/graphApi';

export default function QandA(props) {
  const { user } = useAuthState();
  const snackbar = useSnackbar();
  const { search, pathname } = useLocation();
  const [formData, setFormData] = useState(null);
  const { trackEvent } = useMatomo();
  const [addQuestion, { loading }] = useMutation(addFormSubmissionGql, {
    onCompleted: () => {
      snackbar.showMessage(t('form.submit.success'));
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 404) return snackbar.showMessage(t('form.submit.error.notFound'));
      if (err.graphQLErrors[0]?.extensions.code === 409) return snackbar.showMessage(t('form.submit.error.closed'));
      return snackbar.showMessage(t('form.submit.error.default'));
    },
  });

  const [getForm, { loading: formLoading }] = useLazyQuery(getFormGql, {
    onCompleted(data) {
      const newFields = data.getFormForFrontend?.fields.map((field) => {
        let { defaultValue } = field;
        if (field.params?.queryParam) {
          defaultValue = window.sessionStorage.getItem(`form_${pathname}_modal_${field.params?.queryParam}`);
        }
        return { ...field, defaultValue };
      });
      setFormData({ ...data.getFormForFrontend, fields: newFields });
    },
    onError() {
      snackbar.showMessage(t('form.loading.error'));
    },
  });

  const [variables, setVariables] = useState({
    author: user?.firstname,
    fields: {},
  });

  const query = useMemo(() => new URLSearchParams(search), [search]);
  for (const entry of query.entries()) {
    window.sessionStorage.setItem(`form_${pathname}_modal_${entry[0]}`, entry[1]);
  }

  const handleClose = () => {
    setVariables({ ...variables, content: '' });
    props.onQuestionCloseClick();
  };

  const handleSubmitFormular = async (fieldsWithValues) => {
    let author = fieldsWithValues.formAuthor;
    if (formData?.params?.hideFormAuthor) author = `${user?.firstname} ${user?.lastname}`;
    const fields = Object.entries(fieldsWithValues)
      .filter(([key, value]) => value !== '' && key !== 'formAuthor')
      .map(([key, value]) => ({ formFieldId: parseInt(key), content: value.toString() }));
    await addQuestion({
      variables: { author, fields, code: props.requestForm.code },
    });
    trackEvent({ category: 'stream', action: 'submitFormular', value: props.requestForm.code });
    handleClose();
    setVariables({ author: user?.firstname, fields: {} });
  };

  useEffect(() => {
    if (props.requestForm.code) {
      getForm({ variables: { code: props.requestForm.code } });
    }
  }, [props.requestForm.code]);

  const activeFields = useMemo(() => {
    if (!formData?.fields) return [];

    const activeFields = formData?.fields.map((field) => {
      const mappedField = { ...field, code: field.id, default: '', orgType: field.type };
      if (mappedField.isRequired) {
        mappedField.validation = [
          {
            type: 'required',
            message: t('common.validation.required', { field: mappedField.label, interpolation: { escapeValue: false } }),
          },
        ];
        if (field.type === 'checkbox') {
          mappedField.validation.push({
            type: 'oneOf',
            message: t('common.validation.required', { field: mappedField.label, interpolation: { escapeValue: false } }),
            params: [[true]],
          });
        }
        mappedField.label = `${mappedField.label} *`;
      }
      return mappedField;
    }) || [];

    const anonymCheckboxField = activeFields.find(({ type }) => type === 'anonymCheckbox');

    if (!formData?.params?.hideFormAuthor) {
      activeFields.unshift({
        code: 'formAuthor',
        label: t('stream.labelName'),
        type: 'text',
        default: anonymCheckboxField?.defaultValue === 'true' ? 'Anonym' : user?.firstname,
        validation: [
          {
            type: 'required',
            message: t('common.validation.required', { field: t('stream.labelName') }),
          },
        ],
      });
    }
    return activeFields;
  }, [formData?.fields, user?.firstname]);

  const dialogButtons = (
    <DialogActions>
      <Button onClick={handleClose} color="secondary">
        {t('form.button.cancel')}
      </Button>
      <Button type="submit" color="primary">
        {loading || formLoading ? (
          <CircularProgress size={20} color="secondary" />
        ) : (
          t('form.button.submit')
        )}
      </Button>
    </DialogActions>
  );

  return (
    <>
      <Dialog
        open={props.requestForm.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {formData?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {' '}
            {formData?.description}{' '}
          </DialogContentText>
          {formData?.fields && (
            <DynamicForm
              activeFields={activeFields}
              onSubmit={handleSubmitFormular}
              submitLabel={t('form.button.submit')}
              actionButtonsComponent={dialogButtons}
              onChange={(value, field, formik) => {
                if (field.type === 'anonymCheckbox') {
                  formik.setFieldValue('formAuthor', value ? 'Anonym' : user?.firstname);
                }
              }}
            />
          )}

        </DialogContent>
      </Dialog>
    </>
  );
}
