import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Typography from "@material-ui/core/Typography";
import DynamicForm from "../components/DynamicForm";
import { useCallback } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router";
import * as yup from "yup";
import PublicLayout from "../components/layout/PublicLayout.js";
import { t } from "../locales";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: 32,
  },
}));

const fields = [
  {
    code: "password",
    type: "password",
    label: `${t("register.password")} *`,
    default: "",
    validation: [
      {
        type: "required",
        message: t("register.validation.required", { field: "password" }),
      },
      {
        type: "min",
        message: t("register.validation.passwordReq"),
        params: [8],
      },
    ],
  },
  {
    code: "passwordConfirm",
    label: `${t("register.passwordRepeat")} *`,
    type: "password",
    default: "",
    validation: [
      {
        type: "required",
        message: t("register.validation.required", { field: "password" }),
      },
      {
        type: "min",
        message: t("register.validation.passwordReq"),
        params: [8],
      },
      {
        type: "oneOf",
        message: t("register.validation.passwordRepeat"),
        params: [[yup.ref("password")]],
      },
    ],
  },
];

const SET_CUSTOMER_PASSWORD = gql`
  mutation ($resetToken: String!, $password: String!) {
    setCustomerPassword(resetToken: $resetToken, password: $password)
  }
`;

export default function SetPassword(props) {
  const classes = useStyles();
  const history = useHistory();
  const { token } = useParams();

  const [setCustomerPassword] = useMutation(SET_CUSTOMER_PASSWORD);

  const onSubmit = useCallback(
    async (data) => {
      let notification = {};
      try {
        await setCustomerPassword({
          variables: { password: data.password, resetToken: token },
        });
        notification.type = "success";
        notification.text = t("forgotPw.resetPasswordSuccess");
      } catch (e) {
        notification.type = "error";
        notification.text = t("forgotPw.resetPasswordError");
      }

      history.push("/landing", { notification });
      return true;
    },
    [setCustomerPassword, token, history]
  );

  return (
    <PublicLayout title={t("forgotPw.resetTitle")}>
      <PublicLayout.Box>
        <Typography variant="h3" color="primary">
          {t("forgotPw.resetTitle")}
        </Typography>
        <DynamicForm
          className={classes.form}
          activeFields={fields}
          onSubmit={onSubmit}
          submitLabel={t("forgotPw.resetSubmit")}
        />
      </PublicLayout.Box>
    </PublicLayout>
  );
}
