import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import YouTube from 'react-youtube';

const useStyles = makeStyles({
  iframe: {
    border: 'none',
    height: '100%',
    width: '100%',
  },
  root: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  rootError: {
    background: '#000',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 32,
    textAlign: 'center',
    color: '#fff',
  },
});

export default function YoutubePlayer({ params }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <YouTube
        videoId={params.videoId}
        containerClassName={classes.root}
        className={classes.iframe}
        opts={{ playerVars: { autoplay: 1 } }}
        onReady={(event) => event.target.playVideo()}
      />
    </div>
  );
}
