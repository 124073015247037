import React, { createContext, useReducer, useContext, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';
import { parseDomain } from 'parse-domain';
import { useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import config from '../config';
import { LOGIN_STATUS } from '../gql/graphApi';

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

const cookies = new Cookies();

let user = localStorage.getItem('user');
if (user) user = JSON.parse(user);
let anonymousCustomerId = localStorage.getItem('anonymousCustomerId');
if (anonymousCustomerId) anonymousCustomerId = JSON.parse(anonymousCustomerId);

const token = localStorage.getItem('token');
if (token) {
  const decodedToken = jwtDecode(token);
  const expiresAt = new Date(decodedToken.exp * 1000);

  if (!user) user = decodedToken;
  if (new Date() > expiresAt) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    user = null;
  }
} else console.log('No token found');

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN': {
      let anonymousCustomerId;
      if (action.payload.token) {
        localStorage.setItem('token', action.payload.token);
        const decodedToken = jwtDecode(action.payload.token);
        anonymousCustomerId = decodedToken.anonymousCustomerId;
        if (anonymousCustomerId) localStorage.setItem('anonymousCustomerId', JSON.stringify(anonymousCustomerId));
        else localStorage.removeItem('anonymousCustomerId');
      }
      localStorage.setItem('user', JSON.stringify({ ...action.payload, token: undefined }));

      return {
        ...state,
        user: action.payload,
        anonymousCustomerId: anonymousCustomerId || undefined,
      };
    }
    case 'LOGOUT':
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('anonymousCustomerId');
      if (config.parentProjectId) {
        const parsedResult = parseDomain(window.location.host);
        cookies.remove(`psid_${config.parentProjectId}`, {
          domain: (parsedResult.subDomains.length)
            ? `.${parsedResult.domain}.${parsedResult.topLevelDomains.join('.')}`
            : window.location.host,
        });
      }
      window.location.href = '/landing';

      return {
        ...state,
        user: undefined,
        anonymousCustomerId: undefined,
      };
    case 'UPDATE':
      localStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
        anonymousCustomerId: anonymousCustomerId || undefined,
      };

    case 'SETANONYMOUS': {
      const anonymousCustomerId = uuidv4();
      localStorage.setItem('anonymousCustomerId', JSON.stringify(anonymousCustomerId));
      return {
        ...state,
        anonymousCustomerId,
      };
    }

    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, { user, anonymousCustomerId });
  const { data } = useQuery(LOGIN_STATUS, {
    fetchPolicy: 'network-only',
    skip: !state?.user || config.sessionMode !== 'oneSessionOnly' || state?.user.ticket === 'admin' || state?.anonymousCustomerId,
    pollInterval: 10000,
  });

  useEffect(() => {
    if (state.user?.token && state.user?.alias === 'anonymous') window.location.href = '/';
  }, [state.user?.token]);

  useEffect(() => {
    if (state?.user && data && !data?.getCustomerLoginStatus) {
      localStorage.setItem('logoutReason', 'otherUserLoggedIn');
      window.location.href = '/logout';
    }
    if (!state?.user && !state.anonymousCustomerId) dispatch({ type: 'SETANONYMOUS' });
  });

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={state}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};

export const useAuthState = () => useContext(AuthStateContext);
export const useAuthDispatch = () => useContext(AuthDispatchContext);
