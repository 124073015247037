import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import config from '../../config';
import NavBar from './NavBar';
import { useRouteBasedConfig } from '../../util/routeBasedConfig';
import { useNavBarContextState } from '../global/NavBarContextProvider';

const useStyles = makeStyles((theme) => ({
  antiOverlay: {
    height: theme.mixins.toolbar.minHeight + 8,
  },
}));

export default function DynamicNavBar() {
  const { pathname } = useLocation();
  const activeConfig = useRouteBasedConfig('navbars');
  const classes = useStyles();
  const navBarState = useNavBarContextState();
  const documentTitle = config.appendPageTitleToDocumentTitle && navBarState?.title
    ? `${config.documentTitle || config.title} - ${navBarState?.title}` : (config.documentTitle || config.title);

  const routesWithoutNavbar = ['/preview/cta'];

  if (
    !activeConfig
    || !activeConfig.visible
    || activeConfig.isVisible === false
    || routesWithoutNavbar.includes(pathname)
  ) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>
      <NavBar
        items={activeConfig.items}
        burger
        title={navBarState?.title || (activeConfig.hideTitle ? '' : (activeConfig?.pageTitle || config?.title))}
      />
      {activeConfig.overlay === false ? (
        <div className={classes.antiOverlay} />
      ) : null}
    </>
  );
}
