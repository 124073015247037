import { useEffect } from 'react';
import { useAuthDispatch } from '../../context/auth';

export default function Projects() {
  const authDispatch = useAuthDispatch();

  useEffect(() => {
    authDispatch({ type: 'LOGOUT' });
  }, []);

  return null;
}
