import { makeStyles, Slide } from "@material-ui/core";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Alert, AlertTitle } from "@material-ui/lab";

const Context = createContext();

export function useNotificationProvider() {
  return useContext(Context);
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    right: 8,
    [theme.breakpoints.down("sm")]: {
      left: 8,
      bottom: 64,
    },
  },
}));

export function NotificationProvider({ children }) {
  const api = useRef();
  const classes = useStyles();
  const [activeNotifications, setActiveNotifications] = useState([]);
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    api.current = {
      remove(id) {
        setActiveNotifications(activeNotifications.filter((a) => a.id !== id));
      },
      show(severity, message, timeout = 10000, title = null) {
        const id = Math.random();
        setActiveNotifications([
          ...activeNotifications,
          { severity, message, title, id },
        ]);
        setTimeout(() => {
          api.current.remove(id);
        }, timeout);
      },
    };
    if (!isReady) {
      setReady(true);
    }
  }, [activeNotifications, setActiveNotifications, isReady]);

  if (!isReady) {
    return null;
  }

  return (
    <Context.Provider value={api}>
      {children}
      <div className={classes.root}>
        {activeNotifications.map((notification) => (
          <Slide
            direction="up"
            in={true}
            mountOnEnter
            unmountOnExit
            key={notification.id}
          >
            <Alert severity={notification.severity}>
              {notification.title ? (
                <AlertTitle>{notification.message}</AlertTitle>
              ) : null}
              {notification.message}
            </Alert>
          </Slide>
        ))}
      </div>
    </Context.Provider>
  );
}
