import { Container, Grid, Paper, Button } from '@material-ui/core';
import { React, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import JsxParser from 'react-jsx-parser';
import { useLocation } from 'react-router';
import config from '../config';
import { t } from '../locales';
import ConversionCards from '../components/ConversionCards';
import PublicLayout from '../components/layout/PublicLayout';
import QandA from '../components/QandA';
import { useAuthState } from '../context/auth';

export default function AfterEvent() {
  const location = useLocation();
  const [formRequest, setFormRequest] = useState({ open: false, code: null });
  const { user } = useAuthState();
  if (config.afterEvent?.redirectTo) {
    window.location.href = config.afterEvent?.redirectTo;
    return null;
  }

  const buttons = (config.loginType !== 'anonymousLogin' && config.loginType !== 'eventRegistration') && user
    ? [{ link: '/logout', label: t('logout') }]
    : null;

  const renderContent = () => {
    let jsx = config.afterEvent?.additionalContentTop;
    let thankYouText = config.afterEvent?.thankYouText;

    if (location.pathname === '/finAnonymous' && config.afterEvent?.anonymous) {
      jsx = config.afterEvent?.anonymous?.additionalContentTop;
      thankYouText = config.afterEvent?.anonymous?.thankYouText;
    }
    return (
      <>
        <JsxParser
          components={{ Typography, Paper, Grid, Button }}
          jsx={jsx}
          bindings={{ config }}
        />
        <Typography
          color="primary"
          paragraph
          variant="h3"
          style={{ textAlign: 'center' }}
        >
          {thankYouText}
        </Typography>
      </>
    );
  };

  return (
    <>
      <PublicLayout title="" containerWidth="lg" titleImage={config.afterEvent?.titleImage} buttons={buttons}>
        <PublicLayout.Box buttons={buttons}>
          {renderContent()}
        </PublicLayout.Box>
      </PublicLayout>
      <Container maxWidth="lg" style={{ marginBottom: 64 }}>
        {config?.afterEvent?.enableConversionCards && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ConversionCards onQuesionRequestClick={(requestForm) => setFormRequest({ open: true, code: requestForm.formCode })} />
            </Grid>
          </Grid>
        )}
      </Container>
      <QandA requestForm={formRequest} onQuestionCloseClick={() => setFormRequest({ open: false, code: null })} />
    </>
  );
}
