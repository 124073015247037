import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  CircularProgress,
  Button,
  makeStyles,
} from '@material-ui/core';

import { t } from '../../locales';
import DynamicFormTextField from './fields/DynamicFormTextField';
import DynamicFormCheckboxField from './fields/DynamicFormCheckboxField';
import DynamicFormRating from './fields/DynamicFormRating';

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: 32,
    marginBottom: 32,
  },
  submitLoader: {
    color: '#fff',
  },
}));

const FieldTypeMapping = {
  text: DynamicFormTextField,
  multilineText: DynamicFormTextField,
  password: DynamicFormTextField,
  email: DynamicFormTextField,
  number: DynamicFormTextField,
  url: DynamicFormTextField,
  checkbox: DynamicFormCheckboxField,
  anonymCheckbox: DynamicFormCheckboxField,
  hidden: DynamicFormTextField,
  rating: DynamicFormRating,
};

export default function DynamicForm({
  activeFields,
  className,
  onSubmit,
  footer = null,
  submitLabel = null,
  readOnly = false,
  actionButtonsComponent = null,
  onChange = null,
}) {
  const classes = useStyles();
  const [loading, setIsLoading] = useState(false);

  // Create validation schema
  const activeValidation = useMemo(() => {
    const fields = activeFields.map((field) => {
      const baseValidation = (field.type === 'checkbox') ? yup.bool() : yup.string();
      const nestedRules = field.validation?.reduce(
        (current, validation) =>
          current[validation.type](
            ...(validation.params || []),
            validation.message,
          ),
        baseValidation,
      ) || baseValidation;
      return {
        [field.code]: nestedRules,
      };
    });
    return yup.object(Object.assign({}, ...fields));
  }, [activeFields]);

  // Build form
  const initialValues = useMemo(() => {
    const fields = activeFields.map((field) => ({
      [field.code]: field.defaultValue || field.default,
    }));
    return Object.assign({}, ...fields);
  }, [activeFields]);

  const formik = useFormik({
    initialValues,
    validationSchema: activeValidation,
    onSubmit: async (values, actions) => {
      setIsLoading(true);
      try {
        const output = await onSubmit(values);
        if (typeof output === 'object') {
          actions.setErrors(output);
        }
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    },
  });

  const buttonsCmp = useMemo(() => {
    if (readOnly) return null;
    if (actionButtonsComponent) return actionButtonsComponent;
    return (
      <Button
        className={classes.submit}
        size="large"
        variant="contained"
        color="primary"
        type="submit"
        disabled={loading}
        fullWidth
      >
        {loading ? (
          <CircularProgress size={20} className={classes.submitLoader} />
        ) : (
          submitLabel || t('common.submit')
        )}
      </Button>
    );
  }, [readOnly, actionButtonsComponent, loading, submitLabel]);

  const formElements = activeFields.map((field) => {
    const Component = FieldTypeMapping[field.type];
    let fieldHidden = false;
    if (field.type === 'hidden') fieldHidden = true;
    let fieldReadOnly = readOnly;
    if (field.code === 'formAuthor') {
      const anonymCheckboxFieldCode = activeFields.find(({ type }) => type === 'anonymCheckbox')?.code;
      if (anonymCheckboxFieldCode && formik.values[anonymCheckboxFieldCode]) {
        fieldReadOnly = true;
      }
    }
    return (
      <Component
        key={field.code}
        code={field.code}
        type={field.type}
        params={field.params}
        hintText={field.hintText}
        label={field.label}
        formik={formik}
        disabled={fieldReadOnly}
        hidden={fieldHidden}
        onChange={(value) => {
          onChange && onChange(value, field, formik);
        }}
      />
    );
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={className}>
        {formElements}
        {footer}
        {buttonsCmp}
      </form>
    </>
  );
}
