import merge from 'lodash/merge';

const defaults = {
  themeJSON: {
    palette: {
      background: {
        contrastText: '#fff',
      },
    },
    overrides: {
      MuiAlert: {
        message: {
          fontSize: '1rem',
        },
      },
    },
  },
};

const config = window.wd_config || {
  apiUrl: '/graphql',
  loginType: 'anonymousLogin',
  sessionMode: 'normal',
  projectId: 4,
  logo: '',
  favicon:
    '',
  appleTouchIcon:
    '',
  i18n: {
    language: 'de-DE-DU',
    overrides: {},
  },
  footerLinks: [
    {
      name: 'Impressum',
      link: 'https://wirkung.group/digital/impressum/',
    },
    {
      name: 'Datenschutz',
      link: 'https://wirkung.group/digital/datenschutz/',
    },
    {
      name: 'AGB',
      link: 'https://wirkung.group/digital/haftungsausschluss/',
    },
    {
      name: 'Technik',
      link: 'https://wirkung.group/digital/',
    },
  ],
  navbars: [{
    path: ['/*'],
    items: [],
    overlay: true,
    visible: true,
    hideTitle: false,
  }],
  cookieBanner: {
    isEnabled: true,
    privacyPolicyLink: 'https://wirkung.group/digital/datenschutz/',
  },
  autoplayDialog: {
    title: 'Schön, dass du zurück bist!',
    text: 'Um fortzufahren klicke auf "Weiter".',
    button: 'Weiter',
  },
  matomo: {
    urlBase: 'https://wirkung-digital.matomo.cloud',
    siteId: 2,
    disabled: true,
  },
  title: 'WIRKUNG.stream - Oops!',
  landingPage: {
    loginForm: true,
    titleImage:
      'https://via.placeholder.com/1920x576.jpg?text=Oops!',
    text: '',
    textBackup: "<div className=\"card-content\" style={{ padding: '28px', zIndex: 1000, borderBottomLeftRadius: 5, borderBottomRightRadius: 5}}>\n        <Typography color=\"primary\" paragraph variant=\"h3\">Lorem ipsum.</Typography>\n        <Typography variant=\"body1\" paragraph >Lorem ipsum.</Typography>\n    </div>",

  },
  welcomePage: {
    hideTitleInNavbar: false,
    buttons: [{
      link: '/?ok=1',
      label: 'Ok',
    }],
    titleImage:
    'https://via.placeholder.com/1920x576.jpg?text=Oops!',
    additionalContentTop: '<div><Typography color="primary" paragraph variant="h3">Oops!</Typography>\n<Typography color="primary" paragraph variant="h5">Da ist etwas schiefgelaufen.</Typography>\n<Typography variant="body1" paragraph>Bitte lade die Seite neu oder wechsle den Browser.</Typography></div>',

  },
  afterEvent: {
    titleImage: '',
    thankYouText: 'Danke für Deine Teilnahme.',
    hideTitleInNavbar: false,
    enableConversionCards: true,
  },
  eventProjectId: 6,
  register: {
    enabled: true,
    invitations: true,
    text: '',
    footer: '',
    customFields: [{
      code: 'business',
      type: 'text',
      label: 'Firma *',
      default: '',
      validation: [{
        type: 'required',
        message: 'Firma ist erforderlich',
      }, {
        type: 'min',
        params: [2],
        message: 'Firma ist erforderlich',
      }],
    }, {
      code: 'position',
      type: 'text',
      label: 'Position *',
      default: '',
      validation: [{
        type: 'required',
        message: 'Position ist erforderlich',
      }, {
        type: 'min',
        params: [2],
        message: 'Position ist erforderlich',
      }],
    }],
  },
  streamPage: {
    hideStream: false,
    enablePolls: false,
    hideTitleInNavbar: false,
    showVisitorCount: false,
    arenaImChat: {
      enabled: false,
      link: '',
      publisher: '',
      chatroom: '',
    },
    navbarItems: [
      { link: '/logout', label: 'Ausloggen' },
    ],
    poll: {
      highlightColor1: '#33333322',
      highlightColor2: '#BA152699',
    },
    locale: {
      useDesktop:
        'Bitte benutze einen Desktop oder Laptop um an diesen am Zoom Event teilzunehmen.',
      streamAlreadyInUse:
        'Fehler beim Anzeigen des Livestreams. Bitte schliesse alle anderen offenen Fenster des Livestreams und versuche es erneut.',
      poll: {
        totalVotes: 'Gesamte Abstimmungen: ',
        customersVoted: 'Teilgenommen: ',
        votes: 'Stimmen',
      },
    },
    conversionCard: {
      position: 'center',
      button: {
        background: '#F19100',
        textColor: '#fff',
      },
    },
  },
  otherPages: {
  },
  cometChatGlobal: {
    isEnabled: true,
    appId: 'none',
    region: 'none',
    meta: {
      business: 'Firma',
      position: 'Position',
    },
    tabs: ['chats', 'users', 'groups'],
  },
  arenaChat: [],
  tawkTo: {
    enabled: false,
    propertyId: 'none',
    key: 'none',
  },
  styles: {
    generalBackground:
      'transparent',
  },
  themeJSON: {
    breakpoints: {
      keys: ['xs', 'sm', 'md', 'lg', 'xl'],
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    direction: 'ltr',
    mixins: {
      toolbar: {
        color: '#323232',
        backgroundColor: 'white',
        minHeight: 56,
        '@media (min-width:0px) and (orientation: landscape)': {
          minHeight: 48,
        },
        '@media (min-width:600px)': {
          minHeight: 64,
        },
      },
    },
    palette: {
      common: {
        black: '#000',
        white: '#fff',
      },
      type: 'light',
      primary: {
        light: '#888888',
        main: '#888888',
        dark: '#fff',
        contrastText: '#fff',
      },
      secondary: {
        light: '#000',
        main: '#000',
        dark: '#000',
        contrastText: '#fff',
      },
      error: {
        light: '#e57373',
        main: '#f44336',
        dark: '#d32f2f',
        contrastText: '#fff',
      },
      grey: {
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#eeeeee',
        300: '#e0e0e0',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161',
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.54)',
        disabled: 'rgba(0, 0, 0, 0.38)',
        hint: 'rgba(0, 0, 0, 0.38)',
      },
      divider: 'rgba(0, 0, 0, 0.12)',
      background: {
        paper: '#fff',
        contrastText: '#333333',
        hover: '#F19100',
        default: '#fafafa',
      },
      action: {
        active: 'rgba(0, 0, 0, 0.54)',
        hover: 'rgba(0, 0, 0, 0.08)',
        hoverOpacity: 0.08,
        selected: 'rgba(0, 0, 0, 0.14)',
        disabled: 'rgba(0, 0, 0, 0.26)',
        disabledBackground: 'rgba(0, 0, 0, 0.12)',
      },
    },
    props: {},
    shadows: [
      'none',
      '0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
      '0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
      '0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
      '0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      '0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
      '0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
      '0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
      '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
      '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
      '0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
      '0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
      '0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
      '0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)',
      '0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
      '0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)',
      '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
      '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
      '0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
      '0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
      '0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
    ],
    typography: {
      htmlFontSize: 16,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 16,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,

      h1: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 300,
        fontSize: '6rem',
        lineHeight: 1.167,
        letterSpacing: '-0.01562em',
      },
      h2: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 300,
        fontSize: '3.75rem',
        lineHeight: 1.2,
        letterSpacing: '-0.00833em',
      },
      h3: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 800,
        fontSize: '1.6rem',
        lineHeight: 1.167,
        letterSpacing: '0em',
      },
      h4: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '1.6rem',
        lineHeight: 1.235,
        letterSpacing: '0.00735em',
      },
      h5: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '1.5rem',
        lineHeight: 1.334,
        letterSpacing: '0em',
      },
      h6: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
        fontSize: '1.25rem',
        lineHeight: 1.6,
        letterSpacing: '0.0075em',
      },
      subtitle1: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.75,
        letterSpacing: '0.00938em',
      },

      subtitle2: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: 1.57,
        letterSpacing: '0.00714em',
      },

      body1: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '1.2rem',
        lineHeight: 1.5,
        letterSpacing: '0.00938em',
      },

      body2: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: 1.43,
        letterSpacing: '0.01071em',
      },

      button: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: 1.75,
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
      },

      caption: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: 1.66,
        letterSpacing: '0.03333em',
      },
      overline: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: 2.66,
        letterSpacing: '0.08333em',
        textTransform: 'uppercase',
      },
    },
    shape: {
      borderRadius: 5,
    },
    zIndex: {
      mobileStepper: 1000,
      appBar: 1100,
      drawer: 1200,
      modal: 1300,
      snackbar: 1400,
      tooltip: 1500,
    },
    overrides: {
      MuiFab: {
        primary: {
          '&&:hover': {
            color: '#333333',
            background: 'white',
          },
        },
      },
      MuiLink: {
        root: {
          '&&:hover': {
            color: '#333333',
          },
        },
      },
      MuiCssBaseline: {
        '@global': {
          '.conversion-card-box': {
            display: 'flex !important',
            'align-items': 'center',
            'justify-content': 'center',
          },
        },
      },
      MuiButton: {
        root: {
          minHeight: 50,
        },
        contained: {
          '&&:hover': {
            color: '#333333',
          },
        },
      },
      MuiCssBaseline: {
        '@global': {
          '.MuiToolbar-root .MuiButtonBase-root': {
            backgroundColor: 'white',
          },
          '.MuiToolbar-root .MuiButtonBase-root:hover': {
            backgroundColor: 'white',
          },
          '.MuiToolbar-root .MuiButtonBase-root .MuiButton-label, .MuiToolbar-root .MuiButtonBase-root .MuiIconButton-label': {
            color: '#323232',
          },
          '.MuiToolbar-root .MuiButtonBase-root .MuiButton-label:hover, .MuiToolbar-root .MuiButtonBase-root .MuiIconButton-label:hover': {
            color: '#F19100',
          },
        },
      },
    },
  },
};

config.navbars = config.navbars || [];
config.cometChat = config.cometChat || [];
config.matomo = config.matomo || { urlBase: 'https://wirkung-digital.matomo.cloud', siteId: 2 };

function addDefaultRouteBasedRule(property, data) {
  if (!config[property] || !config[property].length) return;

  const path = data.path.filter((p) => !config[property].find((navbar) => navbar.path.indexOf(p) !== -1));
  if (!path.length) {
    return;
  }
  config[property].push({
    ...data,
    path,
  });
}

// Navbar default config.
addDefaultRouteBasedRule('navbars', {
  path: ['/landing', '/register*', '/forgotPassword', '/resetPassword'],
  visible: false,
});

addDefaultRouteBasedRule('navbars', {
  path: ['/', '/welcome'],
  visible: true,
  overlay: false,
  items: config.welcomePage?.navbarItems || [
    { link: '/mediaLibrary', label: 'Mediathek' },
    { link: '/profile', label: 'Profil' },
    { link: '/logout', label: 'Ausloggen' },
  ],
  hideTitle: !!config.welcomePage?.hideTitleInNavbar,
  pageTitle: config.welcomePage?.pageTitle,
});

addDefaultRouteBasedRule('navbars', {
  path: ['/stream', '/stream/*'],
  visible: true,
  items: config.streamPage?.navbarItems || [
    { link: '/mediaLibrary', label: 'Mediathek' },
    { link: '/profile', label: 'Profil' },
    { link: '/logout', label: 'Ausloggen' },
  ],
  hideTitle: !!config.streamPage?.hideTitleInNavbar,
});

addDefaultRouteBasedRule('navbars', {
  path: ['/thankYou', '/quiz', '/fin', '/mediaLibrary', '/page', '/page*'],
  visible: true,
  items: config.otherPage?.navbarItems || [
    { link: '/mediaLibrary', label: 'Mediathek' },
    { link: '/profile', label: 'Profil' },
    { link: '/logout', label: 'Ausloggen' },
  ],
  hideTitle: !!config.otherPage?.hideTitleInNavbar,
  pageTitle: config.otherPage?.pageTitle,
});

addDefaultRouteBasedRule('navbars', {
  path: ['/profile'],
  visible: true,
  items: config.otherPage?.navbarItems || [
    { link: '/mediaLibrary', label: 'Mediathek' },
    { link: '/profile', label: 'Profil' },
    { link: '/logout', label: 'Ausloggen' },
  ],
  hideTitle: !!config.otherPage?.hideTitleInNavbar,
  pageTitle: config.otherPage?.pageTitle,
  overlay: false,
});

// Comet chat default config
addDefaultRouteBasedRule('cometChat', {
  path: ['/', '/landing', '/register*', '/forgotPassword', '/resetPassword'],
  isVisible: false,
});

addDefaultRouteBasedRule('cometChat', {
  path: ['/stream*', '/thankYou', '/quiz', '/fin'],
  isVisible: config.cometChatGlobal?.appId && config.cometChatGlobal?.region,
});

addDefaultRouteBasedRule('arenaChat', {
  path: ['/stream*', '/stream/*'],
  isVisible: config.streamPage?.arenaImChat?.enabled,
  chatroom: config.streamPage?.arenaImChat?.chatroom,
  publisher: config.streamPage?.arenaImChat?.publisher,
  link: config.streamPage?.arenaImChat?.link,
  position: 'bottom',
});

export default merge({}, defaults, config);
