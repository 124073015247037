import { useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Grow from '@material-ui/core/Grow';
import { Typography } from '@material-ui/core';
import Cookies from 'universal-cookie';
import config from '../config';
import GqlAPI from '../gql';
import QandA from './QandA';
import renderCard from './ConversionCards/renderCard';
import { useTracking } from '../util/hooks';
import { t } from '../locales';

const cookies = new Cookies();

export default function ConversionCards({ path, layout }) {
  const { trackEvent } = useMatomo();
  const { track, EVENTS, TARGETTYPES } = useTracking();
  const [formRequest, setFormRequest] = useState({ open: false, code: null });

  const breakpoints = {
    xs: 12,
    sm: layout === 'side' ? 12 : 6,
    md: layout === 'side' ? 12 : 4,
    xl: layout === 'side' ? 12 : 4,
    lg: layout === 'side' ? 12 : 4,
    ...config?.conversionCard?.breakpoints,
  };

  const { error, data, startPolling, stopPolling } = useQuery(GqlAPI.GET_CARDS, { variables: { path } });

  useEffect(() => {
    startPolling(5000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const onCardClick = (card) => {
    trackEvent({ category: 'stream', action: 'ctaClick', value: card.name });
    track({ event: EVENTS.CLICK, targetType: TARGETTYPES.CTA, target: card.code });
    window.dataLayer && window.dataLayer.push({
      event: 'ctaClick',
      cardName: card.name,
    });

    switch (card.params?.actionType) {
      case 'link':
        if (card.params?.openNewTab) { return window.open(card.params?.link, '_blank')?.focus(); }
        window.location.href = card.params?.link;
        break;
      case 'linkRotation':
        window.open('/jump/zoomExchangeMeeting', '_blank');
        break;
      case 'form':
        setFormRequest({ open: true, code: card.params?.formCode });
        break;
      default:
        return null;
    }
  };

  useMemo(() => {
    if (!data?.getConversionCardsForFrontend?.conversionCards) return;
    for (const conversionCard of data?.getConversionCardsForFrontend?.conversionCards) {
      if (conversionCard.params.actionType !== 'form' || !conversionCard.params.autoOpen) continue;
      if (cookies.get(`conversionCard_${conversionCard.code}_opened`)) continue;

      setTimeout(() => {
        onCardClick(conversionCard);
      }, 1000);
      cookies.set(`conversionCard_${conversionCard.code}_opened`, true, { path: '/', maxAge: 86400 });
    }
  }, [data?.getConversionCardsForFrontend?.conversionCards]);

  if (error) {
    return null;
  }

  return (
    <>
      <Grid container spacing={2} justify={config.streamPage?.conversionCard?.position} className="conversion-card-box">
        {
          config.streamPage?.layout === 'side' && !data?.getConversionCardsForFrontend?.conversionCards?.length
            ? (
              <Grid item xs={breakpoints.xs} sm={breakpoints.sm} md={breakpoints.md} lg={breakpoints.lg} xl={breakpoints.xl}>
                <Typography variant="h4" align="center" style={{ marginTop: 32 }}>{t('cta.empty')}</Typography>

              </Grid>
            )
            : data?.getConversionCardsForFrontend?.conversionCards?.map((card) => (
              <Grow in key={card.code}>
                <Grid item xs={breakpoints.xs} sm={breakpoints.sm} md={breakpoints.md} lg={breakpoints.lg} xl={breakpoints.xl}>
                  {renderCard(card, onCardClick)}
                </Grid>
              </Grow>
            ))
        }
      </Grid>
      <QandA requestForm={formRequest} onQuestionCloseClick={() => setFormRequest({ open: false, code: null })} />
    </>
  );
}
