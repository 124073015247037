import {
  Button,
  CardContent,
  Card,
  CircularProgress,
  Typography,
  CardActions,
  Box,
  Collapse,
} from '@material-ui/core';
import { React, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useLocation } from 'react-router-dom';
import Lottie from 'react-lottie';
import { useAuthState } from '../../context/auth';
import DynamicForm from '../DynamicForm';
import { t } from '../../locales';
import animationData from '../../util/lotties/success.json';
import { getFormGql, addFormSubmissionGql } from '../../gql/graphApi';

const lottieOptions = {
  autoplay: true,
  loop: false,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function FormPageElement({ params }) {
  const { user } = useAuthState();
  const snackbar = useSnackbar();
  const { trackEvent } = useMatomo();
  const { search, pathname } = useLocation();
  const [formData, setFormData] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const query = useMemo(() => new URLSearchParams(search), [search]);
  for (const entry of query.entries()) {
    window.sessionStorage.setItem(`form_${pathname}_${entry[0]}`, entry[1]);
  }

  const [addQuestion, { loading }] = useMutation(addFormSubmissionGql, {
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 404) return snackbar.showMessage(t('form.submit.error.notFound'));
      if (err.graphQLErrors[0]?.extensions.code === 409) return snackbar.showMessage(t('form.submit.error.closed'));
      return snackbar.showMessage(t('form.submit.error.default'));
    },
  });

  const { loading: formLoading } = useQuery(getFormGql, {
    variables: { code: params.code },
    onCompleted(data) {
      const newFields = data.getFormForFrontend?.fields.map((field) => {
        let { defaultValue } = field;
        if (field.params?.queryParam) {
          defaultValue = window.sessionStorage.getItem(`form_${pathname}_${field.params?.queryParam}`);
        }
        return { ...field, defaultValue };
      });
      setFormData({ ...data.getFormForFrontend, fields: newFields });
    },
    onError() {
      snackbar.showMessage(t('form.loading.error'));
    },
  });

  const handleSubmitFormular = async (fieldsWithValues) => {
    const author = fieldsWithValues.formAuthor;
    const fields = Object.entries(fieldsWithValues)
      .filter(([key, value]) => value !== '' && key !== 'formAuthor')
      .map(([key, value]) => ({ formFieldId: parseInt(key), content: value.toString() }));
    await addQuestion({
      variables: { author, fields, code: params.code },
    });
    setFormSubmitted(true);
    trackEvent({ category: 'stream', action: 'submitFormular', value: params.code });
  };

  const activeFields = useMemo(() => {
    if (!formData?.fields) return [];

    const activeFields = formData?.fields.map((field) => {
      const mappedField = { ...field, code: field.id, default: '', orgType: field.type };
      if (mappedField.isRequired) {
        mappedField.validation = [
          {
            type: 'required',
            message: t('common.validation.required', { field: mappedField.label, interpolation: { escapeValue: false } }),
          },
        ];
        if (field.type === 'checkbox') {
          mappedField.validation.push({
            type: 'oneOf',
            message: t('common.validation.required', { field: mappedField.label, interpolation: { escapeValue: false } }),
            params: [[true]],
          });
        }
        mappedField.label = `${mappedField.label} *`;
      }
      return mappedField;
    }) || [];

    const anonymCheckboxField = activeFields.find(({ type }) => type === 'anonymCheckbox');

    activeFields.unshift({
      code: 'formAuthor',
      label: t('stream.labelName'),
      type: 'text',
      default: anonymCheckboxField?.defaultValue === 'true' ? 'Anonym' : user?.firstname,
      validation: [
        {
          type: 'required',
          message: t('common.validation.required', { field: t('stream.labelName') }),
        },
      ],
    });
    return activeFields;
  }, [formData?.fields, user?.firstname]);

  const dialogButtons = (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
      <Button type="submit" variant="contained" color="primary">
        {loading || formLoading ? (
          <CircularProgress size={20} color="secondary" />
        ) : (
          t('form.button.submit')
        )}
      </Button>
    </div>
  );

  return (
    <>
      <Card>
        <CardContent>

          <Collapse in={formSubmitted}>
            {formSubmitted
              && (
                <>

                  <Lottie
                    options={lottieOptions}
                    height={200}
                    width={200}
                  />
                  <Box mt={2}>
                    <Typography variant="h3" gutterBottom align="center">
                      { t('form.submit.success')}
                    </Typography>
                  </Box>
                </>
              )}
          </Collapse>

          <Collapse in={!formSubmitted}>
            <Box mb={3}>
              <Typography variant="h3" gutterBottom>
                {' '}
                {formData?.title}
              </Typography>
              <Typography gutterBottom>
                {' '}
                {formData?.description}{' '}
              </Typography>
            </Box>

            {formLoading
                && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress color="primary" />
                  </div>
                )}

            {(formData?.fields && !formSubmitted) && (
              <DynamicForm
                activeFields={activeFields}
                onSubmit={handleSubmitFormular}
                submitLabel={t('form.button.submit')}
                actionButtonsComponent={dialogButtons}
                onChange={(value, field, formik) => {
                  if (field.type === 'anonymCheckbox') {
                    formik.setFieldValue('formAuthor', value ? 'Anonym' : user?.firstname);
                  }
                }}
              />
            )}
          </Collapse>

        </CardContent>

        {formSubmitted && !loading && (
          <CardActions>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'end', marginBottom: 12, marginRight: 10 }}>
              <Button type="submit" variant="contained" color="primary" onClick={() => setFormSubmitted(false)}>
                {t('form.button.reset')}
              </Button>
            </div>
          </CardActions>
        )}

      </Card>
    </>
  );
}
