import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Box, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import GqlAPI from '../../../gql';
import config from '../../../config';
import { t } from '../../../locales';
import Img from '../../Img';

const useStyles = makeStyles((theme) => ({
  button: {
    paddingTop: '10%',
    paddingLeft: '27.5%',
    paddingRight: '27.5%',
  },
  fillOtherVotes: {
    backgroundColor: config?.streamPage?.poll?.highlightColor1 || theme.palette.primary.main,
    height: '100%',
    position: 'absolute',
    left: 0,
    borderRadius: theme.shape.borderRadius - 1,
  },
  fillOwnVote: {
    backgroundColor: config?.streamPage?.poll?.highlightColor2 || theme.palette.primary.main,
  },
  avatar: { marginRight: '15px',
    zIndex: 100,
    width: '40px' },
}));

const calcPercentage = (val, totalVal) => {
  if (totalVal === 0) return 0;
  return ((val / totalVal) * 100).toFixed(1);
};

export default function CTAPollCard({ poll, refetch, onLoad }) {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const [lockPoll, setLockPoll] = useState(null);
  const { trackEvent } = useMatomo();
  const [localPollInfo, setLocalPollInfo] = useState({ votes: {} });

  const [placeVote, { loading: placingVote }] = useMutation(GqlAPI.PLACE_VOTE, {
    onCompleted: () => {
      refetch();
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 403) {
        snackbar.showMessage('Fehler bei der Stimmabgabe');
      }
    },
  });

  const onPollItemClicked = async (poll, item) => {
    if (onLoad) return;

    setLockPoll(poll.pollId);
    const result = await placeVote({
      variables: {
        code: poll.code,
        pollItemId: item.pollItemId,
        token: (poll.type === 'singleChoice') ? Object.values(localPollInfo.votes)[0]?.token : undefined,
      },
    });

    trackEvent({ category: 'stream', action: 'pollVoted', value: poll.code });

    if (result?.data?.placePollVoteFromFrontend?.token) {
      if (poll.type === 'singleChoice') localPollInfo.votes = {};
      const info = {
        ...localPollInfo,
        votes: {
          ...localPollInfo.votes,
          [item.pollItemId]: {
            pollItemId: item.pollItemId,
            token: result?.data?.placePollVoteFromFrontend?.token,
          },
        },
        date: new Date(),
      };

      setLocalPollInfo(info);
      localStorage.setItem(`poll_${poll.code}`, JSON.stringify(info));
    }
  };

  useEffect(() => {
    const localPollInfoString = localStorage.getItem(`poll_${poll.code}`) || '{ "votes": {} }';
    setLocalPollInfo(JSON.parse(localPollInfoString));
  }, [poll.code]);

  useEffect(() => {
    if (!localPollInfo || !localPollInfo.date) return;
    if (moment(poll.lastClearDate).isAfter(localPollInfo.date)) {
      localStorage.removeItem(`poll_${poll.code}`);
      setLocalPollInfo({ votes: {} });
    }
  }, [poll.lastClearDate, poll.code]);

  const alreadyVoted = (poll, item) => {
    if (poll.status === 'visible_only' || item.voted || localPollInfo.votes[item.pollItemId]) return true;
    const voted = poll.params?.voted || Object.keys(localPollInfo.votes).length;
    switch (poll.type) {
      case 'singleChoice':
        return voted && !poll.params?.canChangeVote;
      case 'multipleChoice':
        return voted >= poll.params?.placeableVotesCount;
      default: return true;
    }
  };

  return (
    <>
      <Grid container direction="column" spacing={1} style={{ marginTop: '15px' }}>
        {
          poll.params?.pollItems.map((item) => (
            <Grid item xs={12} key={item.pollItemId}>
              <Button
                variant="outlined"
                fullWidth
                style={{ textTransform: 'none' }}
                disabled={alreadyVoted(poll, item) || (placingVote && lockPoll === poll.pollId)}
                onClick={() => onPollItemClicked(poll, item)}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: '100%' }}
                >
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="flex-start"
                  >
                    {item.image && <Img src={item.image} className={classes.avatar} />}
                    {item.label && <Typography variant="h6" style={{ zIndex: 100 }}>{item.label}</Typography>}
                  </Box>

                  {(!poll.params?.hideVotes || !!poll.params?.voted) && (poll.params?.isAbsolute

                    ? (
                      <Typography
                        alignSelf="flex-end"
                        variant="body1"
                        style={{ zIndex: 100 }}
                      >
                        {`${item.votes} ${t('poll.votes')}`}
                      </Typography>
                    )
                    : (
                      <Typography
                        alignSelf="flex-end"
                        variant="body1"
                        style={{ zIndex: 100 }}
                      >{`${calcPercentage(item.votes, poll.params?.totalVotes)}%`}
                      </Typography>
                    ))}
                </Box>
                {(poll?.params?.hideVotes && !(poll.params?.voted || localPollInfo.votes[item.pollItemId]))
                  ? (
                    <div
                      className={(item.voted || localPollInfo.votes[item.pollItemId]) && ` ${classes.fillOwnVote}`}
                      style={{ width: `${calcPercentage(item.votes, poll.params?.totalVotes) }%` }}
                    />
                  )
                  : (
                    <div
                      className={(item.voted || localPollInfo.votes[item.pollItemId])
                        ? `${classes.fillOtherVotes} ${classes.fillOwnVote}` : `${classes.fillOtherVotes}`}
                      style={{ width: `${calcPercentage(item.votes, poll.params?.totalVotes) }%` }}
                    />
                  )}

              </Button>
            </Grid>
          ))
        }
        <Grid item xs={12}>
          <span>
            {poll.params?.showTotal
                  && (
                    <Typography
                      variant="body1"
                      align="right"
                      component="p"
                      style={{ marginTop: '15px', color: poll.status === 'visible_only' && 'grey' }}
                    >{`${t('poll.totalVotes')}${poll.params?.totalVotes}`}
                    </Typography>
                  )}
          </span>
          <span>
            {poll.params?.showCustomersVotedCount
                  && (
                    <Typography
                      variant="body1"
                      align="right"
                      component="p"
                      style={{ marginTop: '15px', color: poll.status === 'visible_only' && 'grey' }}
                    >
                      {`${t('poll.customersVoted')}${poll.params?.customersVoted}`}
                    </Typography>
                  )}
          </span>
        </Grid>
      </Grid>
    </>
  );
}
