import Container from '@material-ui/core/Container';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Prompt, useParams, useLocation } from 'react-router';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ConversionCards from '../components/ConversionCards';
import config from '../config';
import { t } from '../locales';
import GqlAPI from '../gql';
import ArenaChatLoader from '../components/ArenaChatLoader';
import Stream from '../components/Stream';
import LoadingSpinner from '../components/ConversionCards/LoadingSpinner';
import { useAuthState, useAuthDispatch } from '../context/auth';
import { useTracking } from '../util/hooks';
import { useFooterContextDispatch } from '../components/global/FooterContextProvider';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  main: {
    paddingTop: theme.mixins.toolbar.minHeight + 32,
    paddingBottom: 64,
  },
  streamSide: {
    paddingTop: theme.mixins.toolbar.minHeight,
    position: 'fixed',
    height: '100%',
    width: '70vw',
    [theme.breakpoints.up('lg')]: {
      width: '75vw',
    },
    [theme.breakpoints.up('xl')]: {
      width: '80vw',
    },
    top: 0,
    left: 0,
    paddingLeft: 0,
    overflow: 'hidden',
  },
  ctaSide: {
    paddingTop: theme.mixins.toolbar.minHeight + 10,
    position: 'absolute',
    width: '30vw',

    [theme.breakpoints.up('lg')]: {
      width: '25vw',
    },
    [theme.breakpoints.up('xl')]: {
      width: '20vw',
    },
    right: 0,
    top: 0,
  },
  stream: {
    maxHeight: '80vh',
    width: '100%',
    paddingBottom: '56.25%',
    position: 'relative',
  },
}));

export default function Home() {
  const classes = useStyles();
  const history = useHistory();
  const { code } = useParams();
  const { user } = useAuthState();
  const authDispatch = useAuthDispatch();
  const location = useLocation();
  const { track, EVENTS, TARGETTYPES } = useTracking();
  const [isAccessGranted, setIsAccessGranted] = useState(false);
  const footerDispatch = useFooterContextDispatch();
  const [isStreamReady, setIsStreamReady] = useState(false);

  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  useQuery(GqlAPI.GET_CURRENT_CUSTOMER, {
    skip: !user || user?.alias === 'anonymous',
    onCompleted(data) {
      authDispatch({ type: 'UPDATE', payload: data.getCurrentCustomer });
    },
  });

  const
    { loading, data, startPolling, stopPolling } = useQuery(GqlAPI.GET_STREAM_ACCESS, {
      variables: { project: 'basic' },
      fetchPolicy: 'no-cache',

      onError: (err) => {
        if (err.graphQLErrors[0]) history.push('/closed');
      },
      onCompleted: ({ getStreamAccess }) => {
        if (cookies.get('preview') === '1') return;
        if (getStreamAccess === 'ok') { setIsAccessGranted(true); return; }
        if (getStreamAccess === 'early') history.push('/closed');
        if (getStreamAccess === 'late') history.push('/fin');
        if (getStreamAccess === 'loggedOut') history.push('/logout');
      },
    });

  useEffect(() => {
    footerDispatch(config.streamPage.layout);
    return () => {
      footerDispatch(null);
    };
  }, []);

  useEffect(() => {
    startPolling(20000);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  useEffect(() => {
    if (isAccessGranted) track({ event: EVENTS.VISIT, targetType: TARGETTYPES.PAGE, target: location.pathname });
  }, [isAccessGranted]);

  const streamIsReady = () => {
    setIsStreamReady(true);
  };

  if (loading) {
    return (
      <Container className={classes.main}>
        <Grid container spacing={2}>
          <LoadingSpinner />
        </Grid>
      </Container>
    );
  }
  if (cookies.get('preview') === '1' || data?.getStreamAccess === 'ok') {
    return (
      <>
        {config.streamPage?.enableLeavePrompt
        && (
          <Prompt
            message={(location) => {
              if (location.state?.force) return true;
              return t('stream.cancel');
            }}
          />
        )}

        {
          lgUp && config.streamPage?.layout === 'side'

            ? (
              <div>
                <div item className={`${classes.streamSide} streamSide`}>
                  <div className={classes.stream}>
                    <Stream code={code} onLoad={streamIsReady} />
                  </div>
                </div>
                {isStreamReady
                  && (
                    <div item className={`${classes.ctaSide} ctaSide`}>
                      <Container>
                        <ConversionCards layout="side" />
                      </Container>
                    </div>
                  )}
              </div>
            )

            : (
              <Container maxWidth="lg" className={classes.main}>
                <Stream code={code} onLoad={streamIsReady} />
                {isStreamReady
                  && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ConversionCards />
                      </Grid>
                    </Grid>
                  )}
              </Container>
            )

        }
        <ArenaChatLoader />

      </>
    );
  }

  return null;
}
