// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // Common ////////////////////////////////////////////////////////////////
  'common.submit': 'Absenden',

  // Login ////////////////////////////////////////////////////////////////
  'login.email': 'E-Mail',
  'login.password': 'Passwort',
  'login.forgot': 'Passwort vergessen?',
  'login.login': 'Einloggen',
  'login.loginSection': 'Sie haben bereits ein Konto?',
  'login.loginWithoutRegisterSection': 'Anmeldung',
  'login.registerSection': 'Benötigen Sie ein Konto?',
  'login.register': 'Registrieren Sie sich',
  'login.errors.api': 'Service vorübergehend nicht erreichbar..',
  'login.errors.invalid': 'Ungültige Zugangsdaten',
  'login.errors.forbidden': 'Die eingebene Zugangsdaten sind nicht für dieses Event freigeschaltet',

  // Registration ////////////////////////////////////////////////////////////////
  'register.title': 'Registrierung',
  'register.email': 'E-Mail',
  'register.password': 'Passwort',
  'register.passwordRepeat': 'Passwort (wiederholen)',
  'register.firstname': 'Vorname',
  'register.lastname': 'Nachname',
  'register.inviteCode': 'Einladungscode',
  'register.submit': 'Absenden',

  'register.success':
    'Ihre Registrierung war erfolgreich. Sie erhalten in kürze eine E-Mail mit allen weiteren Informationen.',
  'register.activateSuccess':
    'Ihr Benutzer wurde erfolgreich aktiviert, Sie können sich nun anmelden.',
  'register.activateError':
    'Beim Aktivieren Ihres Benutzers ist etwas schief gegangen.',
  'register.error':
    'Beim der Anmeldung ist etwas schief gegangen. Bitte versuchen Sie es erneut',
  'register.activationResend':
    'Ihre Aktivierungs-E-Mail wurde erneut versendet. Bitte prüfen Sie auch Ihren Spam-Ordner.',

  'register.validation.required': '$t(register.{{field}}) ist erforderlich',
  'register.validation.inviteCode.alreadyUsed':
    'Einladungscode wurde bereits genutzt.',
  'register.validation.inviteCode.invalid': 'Einladungscode ist ungültig.',
  'register.validation.email': 'Bitte tragen Sie eine gültige E-Mail-Adresse ein.',
  'register.validation.emailDuplicated':
    'Diese E-Mail-Adresse ist bereits in Benutzung.',
  'register.validation.notActivated.part1':
    'Ihr Account ist noch nicht aktiviert. Bitte schauen Sie in Ihrem E-Mail-Postach nach dem Aktivierungslink.',
  'register.validation.notActivated.part2':
    'Klicken Sie hier, um eine neue Aktivierungs-E-Mail zu erhalten.',
  'register.validation.passwordReq':
    'Passwort muss mindestens aus 8 Zeichen bestehen.',
  'register.validation.passwordRepeat':
    'Passwörter müssen miteinander übereinstimmen.',

  // Forgot PW ////////////////////////////////////////////////////////////////
  'forgotPw.title': 'Passwort zurücksetzen',
  'forgotPw.submit': 'Absenden',
  'forgotPw.description':
    'Falls Sie Ihr Passwort vergessen haben, können Sie hier ein neues anfordern.',
  'forgotPw.success':
    'Falls ein Benutzerkonto hinter Ihrer E-Mail angelegt wurde, erhalten Sie in kürze eine Mail mit weiteren Informationen.',

  'forgotPw.resetTitle': 'Passwort neu setzen',
  'forgotPw.resetSubmit': 'Absenden',
  'forgotPw.resetPasswordSuccess':
    'Ihr Passwort wurde erfolgreich neu gesetzt. Sie können sich nun mit Ihrem neuen Passwort anmelden.',
  'forgotPw.resetPasswordError':
    'Beim Zurücksetzen Ihres Passworts ist etwas schief gegangen.',

  // Welcome ////////////////////////////////////////////////////////////////
  'welcome.openLivestream': 'Livestream',
  'welcome.close': 'Schließen',

  // Chat ////////////////////////////////////////////////////////////////
  'cometChat.chats': 'Chats',
  'cometChat.users': 'Benutzer',
  'cometChat.groups': 'Gruppen',
  'cometChat.groupImgAltText': 'Gruppenbild für Gruppe {{groupName}}',
  'cometChat.fabButtonAriaText': 'Öffne Chat. {{unreadMessagesText}} neue Nachrichten',

  // Stream ////////////////////////////////////////////////////////////////
  'stream.cancel': 'Wollen Sie die Livestream-Seite wirklich verlassen?',
  'stream.livestream.fail': 'Livestream konnte nicht geladen werden.',
  'stream.button.reload': 'Neuladen',
  'stream.labelName': 'Ihr Name',
  'stream.visitorCount.label': 'Aktuell {{visitorCount}} Zuschauer',
  'stream.extern.loading': 'Sie werden weitergeleitet, bitte warten.',

  // Thank You /////////////////////////////////////////////////////////////
  thanks: 'Danke für Ihre Teilnahme.',

  // STREAM SP /////////////////////////////////////////////////////////////
  'streamSp.questionTitle': 'Frage ins Studio',
  'streamSp.questionInput': 'Sie können hier Ihre Frage einreichen.',
  'streamSp.labelName': 'Ihr Name',
  'streamSp.labelQuestion': 'Ihre Frage...',
  'streamSp.defaultName': 'Anonym',

  // *************** COMPONENTS ********* ////////////////////////////////////

  // Poll Elements ///////////////////////////////////////////////////////////
  'module.fail': 'Modul konnte nicht geladen werden.',
  'poll.totalVotes': 'Gesamte Abstimmungen: ',
  'poll.customersVoted': 'Teilgenommen: ',
  'poll.votes': 'Stimmen',
  'poll.wordCloudInputLabel': 'Geben Sie ein Wort ein',
  'poll.wordCloudSend': 'Absenden',
  'poll.wordCloudResend': 'Erneut teilnehmen',

  // Player Zoom /////////////////////////////////////////////////////////////
  'zoom.name': 'Zoom Namen',
  'zoom.editName':
    'Bitte vervollständigen Sie Ihren Namen, um am Event teilzunehmen.',
  'zoom.participate': 'Teilnehmen',
  'zoom.end': 'Das Zoom Meeting wurde beendet.',
  // loading
  loading: 'Wird geladen...',

  // Layout ///////////////////////////////////////////////////////////////////
  'layout.back': 'Zurück zum Livestream',

  // After Event /////////////////////////////////////////////////////////////
  logout: 'Ausloggen',

  // Profile  /////////////////////////////////////////////////////////////
  'profile.title': 'Mein Profil',
  'profile.email': 'E-Mail',
  'profile.firstname': 'Vorname',
  'profile.lastname': 'Nachname',
  'profile.ticket': 'Ticket',
  'profile.actions': 'Profil anpassen',
  'profile.reset': 'Passwort zurücksetzen',
  'profile.delete': 'Profil löschen',
  'profile.resetSuccess':
    'Sie erhalten in kürze eine E-Mail mit weiteren Informationen.',
  'profile.deleteSuccess': 'Ihr Profil wurde erfolgreich bei uns gelöscht.',
  'profile.deleteError': 'Passwort stimmt nicht überein',
  'profile.deleteText':
    'Um Ihr Profil zu löschen, benötigen wir Ihr aktuelles Passwort als Bestätigung. Diese Aktion kann nicht mehr rückgängig gemacht werden.',
  'profile.submitDelete': 'Profil löschen',

  // Media Library  /////////////////////////////////////////////////////////////
  'mediaLibrary.showMore': 'Mehr zeigen',
  'mediaLibrary.showLess': 'Weniger zeigen',

  // Cookie Banner  /////////////////////////////////////////////////////////////
  // eslint-disable-next-line max-len
  'cookieBanner.text': 'Wir verwenden Cookies und ähnliche Tools, um Ihr Eventerlebnis zu verbessern, um unsere Dienste anzubieten, um zu verstehen, wie die Kunden unsere Dienste nutzen, damit wir Verbesserungen vornehmen können, und um Werbung anzuzeigen. Zugelassene Drittanbieter verwenden diese Tools auch in Verbindung mit der Anzeige von Werbung durch uns.',
  'cookieBanner.privacyPolicy': 'Datenschutzerklärung',
  'cookieBanner.necessaryOptionText': 'Notwendig',
  'cookieBanner.preferencesOptionText': 'Präferenzen',
  'cookieBanner.statisticsOptionText': 'Statistiken',
  'cookieBanner.marketingOptionText': 'Marketing',
  'cookieBanner.acceptButtonText': 'Alle Cookies akzeptieren',
  'cookieBanner.declineButtonText': 'Ablehnen',
  'cookieBanner.managePreferencesButtonText': 'Cookie Einstellungen',
  'cookieBanner.savePreferencesButtonText': 'Einstellungen speichern',

  // Forms  /////////////////////////////////////////////////////////////
  'form.submit.success': 'Das Formular wurde erfolgreich eingereicht.',
  'form.submit.error.default': 'Es gab einen Fehler beim Einreichen des Formulars. Bitte versuchen Sie es noch einmal.',
  'form.submit.error.notFound': 'Formular konnte nicht gefunden werden.',
  'form.submit.error.closed': 'Es können aktuell keine Einreichungen getätigt werden, das Formular ist geschlossen.',
  'form.loading.error': 'Das Formular konnte nicht geladen werden.',
  'form.button.cancel': 'Abbrechen',
  'form.button.submit': 'Einreichen',
  'form.button.reset': 'Weitere Einreichung',
  'form.anonym': 'Anonym',

  // CustomPage  /////////////////////////////////////////////////////////////
  'customPage.error.e404': 'Seite nicht gefunden.',
  'customPage.error.e403': 'Sie müssen angemeldet sein, um diese Seite aufrufen zu können.',
  'customPage.error.e4031': 'Die Seite ist noch nicht oder nicht mehr verfügbar.',
  'customPage.error.e4032': 'Die Seite ist Passwort geschützt, es wurde ein falsches Passwort übermittelt.',
  'customPage.error.e4033': 'Sie verfügen nicht über das richtige Ticket, um diese Seite aufrufen zu können.',
  'customPage.error.default': 'Seite konnte nicht aufgerufen werden.',
  'customPage.error.password': 'Falsches Passwort.',
  'customPage.password': 'Password',
  'customPage.unlock': 'Freischalten',

  // ArenaChat  /////////////////////////////////////////////////////////////
  'arenaChat.header': 'Chat',

  // ConversionCard  /////////////////////////////////////////////////////////////
  'cta.empty': 'Derzeit sind keine Karten verfügbar',

};
