import { gql } from '@apollo/client';

export const GET_WELCOME = gql`
query getWelcome {
  getWelcome {
    title
    subtitle
    lines
    modal {
      title
      lines
    }
  }
}
`;

export const GET_STREAM_ACCESS = gql`
query getStreamAccess($project: String!) {
  getStreamAccess(project: $project)
}
`;

export const GET_STREAM = gql`
  query getStream($projectId: Int!, $code: String, $noSession: Boolean) {
    getStream(projectId: $projectId, code: $code, noSession: $noSession) {
      platform
      type
      params
      actions {
        type
        label
        url
        openInNewTab
        code: formCode
      }
      guid
      content
      navTitle
      showVisitorCount
    }
  }
`;

export const GET_CARDS = gql`
query getConversionCardsForFrontend($path: String){
  getConversionCardsForFrontend(path: $path){
    conversionCards {
      code
      type
      name
      params
    }
  }
}`;

export const getAllEmojisForFrontendGql = gql`
  query getAllEmojisForFrontend {
    getAllEmojisForFrontend {
      code
      name
      image
      sequenceId
      status
    }
  }
`;

export const getSinglePollForFrontend = gql`
  query getSinglePollForFrontend($code: String) {
    getSinglePollForFrontend(code: $code) {
      pollId
      code
      type
      status
      params
      lastClearDate
    }
  }
`;

export const ADD_WORD_TO_WORDCLOUD = gql`
mutation ($code: String!, $word: String!) {
  addWordToWordCLoud(code: $code, word: $word)
  }
`;

export const PING_STREAM = gql`
  mutation ping($code: String, $anonymousCustomerId: String) {
    pingStream(code: $code, anonymousCustomerId: $anonymousCustomerId)
  }
`;

export const LEAVE_STREAM = gql`
  mutation leave($code: String, $anonymousCustomerId: String) {
    leaveStream(code: $code, anonymousCustomerId: $anonymousCustomerId)
  }
`;

export const TRACK_CLICK = gql`
  mutation trackClick($code: String!) {
    trackClick(code: $code) {
      status
    }
  }
`;

export const PLACE_VOTE = gql`
mutation ($code: String!, $pollItemId: Int!, $token: String) {
  placePollVoteFromFrontend(code: $code, pollItemId: $pollItemId, token: $token) {
    success
    token
  }
}
`;

export const LOGIN_STATUS = gql`
query getCustomerLoginStatus{
  getCustomerLoginStatus
}
`;

export const GET_CUSTOM_PAGE = gql`
query getCustomPageForFrontend($project: String!, $path: String!, $password: String) {
  getCustomPageForFrontend(path: $path, password: $password) {
    revNo
    status
    title
    schedulingMode
    startDate
    endDate
    elements {
      code
      component
      params
      sequenceId
      status
    }
  }
  getStreamAccess(project: $project)
}
`;

export const LOGIN_USER = gql`
  query customerLogin($email: String!, $password: String!) {
    customerLogin(email: $email, password: $password) {
      customerId
      firstname
      lastname
      ticket
      token
      alias
      ticketCategoryCode
      tawkToHash
    }
  }
`;

export const LOGIN_PASSWORD = gql`
  query customerPasswordLogin($password: String!) {
    customerPasswordLogin(password: $password) {
      customerId
      token
      alias
    }
  }
`;

export const RESEND_CUSTOMER_ACTIVATION = gql`
  mutation ($email: String!) {
    resendCustomerActivation(email: $email)
  }
`;

export const GET_CURRENT_CUSTOMER = gql`
  query {
    getCurrentCustomer {
      email
      ticket
      firstname
      lastname
      customAttributes
      ticketCategoryCode
      tawkToHash
    }
  }
`;

export const TRACK = gql`
  mutation ($event: TrackingEvent!, $targetType: TrackingTargetType!, $target: String, $anonymousCustomerId: String) {
    track(event: $event, targetType: $targetType, target: $target, anonymousCustomerId: $anonymousCustomerId)
  }
`;

export const GET_STREAM_VISITOR_COUNT = gql`
  query ($streamCode: String!){
    getStreamVisitorCount(streamCode: $streamCode)
  }
`;

export const addFormSubmissionGql = gql`
  mutation addFormSubmissionForFrontend($author: String!, $fields: [AddFormSubmissionField]!, $code: String!) {
    addFormSubmissionForFrontend(code:$code, author: $author, fields: $fields)
  }
`;

export const getFormGql = gql`
  query getFormForFrontend($code: String!) {
    getFormForFrontend( code: $code) {
    title
    description
    params
    fields {
      id
      type
      label
      hintText
      defaultValue
      sequenceId
      status
      params
      isRequired
    }
  }}
`;
