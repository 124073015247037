import { Button, CardActions } from '@material-ui/core';
import React from 'react';
import config from '../../config';

export default function CTAAction({ card, onCardClick }) {
  if (card?.params?.actionEmitter === 'button' && card?.params?.actionType !== 'none') {
    return (
      <CardActions>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginBottom: 8, marginTop: 8 }}>
          <Button
            size="large"
            color="primary"
            onClick={() => onCardClick(card)}
            style={{
              backgroundColor: config.streamPage?.conversionCard?.button?.background,
              color: config.streamPage?.conversionCard?.button?.textColor,
            }}
            variant="contained"
          >
            {card?.params?.buttonLabel}
          </Button>
        </div>
      </CardActions>

    );
  }

  return null;
}
