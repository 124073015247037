import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import config from '../config';

import { useAuthDispatch } from '../context/auth';
import Img from '../components/Img';

const LOGIN_USER = gql`
  query customerLogin($email: String!, $password: String!) {
    customerLogin(email: $email, password: $password) {
      customerId
      token
      alias
    }
  }
`;

const TITLE = config.title;

const useStyles = makeStyles((theme) => ({
  titleImage: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {},
    marginBottom: theme.spacing(6),
  },
  offset: {
    marginTop: '-15%',
  },
}));

export default function Landing() {
  const classes = useStyles();
  const dispatch = useAuthDispatch();
  const history = useHistory();
  const [state, setState] = useState(true);

  const { email } = useParams();

  const [loginUser] = useLazyQuery(LOGIN_USER, {
    onError(err) {
      if (err.graphQLErrors[0]) {
        history.push('/');
      } else {
        history.push('/');
      }
    },
    onCompleted(data) {
      dispatch({ type: 'LOGIN', payload: data.customerLogin });
      history.push('/');
    },
  });

  useEffect(() => {
    if (email && state) {
      loginUser({ variables: { email, password: 'cbl042021!' } });
      setState(false);
    }
  }, [email, loginUser, state]);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Img
        className={classes.titleImage}
        src="https://resources.wirkung.stream/projects/cbc/img/main-stripe.png"
      />

      <div className={classes.offset}>
        <Grid container spacing={3} justify="center" />
        <Grid container spacing={3} justify="center" />
      </div>
    </>
  );
}
