import React from 'react';
import BasicCTA from './Variants/BasicCTA';
import EmojiCTA from './Variants/EmojiCTA';
import IFrameCTA from './Variants/IFrameCTA';
import JsxCTA from './Variants/JsxCTA';
import PollCTA from './Variants/PollCTA';

const renderCard = (card, onCardClick, onLoad) => {
  switch (card.type) {
    case 'basic':
      return (<BasicCTA card={card} onCardClick={onCardClick} onLoad={onLoad} />);
    case 'emoji':
      return (<EmojiCTA card={card} onLoad={onLoad} />);
    case 'iFrame':
      return (<IFrameCTA card={card} onLoad={onLoad} />);
    case 'poll':
      return (<PollCTA card={card} onLoad={onLoad} />);
    case 'jsx':
      return (<JsxCTA card={card} onCardClick={onCardClick} onLoad={onLoad} />);
    default:
      return null;
  }
};

export default renderCard;
