import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useMutation, useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Prompt, Redirect, useLocation } from 'react-router';
import { Container, Grid } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import JsxParser from 'react-jsx-parser';
import Player from './player3Q';
import config from '../config';
import ZoomPlayer from './playerZoom';
import { useBeforeUnload, useInterval } from '../util/hooks';
import { t } from '../locales';
import ContentFlowPlayer from './playerContentFlow';
import { useCometChatContextDispatch, useCometChatContextState } from './global/CometChatContextProvider';
import QandA from './QandA';
import VisitorCount from './VisitorCount';
import { useAuthState } from '../context/auth';
import GqlAPI from '../gql';
import YoutubePlayer from './playerYoutube';
import VimeoPlayer from './VimeoPlayer';
import { useNavBarContextDispatch } from './global/NavBarContextProvider';
import HLSPlayer from './playerHLS';
import LoadingSpinner from './ConversionCards/LoadingSpinner';
import IframePlayer from './playerIFrame';

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    color: 'primary',
  },
  box: {
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    display: (config.streamPage.hideStream) ? 'none' : null,
  },
  video: {
    position: 'relative',
    paddingTop: '0px',
    borderRadius: '10px',
    marginBottom: '-1px',
  },
  videoError: {
    marginBottom: 32,
    paddingBottom: '56.25%',
    height: 0,

  },
  btn: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    minWidth: 180,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  btnWrapper: {
    minWidth: '33.333%',
    display: 'flex',
    marginTop: 10,
    justifyContent: 'center',
  },
  videoFooterEmpty: {
    height: '0px',
    backgroundColor: theme.palette.primary.main,
    marginBottom: 32,
  },
  videoFooter: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: -10,
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '10px',
    paddingBottom: 20,
    minHeight: '70px',
    backgroundColor: theme.palette.primary.main,
    marginBottom: 32,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-around',
    },
  },
  main: {
    paddingTop: theme.mixins.toolbar.minHeight + 32,
    paddingBottom: 64,
  },
  streamError: {
    width: '100%',
    height: '100%',
    backgroundColor: '#000',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 32,
  },
  streamErrorText: {
    color: '#fff',
    marginBottom: 32,
    textAlign: 'center',
  },
}));

export default function Stream({ code, onLoad }) {
  const { anonymousCustomerId } = useAuthState();
  const { trackEvent } = useMatomo();
  const history = useHistory();
  const [formRequest, setFormRequest] = useState({ open: false, code: null });
  const cometChatDispatch = useCometChatContextDispatch();
  const cometChatState = useCometChatContextState();
  const navBarDispatch = useNavBarContextDispatch();
  const zoomRef = useRef(null);
  const { search, pathname } = useLocation();

  const searchQuery = useMemo(() => new URLSearchParams(search), [search]);

  const codeToSend = useMemo(() => {
    if (!code && config.apiType === 'rest') return 'main';
    return code;
  }, [code]);
  const {
    error: streamError,
    data: { getStream: streamData } = {},
    loading: streamLoading,
  } = useQuery(GqlAPI.GET_STREAM, {
    variables: { projectId: parseInt(config.projectId), code: codeToSend, noSession: !searchQuery.has('z') },
  });

  const [leaveStream] = useMutation(GqlAPI.LEAVE_STREAM, {
    variables: {
      code,
      anonymousCustomerId: anonymousCustomerId || undefined,
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 400) window.location.href = '/';
      // eslint-disable-next-line no-console
      console.error(err);
    },
  });

  const [pingStream] = useMutation(GqlAPI.PING_STREAM, {
    variables: config.apiType !== 'rest' ? {
      code,
      anonymousCustomerId: anonymousCustomerId || undefined,
    } : {
      input: {
        code,
        id: anonymousCustomerId || JSON.parse(localStorage.getItem('anonymousCustomerId')),
      },
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 400) window.location.href = '/';
      // eslint-disable-next-line no-console
      console.error(err);
    },
  });

  const handleActionClick = ({ type, url, openInNewTab, code }) => {
    switch (type) {
      case 'zoomExchange':
        trackEvent({ category: 'stream', action: 'exchangeZoom' });
        window.open('/jump/zoomExchangeMeeting', '_blank');
        break;
      case 'form':
        setFormRequest({ open: true, code });
        break;
      case 'askStudio':
        setFormRequest({ open: true, code: null });
        break;
      case 'link':
        if (openInNewTab) return window.open(url, '_blank')?.focus();
        history.push(url);
        break;
      case 'openTawkTo':
        if (window.Tawk_API) {
          window.Tawk_API.maximize();
        }
        break;
      case 'zoomSwitchToFullscreen':
        if (!zoomRef.current) break;
        zoomRef.current.switchToFullscreen();
        break;
      default:
    }
  };

  const classes = useStyles();

  useEffect(() => {
    if (streamData?.navTitle) navBarDispatch(streamData.navTitle);
    if (streamData?.guid && streamData?.guid !== cometChatState?.guid) cometChatDispatch(streamData.guid);
  }, [streamData?.guid]);

  // Make sure that stream is always opened just once for the active user.
  const isStreamAlreadyInUse = useMemo(
    () => streamError?.graphQLErrors?.find((e) => e.extensions?.code === 409),
    [streamError],
  );

  // on unmount of the page trigger the leaveStream mutation
  useEffect(() => {
    // if (config.apiType === 'rest') return;
    pingStream();
    return () => {
      if (!isStreamAlreadyInUse && (searchQuery.has('z') || streamData?.platform !== 'zoom')) leaveStream();
    };
  }, []);

  useEffect(() => {
    if (!config.streamPage.hideStream && streamData?.params) { onLoad && onLoad(); }
  }, [streamData]);

  useBeforeUnload(() => {
    if (isStreamAlreadyInUse || (!searchQuery.has('z') && streamData?.platform === 'zoom')) {
      return;
    }
    if (config.apiType !== 'rest') leaveStream();
  });

  // Keep alive signal of zoom session.
  useInterval(() => {
    if (streamLoading || isStreamAlreadyInUse) {
      return;
    }
    pingStream();
  }, 60000);

  const actionMinWidth = useMemo(() => `${streamData?.actions || config.streamPage?.actions
    ? Math.max(
      1 / (streamData?.actions || config.streamPage?.actions || []).length,
      0.333,
    ) * 100
    : 0
    }%`, [streamData]);

  const stream = useMemo(() => {
    if (config.streamPage.hideStream) return null;
    if (streamLoading) {
      return null;
    }

    if (streamData?.type === 'extern') {
      if (streamData?.platform === 'none' && streamData?.params?.externUrl) {
        window.location.href = streamData.params.externUrl;
      } else {
        window.location.href = `/jump/externalZoomMeeting/${code}`;
      }

      return (
        <Container className={classes.main}>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', gap: '50px' }}>
            <LoadingSpinner />
            <Typography align="center" variant="h3" color="primary">
              {t('stream.extern.loading')}
            </Typography>
          </div>
        </Container>
      );
    }

    if (isStreamAlreadyInUse) {
      return (
        <div
          className={`${classes.video} ${classes.videoError}`}
          id="vdiv"
        >
          <div className={classes.streamError}>
            <Typography className={classes.streamErrorText} variant="h3">
              {config.streamPage.locale.streamAlreadyInUse}
            </Typography>
            <Button
              variant="contained"
              className={classes.btn}
              size="large"
              color="primary"
              onClick={async () => {
                window.location.reload();
              }}
            >
              {t('stream.button.reload')}
            </Button>
          </div>
        </div>
      );
    }
    return (
      <>
        <div className={classes.box} aria-label="Stream" role="presentation">
          <div
            className={classes.video}
            id="vdiv"
            style={
              streamData?.platform === '3q'
                ? {}
                : { paddingBottom: '56.25%', height: 0 }
            }
          >
            {streamData?.showVisitorCount && <VisitorCount streamCode={code} />}

            {streamData?.platform === 'zoom' && streamData?.params && (
              <>
                <ZoomPlayer
                  ref={zoomRef}
                  apiKey={streamData.params.joinParams.apiKey}
                  signature={streamData.params.joinParams.signature}
                  meetingId={streamData.params.joinParams.meetingNumber}
                  passcode={streamData.params.joinParams.passcode}
                  username={streamData.params.joinParams.userName}
                />

                <Prompt
                  message={(location) => {
                    window.location.href = location.pathname;
                  }}
                />
              </>
            )}
            {streamData?.platform === '3q' && (
              <Player params={streamData.params} />
            )}
            {streamData?.platform === 'contentFlow' && (
              <ContentFlowPlayer params={streamData.params} />
            )}
            {streamData?.platform === 'youtube' && <YoutubePlayer params={streamData.params} />}
            {streamData?.platform === 'vimeo' && (
              <VimeoPlayer
                videoId={streamData.params.link.split('/').reverse()[0]}
                type="live"
                width="100%"
                height="100%"
              />
            )}
            {streamData?.platform === 'hls' && <HLSPlayer params={streamData.params} />}
            {streamData?.platform === 'iframe' && <IframePlayer params={streamData.params} />}
            {!streamData ? (
              <Grid container direction="row" justify="center" spacing={2}>
                <Grid
                  justify="center"
                  item
                  xs={12}
                  style={{ textAlign: 'center' }}
                >
                  <Typography variant="body1" color="primary">
                    {t('stream.livestream.fail')}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}

          </div>
          <div
            className={(streamData?.actions && !streamData?.actions.length) || (!streamData?.actions && config.streamPage?.actions?.length)
              ? `${classes.videoFooterEmpty} video-footer` : `${classes.videoFooter} video-footer`}
          >
            {!isStreamAlreadyInUse
              ? (streamData?.actions || config.streamPage?.actions || []).map(
                (action, index) => (
                  <div
                    key={`${code}-${index}`}
                    className={classes.btnWrapper}
                    style={{
                      minWidth: actionMinWidth,
                    }}
                  >
                    <Button
                      key={action.label}
                      variant="contained"
                      className={classes.btn}
                      size="large"
                      color="primary"
                      onClick={() => handleActionClick(action)}
                    >
                      {action.label}
                    </Button>
                  </div>
                ),
              )
              : null}
          </div>
        </div>
        {(streamData?.content
        || config.streamPage.additionalContentBelowStream)
        && (
          <JsxParser
            components={{ Typography, Link, Button }}
            jsx={
              streamData?.content
                || config.streamPage.additionalContentBelowStream
            }
            bindings={{ config }}
          />
        )}
      </>
    );
  }, [streamData, isStreamAlreadyInUse]);

  if (streamData?.platform === 'zoom') {
    if (!searchQuery.has('z')) {
      window.location.href = `${pathname}?z=1`;
      return null;
    }
  }

  // for main stream, code will return undefined
  // if (!code && streamError?.graphQLErrors[0]?.extensions?.code === 404) {
  //   return <Redirect to={{ pathname: "/", state: { force: true } }} />;
  // }

  if (streamError?.graphQLErrors[0]?.extensions?.code === 404) {
    if (code) {
      return <Redirect to={{ pathname: '/stream', state: { force: true } }} />;
    }
    // if we are on the main stream, redirect to home
    return <Redirect to={{ pathname: '/', state: { force: true } }} />;
  }

  return (
    <>
      {stream}
      <QandA requestForm={formRequest} onQuestionCloseClick={() => setFormRequest({ open: false, code: null })} />

    </>
  );
}
