import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Container, Grid, Typography, Dialog, Button, DialogTitle, DialogContent, DialogActions, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import Cookies from 'universal-cookie';
import { useAuthState, useAuthDispatch } from '../context/auth';
import { GET_CUSTOM_PAGE, GET_STREAM_ACCESS, GET_CURRENT_CUSTOMER } from '../gql/graphApi';
import ConversionCards from '../components/ConversionCards';
import Stream from '../components/Stream';
import config from '../config';
import { useNavBarContextDispatch } from '../components/global/NavBarContextProvider';
import LoadingSpinner from '../components/ConversionCards/LoadingSpinner';
import { t } from '../locales';
import JSX from '../components/CustomPage/JSXCustomPageElement';
import PasswordForm from '../components/PasswordForm';
import PublicLayout from '../components/layout/PublicLayout';
import OnDemandPlayer from '../components/CustomPage/OnDemandPlayer';
import ArenaChatLoader from '../components/ArenaChatLoader';
import FormPageElement from '../components/CustomPage/FormPageElement';
import { useFooterContextDispatch } from '../components/global/FooterContextProvider';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  main: {
    paddingBottom: 64,
  },
  dialogActions: {
    color: 'primary',
  },
  streamSide: {
    paddingTop: theme.mixins.toolbar.minHeight,
    position: 'fixed',
    height: '100%',
    width: '70vw',
    [theme.breakpoints.up('lg')]: {
      width: '75vw',
    },
    [theme.breakpoints.up('xl')]: {
      width: '80vw',
    },
    top: 0,
    left: 0,
    paddingLeft: 0,
    overflow: 'hidden',
  },
  ctaSide: {
    paddingTop: theme.mixins.toolbar.minHeight + 10,
    position: 'absolute',
    width: '30vw',

    [theme.breakpoints.up('lg')]: {
      width: '25vw',
    },
    [theme.breakpoints.up('xl')]: {
      width: '20vw',
    },
    right: 0,
    top: 0,
  },
  stream: {
    maxHeight: '80vh',
    width: '100%',
    paddingBottom: '56.25%',
    position: 'relative',
  },
}));

export default function CustomPage() {
  const classes = useStyles();
  const navBarDispatch = useNavBarContextDispatch();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const [waitForAccess, setWaitForAccess] = useState(true);
  const [showTooEarlyDialog, setShowTooEarlyDialog] = useState(false);
  const authDispatch = useAuthDispatch();
  const { user } = useAuthState();
  const [passwordInput, setPasswordInput] = useState(null);
  const [errors, setErrors] = useState(null);
  const [passwordCheckPending, setPasswordCheckPending] = useState(false);
  const footerDispatch = useFooterContextDispatch();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const [isStreamReady, setIsStreamReady] = useState(false);

  useQuery(GET_CURRENT_CUSTOMER, {
    skip: !user || user?.alias === 'anonymous',
    onCompleted(data) {
      authDispatch({ type: 'UPDATE', payload: data.getCurrentCustomer });
    },
  });

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const password = query.get('password') || query.get('p') || query.get('k') || window.sessionStorage.getItem(`password_${pathname}`);

  const handleStreamAccess = (streamAccess) => {
    if (cookies.get('preview') === '1') {
      setWaitForAccess(false);
      return;
    }
    switch (streamAccess) {
      case 'ok':
        setShowTooEarlyDialog(false);
        setWaitForAccess(false);
        return;
      case 'early':
        setShowTooEarlyDialog(true);
        setWaitForAccess(false);
        return;
      case 'late':
        history.push((user) ? '/fin' : '/finAnonymous');
        break;
      default:
        history.push('/logout');
    }
  };

  const {
    loading,
    data: { getCustomPageForFrontend: customPage } = { getCustomPageForFrontend: {}, getStreamAccess: null },
    refetch,
  } = useQuery(GET_CUSTOM_PAGE, {
    variables: { path: pathname, password, project: 'basic' },
    onError: (err) => {
      setPasswordCheckPending(false);
      const errCode = err.graphQLErrors[0]?.extensions?.code;
      if (errCode === 4031) {
        setShowTooEarlyDialog(true);
        return;
      }
      setErrors(err);
    },
    onCompleted: (result) => {
      if (password) window.history.replaceState(null, null, pathname);
      if (passwordInput) window.sessionStorage.setItem(`password_${pathname}`, passwordInput);
      if (result.getCustomPageForFrontend?.schedulingMode === 'event') handleStreamAccess(result.getStreamAccess);
      setErrors(null);
      setPasswordCheckPending(false);
    },
  });

  const [getAccess, { startPolling, stopPolling }] = useLazyQuery(GET_STREAM_ACCESS, {
    variables: { project: 'basic' },
    onError: (err) => {
      if (err.graphQLErrors[0]) history.push('/closed');
    },
    onCompleted: ({ getStreamAccess }) => {
      handleStreamAccess(getStreamAccess);
    },
  });

  const handleProceedWithPassword = async (password) => {
    setPasswordCheckPending(true);
    setPasswordInput(password);
    refetch({ path: pathname, password }).catch(() => setPasswordCheckPending(false));
  };

  useEffect(() => {
    if (customPage?.schedulingMode === 'event') getAccess();
    else { setWaitForAccess(false); }
    if (password) setPasswordInput(password);
    if (customPage?.title) navBarDispatch(customPage?.title);
    return () => navBarDispatch(null);
  }, [customPage, password]);

  useEffect(() => {
    if (customPage?.schedulingMode !== 'event') return;
    if (startPolling) startPolling(20000);
    return () => { if (stopPolling) stopPolling(); };
  }, [waitForAccess, startPolling, stopPolling, customPage]);

  useEffect(() => {
    if (!customPage.elements?.find((element) => element.component === 'streamWithCta' || element.component === 'stream')) setIsStreamReady(true);

    if (customPage.elements?.find((element) => element.component === 'streamWithCta')) {
      footerDispatch('side');
    }
    return () => {
      footerDispatch(null);
    };
  }, [customPage]);

  const renderPageAccessFailed = (error) => {
    switch (error) {
      case 4031:
        return <Typography variant="h2" gutterBottom>{t('customPage.error.e4031')}</Typography>;
      case 4033:
        return <Typography variant="h2" gutterBottom>{t('customPage.error.e4033')}</Typography>;
      default:
        return <Typography variant="h2" gutterBottom>{t('customPage.error.default')}</Typography>;
    }
  };

  const renderComponent = (element) => {
    switch (element.component) {
      case 'cta':
        if (isStreamReady) return (<ConversionCards path={pathname} />);
        return null;
      case 'stream':
        return (<Stream code={element.params?.code} onLoad={() => { setIsStreamReady(true); }} />);
      case 'jsx':
        if (isStreamReady) return (<JSX params={element.params} />);
        return null;
      case 'video':
        if (isStreamReady) return (<OnDemandPlayer params={element.params} />);
        return null;
      case 'form':
        if (isStreamReady) return (<FormPageElement params={element.params} />);
        return null;
      case 'streamWithCta':
        return (
          <div>
            <div item className={`${classes.streamSide} streamSide`}>
              <div className={classes.stream}>
                <Stream code={element.params?.code} />
              </div>
            </div>
            <div item className={`${classes.ctaSide} ctaSide`}>
              <Container>
                <ConversionCards path={pathname} layout="side" />
              </Container>
            </div>
          </div>
        );
      default: return null;
    }
  };

  if (showTooEarlyDialog) {
    return (
      <>
        <Helmet>
          <title>
            {customPage.title || config.title}
          </title>
        </Helmet>
        <Dialog
          open={showTooEarlyDialog}
          PaperProps={{ className: 'welcomeModal' }}
        >
          <DialogTitle>{t('customPage.tooEarlyDialog.title')}</DialogTitle>
          <DialogContent>
            <Typography paragraph variant="body1">
              {t('customPage.tooEarlyDialog.content')}
            </Typography>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              color="secondary"
              className={classes.btn}
              onClick={() => window.location.reload()}
            >
              {t('customPage.tooEarlyDialog.retryAgain')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  if (errors) {
    const errCode = errors.graphQLErrors[0]?.extensions?.code;
    if (errCode === 404 || errCode === 403) return <Redirect to={{ pathname: '/' }} />;

    // show password form
    if (errCode === 4032) {
      return (
        <PublicLayout
          titleImage={matchesSM && config?.customPage?.loginScreen?.titleImageSM
            ? config?.customPage?.loginScreen?.titleImageSM : config?.customPage?.loginScreen?.titleImage}
          titleImageAltText={matchesSM && config?.customPage?.loginScreen?.titleImageSM
            ? config?.customPage.loginScreen.titleImageAltTextSM : config?.customPage?.loginScreen?.titleImageAltText}
        >
          <PasswordForm
            loading={loading || passwordCheckPending}
            handleSubmit={handleProceedWithPassword}
            error={(!passwordCheckPending && passwordInput !== null) && t('customPage.error.password')}
            labels={{ password: t('customPage.password'), login: t('customPage.unlock') }}
          />
        </PublicLayout>
      );
    }

    return (
      <Container className={`${classes.main} customPage-container`}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            {renderPageAccessFailed(errCode)}
          </Grid>
        </Grid>
      </Container>
    );
  }

  if (loading || waitForAccess) {
    return (
      <Container className={classes.main}>
        <Grid container spacing={2}>
          <LoadingSpinner />
        </Grid>
      </Container>
    );
  }

  if (customPage) {
    return (
      <>
        <Helmet>
          <title>
            {customPage.title || config.title}
          </title>
        </Helmet>
        <Container className={classes.main}>
          <Grid container spacing={2}>
            {customPage.elements?.map((element) => (
              <Grid key={element.code} item xs={12}>
                {renderComponent(element)}
              </Grid>
            ))}
          </Grid>
        </Container>
        <ArenaChatLoader />
      </>
    );
  }

  return null;
}
