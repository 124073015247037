import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { gql, useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { t } from '../locales';
import PublicLayout from './layout/PublicLayout';
import DynamicForm from './DynamicForm';
import { useAuthDispatch } from '../context/auth';

const useStyles = makeStyles(() => ({
  form: {
    marginBottom: 16,
  },
  footer: {
    marginBottom: 0,
    marginTop: 16,
  },
  errorText: {
    color: 'red',
    fontSize: 16,
  },
  box: {
    textAlign: 'center',
  },
}));

const defaultFields = [
  {
    code: 'firstName',
    label: `${t('register.firstname')} *`,
    type: 'text',
    default: '',
    validation: [
      {
        type: 'required',
        message: t('register.validation.required', { field: 'firstname' }),
      },
    ],
  },
  {
    code: 'email',
    label: `${t('register.email')} *`,
    type: 'text',
    default: '',
    validation: [
      {
        type: 'required',
        message: t('register.validation.required', { field: 'email' }),
      },
      { type: 'email', message: t('register.validation.email') },
    ],
  },
];

const registerGql = gql`
mutation register($input: PublishablePostInput!) {
  register(input: $input)  @rest(type: "register", path: "event/v1/events/register", method: "POST") {
    status
  }
}
`;

export default function EventRegistration() {
  const classes = useStyles();
  const dispatch = useAuthDispatch();
  const [user, setUser] = useState();
  const [hasError, setHasError] = useState(false);

  const [register] = useMutation(registerGql, {
    onError: (err) => {
      setHasError(true);
      console.error(err);
    },
    onCompleted: () => {
      setHasError(false);
      window.dataLayer && window.dataLayer.push({ event: 'register' });
      dispatch({ type: 'LOGIN', payload: user });
    },
  });

  const onSubmit = async (fields) => {
    setHasError(false);
    const user = { ...fields, anonymousCustomerId: uuidv4() };
    setUser(user);
    await register({
      variables: { input: { ...user, id: user.anonymousCustomerId, anonymousCustomerId: undefined } },
    });
  };

  return (
    <PublicLayout title={t('register.title')}>
      <PublicLayout.Box>
        <Typography className={classes.bgText}>
          {t('login.loginWithoutRegisterSection')}
        </Typography>
        <div className={classes.box}>
          <DynamicForm
            className={classes.form}
            activeFields={defaultFields}
            onSubmit={onSubmit}
            submitLabel={t('register.submit')}
          />
          {hasError && <span className={classes.errorText}>{t('register.error')}&nbsp;</span>}
        </div>
      </PublicLayout.Box>
    </PublicLayout>
  );
}
