import { useTheme } from '@material-ui/core/styles';
import { Grid, Button, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import ReactWordcloud from 'react-wordcloud';
import GqlAPI from '../../../gql';
import { t } from '../../../locales';
import LoadingSpinner from '../LoadingSpinner';
import config from '../../../config';

const options = {
  deterministic: false,
  fontSizes: [20, 60],
  fontStyle: 'normal',
  fontWeight: 'normal',
  padding: 0,
  rotations: 0,
  rotationAngles: [0, 90],
  scale: 'log',
  transitionDuration: 1000,
  enableTooltip: false,
};

export default function WordCloud({ poll, refetch, onLoad }) {
  const snackbar = useSnackbar();
  const { trackEvent } = useMatomo();
  const theme = useTheme();
  options.fontFamily = theme.typography.fontFamily;
  options.padding = poll?.params?.padding;
  if (poll?.params?.colors)options.colors = poll?.params?.colors;

  const [word, setWord] = useState('');
  const [showCloud, setShowCloud] = useState(false);

  const [addWord, { loading }] = useMutation(GqlAPI.ADD_WORD_TO_WORDCLOUD, {
    onCompleted: () => {
      refetch();
      setWord('');
      setShowCloud(true);
      trackEvent({ category: 'stream', action: 'wordAdded', value: poll.code });
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 403) {
        snackbar.showMessage('Wort konnte nicht eingereicht werden');
      }
    },
  });

  useEffect(() => {
    if (poll?.params?.voted) setShowCloud(true);
  }, [poll]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (onLoad) return setShowCloud(!showCloud);
    if (showCloud && poll?.params?.allowMultipleAnswers) {
      setShowCloud(false);
      return;
    }
    addWord({ variables: { code: poll?.code, word } });
  };

  useEffect(() => {
    onLoad && onLoad();
  }, [showCloud]);

  return (
    <>
      <form onSubmit={onSubmit}>
        <Grid container direction="column" spacing={1}>
          {(loading) && <LoadingSpinner />}
          {showCloud
            ? (
              <ReactWordcloud
                options={options}
                words={poll?.params?.words}
                /* maxWords={!!poll?.params?.wordLimit && poll?.params?.wordLimitCount} */
              />
            )
            : (
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  id="alias"
                  value={word}
                  type="text"
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  inputProps={{ maxLength: poll?.params?.charLimit ? poll?.params?.charLimitCount : undefined }}
                  required
                  label={t('poll.wordCloudInputLabel')}
                  onChange={(e) =>
                    setWord(e.target.value)}
                />
              </Grid>
            ) }
          {(!showCloud || poll?.params?.allowMultipleAnswers)
        && (
          <Grid item xs={12}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginBottom: -8, marginTop: 8 }}>
              <Button
                size="large"
                color="primary"
                style={{
                  backgroundColor: config.streamPage?.conversionCard?.button?.background,
                  color: config.streamPage?.conversionCard?.button?.textColor,
                }}
                variant="contained"
                type="submit"
              >
                {showCloud ? t('poll.wordCloudResend') : t('poll.wordCloudSend')}
              </Button>
            </div>
          </Grid>
        )}
        </Grid>
      </form>
    </>
  );
}
