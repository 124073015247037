import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import JsxParser from 'react-jsx-parser';
import { Alert } from '@material-ui/lab';
import { useQuery, gql } from '@apollo/client';
import { Redirect, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Cookies from 'universal-cookie';
import { useAuthDispatch } from '../context/auth';
import PublicLayout from '../components/layout/PublicLayout';
import LoginForm from '../components/LoginForm';
import config from '../config';

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    color: 'primary',
  },
  titleImage: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {},
  },

  box: {
    position: 'relative',
    color: '#0B5AA1',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    '&:hover': {
      color: '#073F71 !important',
    },
  },
  offset: {},
  cardFooter: {
    borderTop: '0px solid rgba(0, 0, 0, 0.125) !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '10px',
    paddingBottom: '10px',
    minHeight: '88px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '0px 0px 5px 5px',
    marginBottom: '30px',
  },
  btn: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
  },
  container: {
    marginTop: 32,
    marginBottom: 32,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  login: {
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  alert: {
    marginBottom: 16,
  },
}));

const LOGIN_USER = gql`
  query customerAnonymousLogin {
    customerAnonymousLogin {
      customerId
      token
      alias
    }
  }
`;

const LOGIN_PARENT_EVENT_USER = gql`
  query customerParentEventLogin{
    customerParentEventLogin{
      customerId
      projectId
      alias
      token
      firstname
      lastname
      companyCode
      ticket
    }
  }
`;

const cookies = new Cookies();

export default function Home() {
  const classes = useStyles();
  const { state } = useLocation();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAuthDispatch();

  const { data } = useQuery(LOGIN_USER, {
    skip: config.loginType !== 'anonymousLogin' || config.apiType === 'rest',
  });
  const { data: loginParentEventUser, loading: loadingParent } = useQuery(LOGIN_PARENT_EVENT_USER, {
    skip: !cookies.get(`psid_${config.parentProjectId}`),
  });

  if (config.parentProjectId && cookies.get(`psid_${config.parentProjectId}`) && loginParentEventUser?.customerParentEventLogin) {
    dispatch({ type: 'LOGIN', payload: loginParentEventUser.customerParentEventLogin });
    return <Redirect to={{ pathname: '/' }} />;
  }

  if (config.loginType === 'anonymousLogin' && config.apiType === 'rest') {
    dispatch({ type: 'LOGIN', payload: { anonymousCustomerId: uuidv4() } });
    return <Redirect to={{ pathname: '/' }} />;
  }

  if (config.loginType === 'anonymousLogin' && data?.customerAnonymousLogin) {
    dispatch({ type: 'LOGIN', payload: data.customerAnonymousLogin });
    return <Redirect to={{ pathname: '/' }} />;
  }

  if (config.loginType === 'anonymousLogin' || loadingParent) {
    return (<PublicLayout title="Start" />);
  }

  return (
    <PublicLayout
      title={config.title}
      titleImage={matchesSM && config?.landingPage?.titleImageSM ? config.landingPage.titleImageSM : config.landingPage.titleImage}
      titleImageAltText={matchesSM && config?.landingPage?.titleImageSM
        ? config.landingPage.titleImageAltTextSM : config.landingPage.titleImageAltText}
    >
      {state?.notification ? (
        <Alert severity={state.notification.type} className={classes.alert}>
          {state.notification.text}
        </Alert>
      ) : null}
      {config.landingPage.text && (
        <PublicLayout.Box>
          <JsxParser
            components={{ Typography }}
            jsx={config.landingPage.text}
          />
        </PublicLayout.Box>
      )}
      {config.landingPage.loginForm && (
        <div className={`${classes.login} loginform`}>
          <LoginForm />
        </div>
      )}
    </PublicLayout>
  );
}
