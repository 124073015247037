import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { CardContent, Card } from '@material-ui/core';
import GqlAPI from '../../../gql';
import LoadingSpinner from '../LoadingSpinner';
import MultipleChoice from '../PollVariants/MultipleChoice';
import WordCloud from '../PollVariants/WordCloud';
import config from '../../../config';
import CTAImage from '../CTAImage';
import CTACaption from '../CTACaption';

export default function PollCTA({ card, onLoad }) {
  const { params } = card;
  const { loading, data, refetch, startPolling, stopPolling } = useQuery(GqlAPI.getSinglePollForFrontend, {
    variables: { code: params.pollCode },
  });

  useEffect(() => {
    if (config?.pollIntervall) startPolling(config?.pollIntervall);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const renderPollCard = (poll) => {
    switch (poll.type) {
      case 'wordCloud':
        return (
          <WordCloud
            poll={poll}
            refetch={refetch}
            onLoad={onLoad}
          />
        );
      default:
        return (
          <MultipleChoice
            poll={poll}
            refetch={refetch}
            onLoad={onLoad}
          />
        );
    }
  };

  useEffect(() => {
    onLoad && onLoad();
  }, [card, renderPollCard]);

  if (loading) {
    return (
      <Card>
        <CTAImage onLoad={onLoad} card={card} />
        <CardContent>
          <LoadingSpinner />
        </CardContent>
      </Card>
    );
  }

  if (!data?.getSinglePollForFrontend) return null;

  return (
    <Card>
      <CTAImage onLoad={onLoad} card={card} />
      <CardContent>
        <CTACaption params={card.params} />
        {renderPollCard(data?.getSinglePollForFrontend)}
      </CardContent>
    </Card>
  );
}
