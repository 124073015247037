import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import config from '../../config';
import Img from '../Img';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleImage: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {},
  },
  box: {
    position: 'relative',
    backgroundColor: '#fff',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    padding: 32,
    borderRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: (hasButtons) => (hasButtons ? 0 : theme.shape.borderRadius),
    borderBottomRightRadius: (hasButtons) => (hasButtons ? 0 : theme.shape.borderRadius),
    minWidth: '100%',
  },
  container: {
    marginTop: 32,
    marginBottom: 32,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    marginTop: 32,
  },
  cardFooter: {
    width: '100%',
    zIndex: 100,
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    borderTop: '0px solid rgba(0, 0, 0, 0.125) !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '16px',
    minHeight: '88px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    marginBottom: '30px',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-around',
    },
  },
  btn: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
  },
  btnWrapper: {
    minWidth: '33.333%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16,
  },
}));

export default function PublicLayout(props) {
  const {
    children,
    showImage = true,
    containerWidth = 'md',
    titleImage = undefined,
    titleImageAltText = undefined,
    buttons = undefined,
  } = props;
  const classes = useStyles({ titleImage });
  const history = useHistory();

  const onLinkClick = (link, openInNewTab) => {
    if (/^https?:\/\//.test(link) || /^http?:\/\//.test(link) || link.includes('mailto:')) {
      if (openInNewTab) return window.open(link, '_blank').focus();
      window.location.href = link;
      return;
    }
    history.push(link);
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>
          {config.title}
        </title>
      </Helmet>
      {showImage && titleImage ? (
        <Img
          role="banner"
          className={classes.titleImage}
          src={titleImage}
          alt={titleImageAltText}
        />
      ) : null}
      <Container maxWidth={containerWidth} className={`${classes.container} layout-container`}>
        {children}
        {buttons && (
          <div className={`${classes.cardFooter} layout-box-footer`}>
            {buttons?.map((button) => (
              <div className={classes.btnWrapper}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.btn}
                  onClick={() => onLinkClick(button.link, button.openInNewTab)}
                >
                  {button.label}
                </Button>

              </div>
            ))}
          </div>
        )}
      </Container>
    </div>
  );
}

PublicLayout.Box = function PublicLayoutBox({ children, buttons }) {
  const hasButtons = buttons?.length || false;
  const classes = useStyles(hasButtons);
  return <div className={`${classes.box} layout-box`}>{children}</div>;
};
