import React, { useEffect } from 'react';
import { useRouteBasedConfig } from '../util/routeBasedConfig';
import { t } from '../locales';

export default function ArenaChatLoader() {
  const activeConfig = useRouteBasedConfig('arenaChat');
  const onLoadError = (err) => {
    console.error(`Arena Chat konnte nicht geladen werden: ${err}`);
  };

  const cleanUp = () => {
    const script = document.getElementById('ExtScript');
    if (script) { document.head.removeChild(script); }
    const container = document.getElementsByClassName('live-chat-room--container arena-chat-widget--container');
    if (container.length) {
      container[0].style.display = 'none';

      // save state of mute button to restore it later
      if (document.querySelector('.chat-room--chat--header--sound path')) {
        const isMuted = !document.querySelector('.chat-room--chat--header--sound path').getAttribute('fill-rule');
        window.sessionStorage.setItem('cometChatMuted', isMuted);
        if (!isMuted) { document.querySelector('.chat-room--chat--header--sound').click(); }
      }
    }
  };

  useEffect(() => {
    const routeHasChat = activeConfig?.isVisible && activeConfig?.link && activeConfig?.publisher && activeConfig?.chatroom;
    if (routeHasChat) {
      const container = document.getElementsByClassName('live-chat-room--container arena-chat-widget--container');
      let script = document.getElementById('ExtScript');

      if (container.length) {
      // restore state how it was before hidding it
        if (document.querySelector('.chat-room--chat--header--sound path')) {
          const isCurrentlyMuted = !document.querySelector('.chat-room--chat--header--sound path').getAttribute('fill-rule');
          const savedState = window.sessionStorage.getItem('cometChatMuted') === 'true';
          if (savedState !== isCurrentlyMuted) document.querySelector('.chat-room--chat--header--sound').click();
        }

        container[0].style.display = 'block';
      } else {
        script = document.createElement('script');
        script.onerror = onLoadError;
        script.onload = () => {
          setTimeout(() => {
            const container = document.getElementsByClassName('arena-chat-widget--container');
            container[0]?.setAttribute('aria-label', 'Chat');
            container[0]?.setAttribute('tabIndex', '0');

            const header = document.getElementsByClassName('chat-room--chat--header');
            if (header[0]) {
              const p = document.createElement('p');
              p.classList.add('arena-chat-label');
              p.appendChild(document.createTextNode(t('arenaChat.header')));
              header[0].prepend(p);
            }

            const inputField = document.getElementsByClassName('react-input-emoji--input');
            inputField[0]?.setAttribute('tabIndex', '0');
            inputField[0]?.setAttribute('role', 'textbox');
            inputField[0]?.setAttribute('aria-label', 'Kommentar posten');
            document.getElementsByClassName('react-input-emoji--button')[0]
              ?.setAttribute('aria-label', 'Emojis');

            const sendButton = document.getElementsByClassName('live-chat-room-input--btn')[0];
            sendButton?.setAttribute('tabIndex', '0');
            sendButton?.setAttribute('role', 'button');
            sendButton?.setAttribute('aria-label', 'Senden');
            sendButton?.addEventListener('keypress', (e) => {
              if (e.key === 'Enter' || e.key === ' ') sendButton.click();
            });

            // remove arena logo
            document.querySelector('.live-chat-room a')?.remove();
          }, 2000);
        };
        script.id = 'ExtScript';
        script.async = true;
        script.type = 'text/javascript';
        document.head.appendChild(script);
        script.src = activeConfig?.link;

        setTimeout(() => {

        }, 200);
      }
    } else {
      cleanUp();
    }
    return () => {
      cleanUp();
    };
  }, [activeConfig]);

  return (
    <>
      <div
        id="ExtArenaChat"
        className="arena-chat"
        data-publisher={activeConfig?.publisher}
        data-chatroom={activeConfig?.chatroom}
        data-position={activeConfig?.position}
      />
    </>
  );
}
