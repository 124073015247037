import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { useEffect } from 'react';
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useAuthState } from '../context/auth';
import { useTracking } from './hooks';

export default function DynamicRoute(props) {
  const location = useLocation();
  const history = useHistory();
  const { trackPageView } = useMatomo();
  const { track, EVENTS, TARGETTYPES } = useTracking();
  const { user, anonymousCustomerId } = useAuthState();
  const noneTrackablePages = [
    '/closed',
    '/landing',
    '/logout',
    '/forgotPassword',
    '/register/',
    '/resetPassword',
    '/preview/',
    '/stream',
    '/preStream',
  ];

  useEffect(() => {
    // listen of page navigation changes
    history.listen((location) => {
      window.dataLayer && window.dataLayer.push({
        event: 'virtualPageview',
        pageUrl: `${window.location.origin}${location.pathname}`,
      });

      if (props.authenticated && !user) return;
      trackPageView({
        href: `${window.location.origin}${location.pathname}`,
      });

      if (noneTrackablePages.some((path) => location.pathname.includes(path))) return;
      track({ event: EVENTS.VISIT, targetType: TARGETTYPES.PAGE, target: location.pathname });
    });

    if (props.authenticated && !user) return;
    trackPageView({
      href: `${window.location.origin}${location.pathname}`,
    });

    if (noneTrackablePages.some((path) => location.pathname.includes(path))) return;
    track({ event: EVENTS.VISIT, targetType: TARGETTYPES.PAGE, target: location.pathname });
  }, [history, trackPageView]);

  if (props.authenticated && !user) {
    return <Redirect to={{ pathname: '/landing' }} />;
  }

  if (props.guest && user && !anonymousCustomerId) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return <Route component={props.component} {...props} />;
}
