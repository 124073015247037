import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, CardContent, Card } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import GqlAPI from '../../../gql';
import LoadingSpinner from '../LoadingSpinner';
import CTAImage from '../CTAImage';
import CTACaption from '../CTACaption';
import Img from '../../Img';

const useStyles = makeStyles(() => ({
  image: {
    width: '100%',
  },
  button: {
    paddingTop: '5%',
    paddingBottom: '5%',
    paddingLeft: '27.5%',
    paddingRight: '27.5%',
  },
  grid: {
    marginTop: '20px',
  },
}));

const emojiClickGql = gql`
  mutation emojiClick ($code: String!) {
    emojiClick (code: $code)
  }
`;

export default function EmojiCTA({ card, onLoad }) {
  const classes = useStyles();
  const { loading, data } = useQuery(GqlAPI.getAllEmojisForFrontendGql);
  const [emojiClick] = useMutation(emojiClickGql);

  useEffect(() => {
    onLoad && onLoad();
  }, [card]);

  if (loading) return <LoadingSpinner />;

  const onClick = async (emoji) => {
    if (onLoad) return;
    await emojiClick({ variables: { code: emoji.code } });
  };

  return (
    <Card>
      <CTAImage card={card} onLoad={onLoad} />
      <CardContent>
        <CTACaption params={card.params} />
        <Grid container xs={12} spacing={0} justify="center" className={classes.grid}>
          {(data.getAllEmojisForFrontend.map((emoji) => (
            <Grid item xs={6} sm={6} md={6} xl={6}>
              <Button fullWidth onClick={() => onClick(emoji)} className={classes.button}>
                <Img onLoad={onLoad} src={emoji.image} alt="" className={classes.image} />
              </Button>
            </Grid>
          )))}
        </Grid>
      </CardContent>
    </Card>

  );
}
