import { makeStyles } from "@material-ui/core/styles";
import React, { useMemo } from "react";
import * as yup from "yup";
import Typography from "@material-ui/core/Typography";
import config from "../config.js";
import DynamicForm from "../components/DynamicForm";
import { useCallback } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { FunctionalRedirect } from "../components/Navigation/FunctionalRedirect.js";
import { useHistory, useParams } from "react-router";
import PublicLayout from "../components/layout/PublicLayout.js";
import { t } from "../locales";
import { Link } from "react-router-dom";
import JsxParser from "react-jsx-parser";
import { useImperativeQuery } from "../util/hooks.js";
import { useAuthDispatch } from "../context/auth.js";
import { Button } from "@material-ui/core";
import { useNotificationProvider } from "../components/global/NotificationProvider.js";
import { RESET_CUSTOMER_PASSWORD } from "./ForgotPassword";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: 32,
    marginBottom: 32,
  },
  footer: {
    marginBottom: 0,
    marginTop: 16,
  },
}));

const GET_CURRENT_CUSTOMER = gql`
  query {
    getCurrentCustomer {
      email
      ticket
      firstname
      lastname
      customAttributes
    }
  }
`;

const REMOVE_CUSTOMER = gql`
  mutation ($password: String!) {
    deleteCurrentCustomer(password: $password)
  }
`;

export default function Profile(props) {
  const classes = useStyles();
  const notification = useNotificationProvider();
  const { loading, error, data } = useQuery(GET_CURRENT_CUSTOMER);

  const fields = useMemo(() => {
    if (loading || error) {
      return null;
    }
    return [
      {
        code: "email",
        label: `${t("profile.email")}`,
        type: "text",
        default: data.getCurrentCustomer.email,
      },
      /*
      {
        code: "ticket",
        label: `${t("profile.ticket")} *`,
        type: "text",
        default: data.getCurrentCustomer.ticket,
      },
      */
      {
        code: "firstname",
        label: `${t("profile.firstname")}`,
        type: "text",
        default: data.getCurrentCustomer.firstname,
      },
      {
        code: "lastname",
        label: `${t("profile.lastname")}`,
        type: "text",
        default: data.getCurrentCustomer.lastname,
      },
      ...config.register.customFields.map((field) => ({
        ...field,
        label: field.label.replace("*", ""),
        default: data.getCurrentCustomer.customAttributes?.[field.code] || "",
      })),
    ];
  }, [data, error, loading]);

  // Remove customer
  const deleteFields = useMemo(() => {
    return [
      {
        code: "password",
        label: `${t("register.password")} *`,
        type: "password",
        default: "",
        validation: [
          {
            type: "required",
            message: t("register.validation.required", { field: "password" }),
          },
        ],
      },
    ];
  }, []);

  const [removeCustomerMut] = useMutation(REMOVE_CUSTOMER);
  const authDispatch = useAuthDispatch();
  const removeCustomer = useCallback(
    async (data) => {
      try {
        await removeCustomerMut({ variables: { password: data.password } });
        authDispatch({ type: "LOGOUT" });
        notification.current.show("success", t("profile.deleteSuccess"));
        return true;
      } catch (err) {
        return {
          password: t("profile.deleteError"),
        };
      }
    },
    [removeCustomerMut, notification, authDispatch]
  );

  // Password reset
  const [resetPasswordMut] = useMutation(RESET_CUSTOMER_PASSWORD);
  const resetPassword = useCallback(() => {
    resetPasswordMut({ variables: { email: data.getCurrentCustomer.email } });
    notification.current.show("success", t("profile.resetSuccess"));
  }, [resetPasswordMut, data, notification]);

  if (!fields) {
    return null;
  }

  return (
    <PublicLayout title={t("profile.title")} showImage={false}>
      <PublicLayout.Box>
        <Typography variant="h3" color="primary" style={{ marginBottom: 10 }}>
          {t("profile.title")}
        </Typography>
        <DynamicForm className={classes.form} activeFields={fields} readOnly />
        <Typography
          variant="h4"
          color="primary"
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          {t("profile.actions")}
        </Typography>
        <Button color="primary" variant="contained" onClick={resetPassword}>
          {t("profile.reset")}
        </Button>
        <Typography variant="h5" color="primary" style={{ marginTop: 32 }}>
          {t("profile.delete")}
        </Typography>
        <Typography variant="body" color="primary" style={{ marginTop: 16 }}>
          {t("profile.deleteText")}
        </Typography>
        <DynamicForm
          className={classes.form}
          activeFields={deleteFields}
          submitLabel={t("profile.submitDelete")}
          onSubmit={removeCustomer}
        />
      </PublicLayout.Box>
    </PublicLayout>
  );
}
