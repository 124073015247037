import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import ScrollToTop from './scrollToTop';
import MyContainer from './MyContainer';
import config from '../../config';

const theme = createMuiTheme(config.themeJSON);

export default function Theme({children}) {
    return (
        <>
            <ScrollToTop />
            <ThemeProvider theme={theme}>
                <MyContainer>
                {children}

                </MyContainer>
            </ThemeProvider>
        </>
    )
}
