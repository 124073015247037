import React, { Fragment } from 'react';
import JsxParser from 'react-jsx-parser';
import { Link } from 'react-router-dom';
import { Container, Typography, Grid, Paper, Button } from '@material-ui/core';
import { useAuthState } from '../../context/auth';
import config from '../../config';

export default function JSX({ params }) {
  const { user } = useAuthState();
  return (
    <>
      <JsxParser
        key={params?.code}
        components={{ Fragment, Container, Paper, Grid, Typography, Link, Button }}
        jsx={params?.jsx}
        bindings={{ config, user }}
      />
    </>
  );
}
