import { gql, useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { useParams } from 'react-router-dom';
import JsxParser from 'react-jsx-parser';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import { useNavBarContextDispatch } from '../components/global/NavBarContextProvider';
import Img from '../components/Img';

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    color: 'primary',
  },

  titleImage: {
    marginTop: '56px',
    position: 'relative',
    width: '100%',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginTop: '64px',
    },
  },
  box: {
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  },

  video: {
    position: 'relative',
    paddingBottom: '56.25%',
    paddingTop: '0px',
    height: '0',
    borderRadius: '10px',
    marginBottom: '-1px',
    marginTop: '50px',
  },
  videoFooter: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '10px',
    paddingBottom: '10px',
    minHeight: '70px',
    backgroundColor: theme.palette.primary.main,
    marginBottom: '30px',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  image: {
    width: '100%',
  },

}));

const getPageDetailsGql = gql`
query getPageContentForFrontend($pageCode: String!){
  getPageContentForFrontend(pageCode: $pageCode){
    titleImg
    buttons {
      link
      label
    }
    bottomGraphics {
      img
      link
      gridAttr
    }
    content
    navTitle
  }
}
`;

export default function PageDetail() {
  const { code } = useParams();
  const classes = useStyles();
  const navBarDispatch = useNavBarContextDispatch();
  const {
    loading,
    data: { getPageContentForFrontend: page } = { getPageContentForFrontend: {} },
  } = useQuery(getPageDetailsGql, { variables: { pageCode: code } });

  useEffect(() => {
    if (page?.navTitle) navBarDispatch(page?.navTitle);
    return () => { navBarDispatch(null); };
  }, [page?.navTitle]);

  const renderContent = () => (
    <>
      <div className="text">
        <Img className={classes.titleImage} src={page.titleImg} alt="" />
        <Container maxWidth="md" style={{ marginTop: '' }}>
          <Grid
            container
            style={{ marginTop: '25px' }}
            direction="row"
            justify="center"
            alignItems="center"
            spacing={5}
          >
            <Grid item xs={12}>
              <Paper
                className={classes.paper}
                elevation={2}
                style={{ paddingTop: 40, paddingBottom: 25, paddingLeft: 100, paddingRight: 100 }}
              >
                {page.buttons && page.buttons.map((button) => (
                  <div style={{ marginTop: 25 }}>
                    <a href={button.link} target="_blank" rel="noopener noreferrer">
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        style={{ display: 'inline' }}
                      >{button.label}
                      </Button>
                    </a>
                  </div>
                ))}

                {page.preText && (
                  <div style={{ marginTop: 50, textAlign: 'left' }}>
                    {page.preText}
                  </div>
                )}

                <div style={{ marginTop: 25, textAlign: 'left' }}>
                  <JsxParser
                    components={{ Typography, Link, Button }}
                    jsx={page.content}
                    bindings={{ page }}
                  />
                </div>
                {page.preText && (
                  <div style={{ marginTop: 25, marginBottom: 25, textAlign: 'left' }}>
                    {page.preText}
                  </div>
                )}

                {page.preText && (page.buttons && page.buttons[0]) && (
                  <div style={{ marginBottom: 25 }}>
                    <a href={page.buttons[0].link} target="_blank" rel="noopener noreferrer">
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        style={(page.buttons.label === '') ? { display: 'none' } : { display: 'inline' }}
                      >{page.buttons[0].label}
                      </Button>
                    </a>
                  </div>
                )}

                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="center"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ marginTop: 50 }}
                >

                  {page.bottomGraphics && page.bottomGraphics.map((graphic, index) => (
                    <Grid item {...graphic.gridAttr} key={index}>
                      {/* With link */}
                      {graphic.link && (
                        <a href={graphic.link} target="_blank" rel="noopener noreferrer">
                          <Img src={graphic.img} width="100%" alt="" />
                        </a>
                      )}
                      {/* Without link */}
                      {!graphic.link && <img src={graphic.img} width="100%" alt="" />}
                    </Grid>
                  ))}

                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );

  return (
    <>
      {loading && <CircularProgress size={20} className={classes.submitLoader} />}
      {!loading && page && renderContent()}
    </ >
  );
}
