import React, { useEffect } from "react";

export default function Projects({ params }) {

  useEffect(() => {
    if (window.js3qVideoPlayer) {
      window.js3qVideoPlayer = new window.js3q({
        dataid: params.dataId,
        container: "myVideoPlayer",
        sticky: true,
        key: params.key,
        timestamp: params.timestamp,
      });
    } else {
      var js3qVideoPlayer = new window.js3q({
        dataid: params.dataId,
        container: "myVideoPlayer",
        sticky: true,
        key: params.key,
        timestamp: params.timestamp,
      });
    }
  }, [params]);
  return (
    <>
      <div id="myVideoPlayer"></div>
    </>
  );
}
