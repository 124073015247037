import React, { createContext, useReducer, useContext } from 'react'

const CometChatDispatchContext = createContext()
const CometChatStateContext = createContext()



const sessionReducer = (state, guid) => {
  if(state.guid !== guid){
    return {
        ...state,
        guid: guid,
      }
  }
}

export const CometChatContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sessionReducer, { guid: null, type: null })

  return (
    <CometChatDispatchContext.Provider value={dispatch}>
      <CometChatStateContext.Provider value={state}>
        {children}
      </CometChatStateContext.Provider>
    </CometChatDispatchContext.Provider>
  )
}

export const useCometChatContextState = () => useContext(CometChatStateContext)
export const useCometChatContextDispatch = () => useContext(CometChatDispatchContext)