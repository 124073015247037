import React, { useEffect, useMemo, useState } from 'react';
import IframeResizer from 'iframe-resizer-react';
import { CardContent, Card } from '@material-ui/core';
import LoadingSpinner from '../LoadingSpinner';
import CTACaption from '../CTACaption';

export default function IFrameCTA({ card, onLoad }) {
  const [loading, setLoading] = useState(true);
  const { iFrame } = card.params;

  const frame = useMemo(() => (
    <IframeResizer
      {...iFrame}
      width="100%"
      frameBorder="0"
      allowFullScreen
      onLoad={() => setLoading(false)}
    />
  ), [iFrame]);

  useEffect(() => {
    onLoad && onLoad();
  }, [card]);

  return (
    <>
      <Card>
        <CardContent>
          {loading && iFrame?.src && <LoadingSpinner />}
          <CTACaption params={card.params} />
          {frame}
        </CardContent>
      </Card>

    </>
  );
}
