/* eslint-disable react/no-danger */
import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  FormControl,
  FormGroup,
  FormHelperText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import JsxParser from 'react-jsx-parser';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Link from '../../Link';

const useStyles = makeStyles(() => ({
  fieldRoot: {
    marginLeft: 14,
    marginBottom: 10,
    width: '100%',
  },
  hintText: {
    marginTop: -10,
    marginLeft: 33,
  },
}));

function DynamicFormCheckboxField({ formik, label, code, disabled, hintText, onChange, hidden }) {
  const classes = useStyles();
  const helperText = (
    <FormHelperText error={!!(formik.touched[code] && formik.errors[code])} className={classes.hintText}>
      {hintText}
      {hintText && formik.touched[code] && formik.errors[code] ? <br /> : null}
      {formik.touched[code] && formik.errors[code] && <span dangerouslySetInnerHTML={{ __html: formik.errors[code] }} />}
    </FormHelperText>
  );

  if (hidden) return null;

  return (
    <FormControl
      required
      error={formik.touched[code] && Boolean(formik.errors[code])}
      component="fieldset"
      className={classes.fieldRoot}
    >
      <FormGroup>
        <FormControlLabel
          className={(formik.touched[code] && Boolean(formik.errors[code])) ? 'MuiFormLabel-root Mui-error' : ''}
          style={{ marginBottom: 0 }}
          control={(
            <Checkbox
              checked={!!formik.values[code]}
              onChange={(e) => {
                formik.handleChange(e);
                onChange && onChange(e.target.checked);
              }}
              name={code && code.toString()}
              icon={<CheckBoxOutlineBlankIcon color={formik.touched[code] && Boolean(formik.errors[code]) ? 'error' : undefined} />}
              checkedIcon={<CheckBoxIcon color={formik.touched[code] && Boolean(formik.errors[code]) ? 'error' : undefined} />}
              value
              disabled={disabled}
            />
          )}
          label={(
            <JsxParser
              components={{ Typography, Link }}
              jsx={label}
            />
          )}
        />
        {helperText}
      </FormGroup>
    </FormControl>
  );
}

export default DynamicFormCheckboxField;
