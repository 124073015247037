import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import config from '../../config';
import { useAuthState } from '../../context/auth';
import Img from '../Img';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: '84px',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  dialogActions: {
    color: 'primary',
  },
  title: {
    flexGrow: 2,
    fontWeight: 600,
    alignSelf: 'center',
  },

  logo: {
    flexGrow: 2,
    maxHeight: '60px',
    alignSelf: 'center',
    backgroundSize: 'contain',
    marginRight: theme.spacing(4),
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));

export default function NavBar({ items: orgItems, burger, title }) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { user } = useAuthState();
  const [drawerState, setDrawerState] = React.useState(false);
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const onLinkClick = (link, openInNewTab) => {
    if (/^https?:\/\//.test(link) || /^http?:\/\//.test(link) || link.includes('mailto:')) {
      if (openInNewTab) return window.open(link, '_blank').focus();
      window.location.href = link;
      return;
    }
    history.push(link);
  };

  const items = useMemo(() => (
    orgItems.filter((item) => {
      if (!item?.ticketCategories) return true;
      if (!user) return false;
      return item.ticketCategories.includes(user.ticketCategoryCode);
    })
  ), [orgItems, user]);

  const listItemDrawer = (item) => (
    <ListItem button key={item.label} onClick={() => onLinkClick(item.link, item.openInNewTab)}>
      <ListItemText primary={<Typography color="primary">{item.label}</Typography>} />
    </ListItem>
  );

  const listItemAppBar = (item) => (
    <Button style={{ color: theme.palette.primary.contrastText }} onClick={() => onLinkClick(item.link, item.openInNewTab)} role="link">
      {item.label}{' '}
    </Button>
  );

  const drawerItems = (
    <List>
      {items.map((item) => listItemDrawer(item))}
    </List>
  );

  const appBarItems = (
    <>
      {items.map((item) => listItemAppBar(item))}
    </>
  );

  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="fixed" color="primary">
          <Toolbar>
            <Box display="flex" flexGrow={1}>
              {config.logo && (
                <Link to="/">
                  <Img src={config.logo} alt="Logo" className={classes.logo} />
                </Link>
              )}
              {(md || config.showLogoMd) && (
                <Typography variant="h5" className={classes.title}>
                  {title}
                </Typography>
              )}
            </Box>
            <nav>
              {md
                ? appBarItems
                : burger && (
                  <IconButton
                    style={{ color: theme.palette.primary.contrastText }}
                    onClick={toggleDrawer}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
            </nav>
          </Toolbar>
        </AppBar>

        {burger && (
          <Drawer
            variant="temporary"
            open={drawerState}
            onClose={toggleDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawerItems}
          </Drawer>
        )}
      </div>
    </>
  );
}
