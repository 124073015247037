import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Typography from "@material-ui/core/Typography";
import DynamicForm from "../components/DynamicForm";
import { useCallback } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import PublicLayout from "../components/layout/PublicLayout.js";
import { t } from "../locales";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: 32,
  },
}));

const fields = [
  {
    code: "email",
    label: `${t("register.email")} *`,
    type: "text",
    default: "",
    validation: [
      {
        type: "required",
        message: t("register.validation.required", { field: "email" }),
      },
      { type: "email", message: t("register.validation.email") },
    ],
  },
];

export const RESET_CUSTOMER_PASSWORD = gql`
  mutation ($email: String!) {
    resetCustomerPassword(email: $email)
  }
`;

export default function Register(props) {
  const classes = useStyles();
  const history = useHistory();

  const [resetCustomerPassword] = useMutation(RESET_CUSTOMER_PASSWORD);

  const onSubmit = useCallback(
    async (data) => {
      try {
        await resetCustomerPassword({ variables: { email: data.email } });
      } catch (e) {}

      history.push("/landing", {
        notification: {
          type: "success",
          text: t("forgotPw.success"),
        },
      });
      return true;
    },
    [resetCustomerPassword, history]
  );

  return (
    <PublicLayout title={t("forgotPw.title")}>
      <PublicLayout.Box>
        <Typography variant="h3" color="primary">
          {t("forgotPw.title")}
        </Typography>
        <Typography variant="body" color="primary">
          {t("forgotPw.description")}
        </Typography>
        <DynamicForm
          className={classes.form}
          activeFields={fields}
          onSubmit={onSubmit}
          submitLabel={t("forgotPw.submit")}
        />
      </PublicLayout.Box>
    </PublicLayout>
  );
}
