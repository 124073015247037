import React, { createContext, useReducer, useContext } from 'react';

const FoterDispatchContext = createContext();
const FoterStateContext = createContext();

const sessionReducer = (state, layout) => {
  if (state?.layout !== layout) {
    return {
      ...state,
      layout,
    };
  }
};

export const FooterContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sessionReducer, { layout: null, type: null });

  return (
    <FoterDispatchContext.Provider value={dispatch}>
      <FoterStateContext.Provider value={state}>
        {children}
      </FoterStateContext.Provider>
    </FoterDispatchContext.Provider>
  );
};

export const useFooterContextState = () => useContext(FoterStateContext);
export const useFooterContextDispatch = () => useContext(FoterDispatchContext);
